import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import NoAvailableData from "reuseableTable/NoAvailableData";
import SearchIcon from "@mui/icons-material/Search";

const GroupDetails = ({
  open,
  groupId,
  handleClose,
  setGroupId,
  selectedGroupIds,
  setSelectedGroupIds,
}) => {
  const [groupList, setGroupList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const role = localStorage.getItem("role");

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleOptionChange = (event) => {
    if (role === "ROLE_COLLEGE_ADMIN") {
      const value = event.target.value;
      setSelectedGroupIds((prevSelected) =>
        prevSelected.includes(value)
          ? prevSelected.filter((id) => id !== value)
          : [...prevSelected, value]
      );
    } else {
      setGroupId(event.target.value);
    }
  };

  const getgroupList = async () => {
    let api =
      role === "ROLE_COLLEGE_ADMIN"
        ? "/v1/communication/group/list"
        : "/v1/communication/group/my/list";
    try {
      const response = await axios.get(`${api}?pageSize=200&pageNumber=0`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: ConvertToRequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setGroupList(response?.data?.data?.content);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (open) {
      getgroupList();
      generateTimeStamp();
    }
  }, [open]);

  const filteredGroupList = groupList.filter((group) =>
    group?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
      >
        <Grid container spacing={2}>
          {/* Header */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500 }} variant="h3">
              Select a Group
            </Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Search Groups"
              variant="outlined"
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>

          {/* Group List */}
          {filteredGroupList.length > 0 ? (
            filteredGroupList.map((data) => (
              <Grid item xs={12} key={data.id}>
                {role === "ROLE_COLLEGE_ADMIN" ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedGroupIds.includes(data.id)}
                        onChange={handleOptionChange}
                        value={data.id}
                      />
                    }
                    label={data?.name}
                  />
                ) : (
                  <RadioGroup value={groupId} onChange={handleOptionChange}>
                    <FormControlLabel
                      value={data?.id}
                      label={data?.name}
                      control={<Radio />}
                    />
                  </RadioGroup>
                )}
              </Grid>
            ))
          ) : (
            <NoAvailableData text="No groups match your search criteria." />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
          <Chip
            onClick={handleClose}
            sx={{
              backgroundColor: "#0a66c2",
              color: "#f0f0f0",
              fontWeight: 450,
              width: 60,
            }}
            label="Back"
          />
          <Chip
            onClick={() =>
              role === "ROLE_COLLEGE_ADMIN"
                ? console.log("Selected IDs:", selectedGroupIds)
                : handleClose()
            }
            sx={{
              backgroundColor: "#0a66c2",
              color: "#f0f0f0",
              fontWeight: 450,
              width: 60,
            }}
            label="Done"
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GroupDetails;
