// MyConnections.js

import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  TablePagination,
  Typography,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import MainCard from "ui-component/cards/MainCard";
import ConnectionCard from "./ConnectionCards";
import NoAvailableData from "reuseableTable/NoAvailableData";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import ProfilePage from "views/alumniRegistration/alumniProfile/profilePage";

import { IconSearch } from "@tabler/icons";
import { DrawerProvider } from "./DrawerContext"; // Import DrawerProvider

const MyConnections = ({ value }) => {
  const [connectionlist, setConnectionList] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionInfo, setConnectionInfo] = useState([]);
  const [search, setSearch] = useState("");
  const [alumniId, setAlumniId] = useState("");
  const [viewProfile, setViewProfile] = useState(false);
  const [openDrawerForUserId, setOpenDrawerForUserId] = useState("");

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageCache({});
  };

  const getConnectionList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/connection/list?pageSize=${rowsPerPage}&pageNumber=${page}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setConnectionList(response?.data?.data?.content);
      setTotalCount(response?.data?.data?.totalElements);
      setPageCache((prev) => ({
        ...prev,
        [page]: response?.data?.data?.content,
      }));
      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleViewProfile = (id) => {
    setViewProfile(true);
    setAlumniId(id);
    setOpenDrawerForUserId(id); // Open drawer for the selected user
  };

  const getConnectionDetails = async () => {
    try {
      const response = await axios.get(
        `/v1/alumni/profile?userId=${alumniId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setConnectionInfo(response?.data?.data);
      generateTimeStamp();
      setIsLoading(false);
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSearch = (event) => {
    const newString = event.target.value;
    setSearch(newString);
    getConnectionList();
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  useEffect(() => {
    if (pageCache[page]) {
      setConnectionList(pageCache[page]);
    } else {
      getConnectionList();
    }
  }, [page, rowsPerPage, pageCache]);

  useEffect(() => {
    getConnectionDetails();
  }, [alumniId]);

  return (
    <DrawerProvider>
      {" "}
      {/* Wrap with DrawerProvider to provide DrawerContext */}
      <MainCard>
        <Grid item md={4} lg={4} xs={12}>
          <OutlinedInput
            fullWidth
            id="input-search-card-style3"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <IconSearch stroke={1.5} size="16px" />
              </InputAdornment>
            }
            size="small"
          />
        </Grid>
        {isLoading ? (
          <AnimatedLoader />
        ) : (
          <>
            {viewProfile ? (
              <>
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  onClick={() => setViewProfile(false)}
                >
                  Back
                </Button>

                <Grid sx={{ mt: 2 }}>
                  <ProfilePage
                    setViewProfile={setViewProfile}
                    IsConnection={true}
                    isLoading={isLoading}
                    alumniDetails={connectionInfo}
                    getAlumniDetails={getConnectionDetails}
                  />
                </Grid>
              </>
            ) : (
              <>
                {connectionlist?.length > 0 ? (
                  <>
                    <Grid container direction="row" spacing={2} mt={2}>
                      {connectionlist.map((friend, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                          <ConnectionCard
                            handleViewProfile={handleViewProfile}
                            friend={friend}
                            getConnectionList={getConnectionList}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <TablePagination
                      component="div"
                      count={totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <NoAvailableData text={"No Connections."} />
                )}
              </>
            )}
          </>
        )}
      </MainCard>
    </DrawerProvider>
  );
};

export default MyConnections;
