import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import StudentList from "./StudentList";

const PlaceMent = () => {
  const [list, setList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [showStudentList, setShowStudentList] = useState(false);

  const handleShowStudents = () => {
    setShowStudentList(true);
  };

  const getPlacementList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/placements/company-dashboard-open-placement-list`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      setList(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getStudentList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/student/get-student-mini-by-course?pageSize=200&pageNo=0`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      setStudentList(response?.data?.data?.content);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    getStudentList();
    getPlacementList();
  }, []);

  return (
    <MainCard>
      <Grid item md={12} xs={12} lg={12}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "18px",
            p: 2,
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
          }}
        >
          Recent Placement Opening
        </Typography>
      </Grid>

      {showStudentList ? (
        <StudentList
          setShowStudentList={setShowStudentList}
          setStudentList={setStudentList}
          studentList={studentList}
        />
      ) : (
        <>
          {list?.map((data) => (
            <Grid container spacing={1} mt={2} key={data.id}>
              {" "}
              {/* Added key prop */}
              <Grid item md={3} lg={3} xs={12}>
                <Card
                  sx={{
                    maxWidth: 340,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                  }}
                >
                  <CardActionArea>
                    <CardContent onClick={() => handleShowStudents()}>
                      <Typography
                        onClick={() => handleShowStudents()}
                        sx={{ cursor: "pointer" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {data?.placementWeekName}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                          Start Date :{" "}
                        </span>
                        {data?.startDate}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                          End Date :{" "}
                        </span>

                        <span style={{ marginLeft: "8px" }}>
                          {data?.endDate}
                        </span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </MainCard>
  );
};

export default PlaceMent;
