import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PendingPost from "./PendingPost";
import ApprovedPost from "./ApprovedPost";
import RejectedPost from "./RejectedPost";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import EventPostDetailsCard from "./EventPostDetailsCard";
import NoAvailableData from "reuseableTable/NoAvailableData";

// ==============================|| SOCIAL PROFILE - EVENT DETAILS ||============================== //

const ManagePost = () => {
  const [postType, setPostType] = useState("Approved");
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  const [clickedDetails, setClickedDetails] = useState(false);
  const [postId, setPostId] = useState("");

  const handleClickedDetails = (id) => {
    setPostId(id);
    setClickedDetails(true);
  };
  const handleCloseDetails = () => {
    setClickedDetails(false);
  };

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getPostDetails = async (postType) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/posts/my/list-by-status?event=false&pageSize=${rowsPerPage}&pageNumber=${page}&approvalStatus=${
          postType ?? "Approved"
        }`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setEventDetails(response?.data?.data?.content);
      setPageData(response?.data?.data?.content);
      setTotalCount(response?.data?.data?.totalElements);
      setPageCache((prev) => ({
        ...prev,
        [page]: response?.data?.data?.content,
      }));
      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setEventDetails(pageCache[page]);
    } else {
      getPostDetails();
    }
  }, [page, rowsPerPage, pageCache]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageCache({});
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <MainCard>
      {/* <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="tabs"
      >
        <Tab label="Pending" />
        <Tab label="Published" />
        <Tab label="Denied" />
      </Tabs>
      <div className="tab-content">
        {activeTab === 0 && <PendingPost />}
        {activeTab === 1 && <ApprovedPost />}
        {activeTab === 2 && <RejectedPost />}
      </div> */}
      <Grid item md={4} xs={12} p={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Posts</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={postType}
            label="Posts"
            onChange={(e) => {
              setPostType(e.target.value);
              getPostDetails(e.target.value);
            }}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Approved">Published</MenuItem>
            <MenuItem value="Rejected">Denied</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {isLoading ? (
        <>
          <AnimatedLoader />
        </>
      ) : (
        <>
          {eventDetails?.length > 0 ? (
            <Grid container spacing={2}>
              {eventDetails?.map((post) => (
                <Grid key={post.id} item xs={12} md={4}>
                  <EventPostDetailsCard
                    post={post}
                    getPostDetails={getPostDetails}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <NoAvailableData text={"There are no any Rejected Event."} />
            </>
          )}
        </>
      )}
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MainCard>
  );
};

export default ManagePost;
