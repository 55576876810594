import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { DASHBOARD_PATH } from "config";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { useEffect, useState } from "react";
const LogoSection = () => {
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const [logoImage, setLogoImage] = useState("");

  const getLogo = async () => {
    const role = localStorage.getItem("role");
    if (role === "ROLE_SUPER_ADMIN") return;
    let url = `/v1/institute/get-ips?instituteId=${localStorage.getItem(
      "instituteId"
    )}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      setLogoImage(response?.data?.data?.ipDetailsList?.NAVIMAGE);
      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.log(err);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  return (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
      <img
        src={logoImage || "/assets/images/icons/AlumniGreenLogo.svg"}
        alt="logo"
        width={logoImage ? "160" : "100"}
      />
    </Link>
  );
};

export default LogoSection;
