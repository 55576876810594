import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";

const BatchMultiSelect = ({
  onChange,
  value,
  label,
  setFieldValue,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [batchList, setBatchList] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const fetchBatchList = async () => {
    try {
      const response = await axios.get(`/v1/batch/list`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999) + rendomRequestId
          ),
        },
      });
      const data = response?.data?.BatchList;
      setBatchList(data);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      if (!error.response) {
        dispatch(openSnackbar({ message: error.message, type: "error" }));
      } else {
        dispatch(
          openSnackbar({ message: error.response.data.Msg, type: "error" })
        );
      }
    }
  };

  useEffect(() => {
    fetchBatchList();
  }, []);

  useEffect(() => {
    if (batchList.length > 0 && value) {
      const selected = batchList.filter((batch) => value.includes(batch.id));
      setSelectedItems(selected);
      setFieldValue("eventForIds", selected.map((batch) => batch.id));
    }
  }, [batchList, value, setFieldValue]);

  const handleCheckboxChange = (event, option) => {
    const selectedIndex = selectedItems.findIndex(
      (item) => item.id === option.id
    );

    let newSelectedItems;
    if (selectedIndex === -1) {
      newSelectedItems = [...selectedItems, option];
    } else {
      newSelectedItems = selectedItems.filter((item) => item.id !== option.id);
    }
    setSelectedItems(newSelectedItems);
    if (onChange) onChange(newSelectedItems.map((item) => item.id));
    setFieldValue("eventForIds", newSelectedItems.map((item) => item.id));
  };

  const renderOption = (props, option) => (
    <li {...props}>
      <Checkbox
        checked={selectedItems.some((item) => item.id === option.id)}
        onChange={(event) => handleCheckboxChange(event, option)}
      />
      <Typography>{option.tag}</Typography>
    </li>
  );

  useEffect(() => {
    generateTimeStamp();
  }, []);

  console.log(value, "value")

  return (
    <Autocomplete
      multiple
      id="batch-multi-select"
      options={batchList}
      disableCloseOnSelect
      value={selectedItems}
      onChange={(_, newValue) => {
        setSelectedItems(newValue);
        if (onChange) onChange(newValue.map((item) => item.id));
        setFieldValue("eventForIds", newValue.map((item) => item.id));
      }}
      getOptionLabel={(option) => option.tag}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label || "Batch"} />
      )}
    />
  );
};

BatchMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default BatchMultiSelect;
