import axios from "axios";
import useRequestIdGenerater from "customhooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import AlumniSocialProfile from "views/application/users/social-profile/AlumniScoialProfile";

const MainScocialProfile = ({ renderFrom, setIsOpenTab, isOpenTab, alumniDetailsInfo }) => {
  const [alumniDetails, setAlumniDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const { id } = useParams();

  const Role = localStorage.getItem("role");

  const getAlumniDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/alumni/info?userId=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Role,
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setAlumniDetails(response?.data?.data);
      localStorage.setItem("alumniBatchId", response?.data?.data?.batch?.id ?? "");
      localStorage.setItem("alumniId", response?.data?.data?.id);
      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    getAlumniDetails();
    generateTimeStamp();
  }, []);

  return (
    <>
      <AlumniSocialProfile
        alumniDetailsInfo={alumniDetailsInfo}
        renderFrom={renderFrom}
        isOpenTab={isOpenTab}
        setIsOpenTab={setIsOpenTab}
        getAlumniDetails={getAlumniDetails}
        alumniDetails={alumniDetails}
      />
    </>
  );
};

export default MainScocialProfile;
