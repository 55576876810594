import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Fade,
} from "@mui/material";
import Avatar from "ui-component/extended/Avatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";

const CommentList = ({
  comment,
  postId,
  setPosts,
  openComment,
  getCommentList,
  type = "forAlumni",
}) => {

  const theme = useTheme();
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const [loading, setLoading] = useState(false);

  const handleCommentLikes = async (postId, commentId, like) => {
    try {
      setLoading(true); // Start loading
      const response = await axios.get(
        `/v1/posts/like/comment?like=${like}&postId=${postId}&commentId=${commentId}&alumniId=${localStorage.getItem(
          "alumniId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      getCommentList(postId);
      setPosts(response?.data?.data);
      generateTimeStamp();
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <>
      {openComment && (
        <Grid container spacing={2}>

          <Fade in={!!Object.keys(comment).length} timeout={400}>
            <Grid item xs={12}>
              <Card
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.dark.main
                      : theme.palette.grey[50],
                  padding: "16px 16px 8px",
                  mt: 1.25,
                  transition: "border-color 0.3s ease",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                      <Grid item>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          size="sm"
                          alt="User 1"
                          src={`data:image/*;base64, ${comment?.alumniMemberMini?.avatar?.data}`}
                        />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Typography align="left" variant="h5" component="div">
                              {comment.alumniMemberMini?.name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography align="left" variant="caption">
                              <FiberManualRecordIcon
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  opacity: 0.5,
                                  m: "0 5px",
                                }}
                              />{" "}
                              {comment.createdAt}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ "&.MuiGrid-root": { pt: 1.5 } }}>
                    <Typography align="left" variant="body2">
                      {comment?.comment}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        color:
                          theme.palette.mode === "dark" ? "grey.700" : "grey.800",
                      }}
                    >
                      {type !== "FromAdmin" && (
                        <Button
                          onClick={() =>
                            handleCommentLikes(
                              postId,
                              comment.id,
                              !comment?.commentLikesIds?.includes(
                                localStorage.getItem("userId")
                              )
                            )
                          }
                          variant="text"
                          color="inherit"
                          size="small"
                          startIcon={
                            <ThumbUpAltTwoToneIcon
                              color={comment.likes ? "secondary" : "inherit"}
                            />
                          }
                        >
                          {comment?.likes} likes
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Fade>
        </Grid>
      )}
    </>
  );
};

CommentList.propTypes = {
  comment: PropTypes.object,
  postId: PropTypes.string,
  user: PropTypes.object,
  commentAdd: PropTypes.func,
  handleCommentLikes: PropTypes.func,
  handlePostLikes: PropTypes.func,
  handleReplayLikes: PropTypes.func,
  post: PropTypes.object,
  replyAdd: PropTypes.func,
};

export default CommentList;
