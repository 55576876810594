
import {
    Box,
    Button,
    CardMedia,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import Avatar from "ui-component/extended/Avatar";
import { gridSpacing } from "store/constant";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useConfig from "hooks/useConfig";
import MainCard from "ui-component/cards/MainCard";
import ImagePlaceholder from "ui-component/cards/Skeleton/ImagePlaceholder";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { ConnectWithoutContact } from "@mui/icons-material";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
const AlumniProfileAction = ({
    alumniDetails,
    isLoading,
    getProfileInfo,
    profileInfo
}) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const RequestId = useRequestIdGenerater();
    const { rendomRequestId, generateTimeStamp } = useTimeStamp();
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);

    const handleOpenDialog = (requestId) => {
        setSelectedRequestId(requestId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRequestId(null);
    };

    const handleCancelRequest = async (userId) => {
        const response = await axios.put(
            `/v1/connection/request/cancel/${userId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        "serviceToken"
                    )}`,
                    "Content-Type": "application/json",
                    requestId: RequestId(
                        Math.floor(1000 + Math.random() * 9999),
                        Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
                    ),
                },
            }
        )
        getProfileInfo()
            .then((value) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: "Cancelled Successfully",
                        variant: "alert",
                        alert: {
                            color: "success",
                        },
                        close: false,
                    })
                );
                generateTimeStamp();
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    generateTimeStamp();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response?.data?.data.Msg,
                            variant: "alert",
                            alert: {
                                color: "error",
                            },
                            close: false,
                        })
                    );
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: "Something went wrong",
                            variant: "alert",
                            alert: {
                                color: "error",
                            },
                            close: false,
                        })
                    );
                }
            });
    };
    const handleAcceptInvitation = async (requestId) => {
        const response = await axios
            .put(
                `/v1/connection/request/process/${requestId}?approvalStatus=Approved`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                            "serviceToken"
                        )}`,
                        "Content-Type": "application/json",
                        requestId: RequestId(
                            Math.floor(1000 + Math.random() * 9999),
                            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
                        ),
                    },
                }
            )

            .then((value) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: "Accepted Successfully",
                        variant: "alert",
                        alert: {
                            color: "success",
                        },
                        close: false,
                    })
                );
                getProfileInfo();
                generateTimeStamp();
            }).catch((error) => {
                if (error.response.status === 404) {
                    generateTimeStamp();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error?.response?.data?.Msg,
                            variant: "alert",
                            alert: {
                                color: "error",
                            },
                            close: false,
                        })
                    );
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: "Something went wrong",
                            variant: "alert",
                            alert: {
                                color: "error",
                            },
                            close: false,
                        })
                    );
                }
            });
    };

    const confirmRejectInvitation = async () => {
        if (!selectedRequestId) return;
        try {
            const response = await axios.put(
                `/v1/connection/request/process/${selectedRequestId}?approvalStatus=Rejected`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
                        "Content-Type": "application/json",
                        requestId: RequestId(
                            Math.floor(1000 + Math.random() * 9999),
                            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
                        ),
                    },
                }
            );
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Rejected Successfully",
                    variant: "alert",
                    alert: {
                        color: "success",
                    },
                    close: false,
                })
            );
            getProfileInfo();
            generateTimeStamp();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Something went wrong",
                    variant: "alert",
                    alert: {
                        color: "error",
                    },
                    close: false,
                })
            );
        } finally {
            handleCloseDialog();
        }
    };

    const handleConnect = async (userId) => {
        try {
            const response = await axios.put(
                `/v1/connection/request/${userId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                            "serviceToken"
                        )}`,
                        "Content-Type": "application/json",
                        requestId: RequestId(
                            Math.floor(1000 + Math.random() * 9999),
                            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
                        ),
                    },
                }
            );
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Your request sended to ${response?.data?.data?.name}`,
                    variant: "alert",
                    alert: {
                        color: "success",
                    },
                    close: false,
                })
            );
            generateTimeStamp();
            getProfileInfo()
        } catch (error) {
            generateTimeStamp();
            if (error.response && error.response.status === 404) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.Msg,
                        variant: "alert",
                        alert: {
                            color: "error",
                        },
                        close: false,
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: "Something went wrong",
                        variant: "alert",
                        alert: {
                            color: "error",
                        },
                        close: false,
                    })
                );
            }
        }
    };

    const btnSX = {
        borderColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.grey[200],
        background:
            theme.palette.mode === "dark"
                ? theme.palette.dark.dark
                : theme.palette.background.paper,
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard
                        contentSX={{
                            p: 1.5,
                            [theme.breakpoints.down("lg")]: {
                                textAlign: "center",
                            },
                        }}
                    >
                        {isLoading ? (
                            <ImagePlaceholder
                                sx={{
                                    borderRadius: `${borderRadius}px`,
                                    overflow: "hidden",
                                    mb: 3,
                                    height: { xs: 85, sm: 150, md: 260 },
                                }}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                image="https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2017/08/nature-design.jpg"
                                sx={{
                                    borderRadius: `${borderRadius}px`,
                                    overflow: "hidden",
                                    mb: 3,
                                    height: 200,
                                }}
                            />
                        )}
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={3}>
                                {isLoading ? (
                                    <ImagePlaceholder
                                        sx={{
                                            margin: "-70px 0 0 auto",
                                            borderRadius: "16px",
                                            [theme.breakpoints.down("lg")]: {
                                                margin: "-70px auto 0",
                                            },
                                            [theme.breakpoints.down("md")]: {
                                                margin: "-60px auto 0",
                                            },
                                            width: { xs: 72, sm: 100, md: 140 },
                                            height: { xs: 72, sm: 100, md: 140 },
                                        }}
                                    />
                                ) : (
                                    <Box
                                        position="relative"
                                    >
                                        <Avatar
                                            alt={alumniDetails?.name}
                                            src={`data:image/*;base64,${alumniDetails?.avatar?.data}`}
                                            sx={{
                                                margin: "-70px 0 0 auto",
                                                borderRadius: "16px",
                                                [theme.breakpoints.down("lg")]: {
                                                    margin: "-70px auto 0",
                                                },
                                                [theme.breakpoints.down("md")]: {
                                                    margin: "-60px auto 0",
                                                },

                                                width: { xs: 72, sm: 100, md: 140 },
                                                height: { xs: 72, sm: 100, md: 140 },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" sx={{ color: "#245081" }}>
                                            {alumniDetails?.name}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {alumniDetails?.batch?.tag} |{" "}
                                            {alumniDetails?.programs?.name}
                                        </Typography>
                                        {profileInfo?.connectionRequestByUser && (

                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Chip
                                                        variant="outlined"
                                                        color="secondary"
                                                        label="Pending"
                                                        icon={<WatchLaterOutlinedIcon />}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() => handleCancelRequest(profileInfo?.connectionRequestByUser?.toUserId)}
                                                        variant="outlined"
                                                        fullWidth
                                                        color="error"
                                                        sx={btnSX}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        )}
                                        {profileInfo?.connectionRequestByAlumni && (

                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() => handleAcceptInvitation(profileInfo?.connectionRequestByAlumni?.id)}
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={btnSX}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() => handleOpenDialog(profileInfo?.connectionRequestByAlumni?.id)}
                                                        variant="outlined"
                                                        fullWidth
                                                        color="error"
                                                        sx={btnSX}
                                                    >
                                                        Reject
                                                    </Button>

                                                </Grid>
                                            </Grid>

                                        )}
                                        {!profileInfo?.connected && profileInfo?.connectionRequestByAlumni === null && profileInfo?.connectionRequestByUser === null && (
                                            <Grid container>
                                                <Grid item md={4} lg={4} xs={12} >
                                                    <Button
                                                        onClick={() => handleConnect(alumniDetails?.userId)}
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{ color: 'primary' }}
                                                        startIcon={<ConnectWithoutContact />}
                                                    >
                                                        Connect
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Rejection</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to reject this invitation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmRejectInvitation} color="error" autoFocus>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AlumniProfileAction;
