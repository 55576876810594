import PropTypes from "prop-types";

// material-ui
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports

import Avatar from "ui-component/extended/Avatar";
import SubCard from "ui-component/cards/SubCard";
import { gridSpacing } from "store/constant";
import VerifiedIcon from "@mui/icons-material/Verified";

// assets

import { useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { useTheme } from "@mui/material/styles";

import EditAbout from "./EditAbout";

import { useLocation, useNavigate } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import SocialProfie from "./SocialProfie";
import UploadProfilePicture from "./UploadProfilePicture";
import useConfig from "hooks/useConfig";
import MainCard from "ui-component/cards/MainCard";
import ImagePlaceholder from "ui-component/cards/Skeleton/ImagePlaceholder";

import AnimatedLoader from "reuseableTable/AnimatedLoader";
import PersonalDetailsCard from "./PersonalDetailsCard";

import WorkingDetailsCard from "./WorkingDetailsCard";
import EducationDetailsCard from "./EducationDetailsCard";
import MainScocialProfile from "views/alumniSide/socialmedia";

// progress
function LinearProgressWithLabel({ value, ...others }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          mr: 1,
        }}
      >
        <LinearProgress value={value} {...others} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

// ==============================|| PROFILE 1 - PROFILE ||============================== //

const ProfilePage = ({
  alumniDetails,
  getAlumniDetails,
  isLoading,
  IsConnection,
  setViewProfile,
  renderFrom = "Other",
}) => {
  const [editMode, setEditMode] = useState(false);
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const { borderRadius } = useConfig();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const { state } = useLocation();
  const alumniDetailsInfo = alumniDetails === null ? state.data : alumniDetails;

  const handleAddProfile = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenUpdateProfile = () => {
    setOpenProfileUpdate(true);
  };

  const handleCloseUpdateProfile = () => {
    setOpenProfileUpdate(false);
  };

  const Role = localStorage.getItem("role");

  const isAlumn = Role === "ROLE_ALUMNI";

  const handleDoubleClick = () => {
    setEditMode(isAlumn ? true : false);
  };

  const handleAddNavigation = (id) => {
    navigate(`/update/workingstatus/${id}`);
  };
  const handleAddEducatiobNavigation = (id) => {
    navigate(`/update/educationstatus/${id}`);
  };
  const handleNavigateWorkingStatus = (id) => {
    navigate(`/update/careerStatus/${id}`);
  };
  const handleNavigate = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleSocialMediaLinkClick = (socialMediaType) => {
    const link = alumniDetails?.socialLinks?.find(
      (link) => link.socialLinkType === socialMediaType
    );

    if (link) {
      window.open(link.socialLink, "_blank");
    }
  };

  const handleLinkedInClick = () => {
    handleSocialMediaLinkClick("LinkedIn");
  };

  // To handle clicking on a Twitter icon
  const handleTwitterClick = () => {
    handleSocialMediaLinkClick("Twitter");
  };

  const handleLoginLinkedInClick = () => {
    const clientId = "86ottvr9b434ca";
    const redirectUri = encodeURIComponent(
      "http://192.168.68.134:5000/api/v1/auth/login/oauth2/code/linkedin"
    );
    const scope = encodeURIComponent("w_member_social");
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    window.location.href = linkedInAuthUrl;
  };

  const [isOpenTab, setIsOpenTab] = useState(false);
  const handleOpenMsgTab = () => {
    setIsOpenTab(true);
    // setViewProfile(false);
  };

  return (
    <MainCard pt={1}>
      {isOpenTab ? (
        <>
          <Grid item md={12} lg={12} xs={12} p={2}>
            <Button onClick={() => setIsOpenTab(false)} variant="outlined">
              Back to Profile
            </Button>
          </Grid>
          <MainScocialProfile
            isOpenTab={isOpenTab}
            setIsOpenTab={isOpenTab}
            alumniDetailsInfo={alumniDetailsInfo}
            renderFrom="HeaderSearch"
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <AnimatedLoader />
          ) : (
            <>
              <Grid container spacing={gridSpacing} pt={IsConnection ? 0 : 2}>
                <Grid item xs={12}>
                  <MainCard
                    contentSX={{
                      p: 1.5,
                      paddingBottom: "0px !important",
                      [theme.breakpoints.down("lg")]: {
                        textAlign: "center",
                      },
                    }}
                  >
                    {isLoading ? (
                      <ImagePlaceholder
                        sx={{
                          borderRadius: `${borderRadius}px`,
                          overflow: "hidden",
                          mb: 3,
                          height: { xs: 85, sm: 150, md: 260 },
                        }}
                      />
                    ) : (
                      <CardMedia
                        component="img"
                        image="https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2017/08/nature-design.jpg"
                        sx={{
                          borderRadius: `${borderRadius}px`,
                          overflow: "hidden",
                          mb: 3,
                          height: 200,
                        }}
                      />
                    )}
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} md={3}>
                        {isLoading ? (
                          <ImagePlaceholder
                            sx={{
                              margin: "-70px 0 0 auto",
                              borderRadius: "16px",
                              [theme.breakpoints.down("lg")]: {
                                margin: "-70px auto 0",
                              },
                              [theme.breakpoints.down("md")]: {
                                margin: "-60px auto 0",
                              },
                              width: { xs: 72, sm: 100, md: 140 },
                              height: { xs: 72, sm: 100, md: 140 },
                            }}
                          />
                        ) : (
                          <Box
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            position="relative"
                          >
                            <Avatar
                              alt={alumniDetailsInfo?.name}
                              src={alumniDetailsInfo?.largeAvatar}
                              sx={{
                                margin: "-70px 0 0 auto",
                                borderRadius: "16px",
                                [theme.breakpoints.down("lg")]: {
                                  margin: "-70px auto 0",
                                },
                                [theme.breakpoints.down("md")]: {
                                  margin: "-60px auto 0",
                                },

                                width: { xs: 72, sm: 100, md: 140 },
                                height: { xs: 72, sm: 100, md: 140 },
                                border: alumniDetailsInfo?.starAlumni
                                  ? "5px solid #D89E46"
                                  : "none",
                              }}
                            />
                            {hover && (
                              <>
                                {!IsConnection && (
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: matches ? "50%" : "80%",
                                      alignItems: "center",
                                      transform: "translate(-50%, -50%)",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.7)",
                                    }}
                                    size="large"
                                    onClick={handleOpenUpdateProfile}
                                  >
                                    <CloudUploadOutlinedIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </Box>
                        )}
                      </Grid>
                      {!matches ? (
                        <Grid item xs={12} md={9}>
                          <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={4}>
                              <Typography
                                variant="h4"
                                // sx={{ color: "#245081" }}
                                sx={{
                                  color: "#245081",
                                  display: "inline-flex", // Align name and icon on the same line
                                  alignItems: "center", // Vertically align the icon with the text
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {alumniDetailsInfo?.name}
                                {alumniDetailsInfo?.starAlumni && (
                                  <VerifiedIcon
                                    sx={{
                                      color: "#D89E46",
                                      fontSize: "19px",
                                      marginLeft: "4px",
                                    }}
                                  />
                                )}
                              </Typography>
                              <Typography variant="subtitle1">
                                {alumniDetailsInfo?.batch?.tag} |{" "}
                                {alumniDetailsInfo?.programs?.name}
                              </Typography>

                              {alumniDetailsInfo?.socialLinks === null ||
                              alumniDetailsInfo?.socialLinks?.length === 0 ? (
                                <>
                                  {!IsConnection && (
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleAddProfile}
                                      >
                                        Add Social Profile
                                      </Typography>
                                    </>
                                  )}
                                </>
                              ) : (
                                <Grid
                                  item
                                  md={12}
                                  xs={12}
                                  spacing={1}
                                  sx={{
                                    display: "flex",
                                    p: 1,
                                    position: "relative",
                                  }}
                                  onMouseEnter={() => setShowEditButton(true)}
                                  onMouseLeave={() => setShowEditButton(false)}
                                >
                                  {alumniDetailsInfo?.socialLinks?.find(
                                    (link) => link.socialLinkType === "LinkedIn"
                                  ) && (
                                    <Avatar
                                      src="/assets/images/icons/LinkedIn.png"
                                      sx={{
                                        height: "23px",
                                        width: "23px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleLinkedInClick}
                                    />
                                  )}
                                  {alumniDetailsInfo?.socialLinks?.find(
                                    (link) => link.socialLinkType === "Twitter"
                                  ) && (
                                    <Avatar
                                      src="/assets/images/icons/twitter.png"
                                      sx={{
                                        height: "23px",
                                        width: "23px",
                                        ml: 1,
                                        cursor: "pointer",
                                      }}
                                      onClick={handleTwitterClick}
                                    />
                                  )}

                                  {showEditButton && (
                                    <>
                                      <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        sx={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "30%",
                                          transform: "translate(-50%, -50%)",
                                          transition: "opacity 0.3s",
                                          opacity: 1,
                                          "@media (max-width: 600px)": {
                                            top: "110%",
                                          },
                                        }}
                                      >
                                        {!IsConnection && (
                                          <IconButton
                                            onClick={handleAddProfile}
                                          >
                                            <EditOutlinedIcon
                                              sx={{ fontSize: "20px" }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              )}

                              {IsConnection && renderFrom === "HeaderSearch" && (
                                <Grid>
                                  <Button
                                    onClick={() => handleOpenMsgTab()}
                                    variant="outlined"
                                  >
                                    Message
                                  </Button>
                                </Grid>
                              )}

                              <SocialProfie
                                alumniDetails={alumniDetailsInfo}
                                open={openDialog}
                                getAlumniDetails={getAlumniDetails}
                                handleClose={handleCloseDialog}
                              />
                            </Grid>

                            <Grid item xs={12} md={8}>
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  justifyContent: "flex-end",
                                  [theme.breakpoints.down("lg")]: {
                                    justifyContent: "center",
                                  },
                                }}
                              ></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12} md={4}>
                            <Typography
                              variant="h4"
                              sx={{
                                color: "#245081",
                                textTransform: "uppercase",
                              }}
                            >
                              {alumniDetailsInfo?.name}
                            </Typography>

                            <Typography variant="subtitle1">
                              {alumniDetailsInfo?.batch?.tag} |{" "}
                              {alumniDetailsInfo?.programs?.name}
                            </Typography>
                            {IsConnection && renderFrom === "HeaderSearch" && (
                              <Grid>
                                <Button
                                  onClick={() => handleOpenMsgTab()}
                                  variant="outlined"
                                >
                                  Message
                                </Button>
                              </Grid>
                            )}

                            {alumniDetailsInfo?.socialLinks === null ||
                            alumniDetailsInfo?.socialLinks?.length === 0 ? (
                              <>
                                {!IsConnection && (
                                  <>
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleAddProfile}
                                    >
                                      Add Social Profile
                                    </Typography>
                                  </>
                                )}
                              </>
                            ) : (
                              <Grid
                                item
                                md={12}
                                xs={12}
                                spacing={1}
                                sx={{
                                  display: "flex",
                                  p: 1,
                                  position: "relative",
                                }}
                                onMouseEnter={() => setShowEditButton(true)}
                                onMouseLeave={() => setShowEditButton(false)}
                              >
                                {alumniDetailsInfo?.socialLinks?.find(
                                  (link) => link.socialLinkType === "LinkedIn"
                                ) && (
                                  <Avatar
                                    src="/assets/images/icons/LinkedIn.png"
                                    sx={{
                                      height: "23px",
                                      width: "23px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleLinkedInClick}
                                  />
                                )}
                                {alumniDetailsInfo?.socialLinks?.find(
                                  (link) => link.socialLinkType === "Twitter"
                                ) && (
                                  <Avatar
                                    src="/assets/images/icons/twitter.png"
                                    sx={{
                                      height: "23px",
                                      width: "23px",
                                      ml: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={handleTwitterClick}
                                  />
                                )}
                                {showEditButton && (
                                  <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "30%",
                                      transform: "translate(-50%, -50%)",
                                      transition: "opacity 0.3s",
                                      opacity: 1,
                                      "@media (max-width: 600px)": {
                                        top: "110%",
                                      },
                                    }}
                                  >
                                    {!IsConnection && (
                                      <IconButton onClick={handleAddProfile}>
                                        <EditOutlinedIcon
                                          sx={{ fontSize: "20px" }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            )}

                            <SocialProfie
                              alumniDetails={alumniDetailsInfo}
                              open={openDialog}
                              getAlumniDetails={getAlumniDetails}
                              handleClose={handleCloseDialog}
                            />
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                justifyContent: "flex-end",
                                [theme.breakpoints.down("lg")]: {
                                  justifyContent: "center",
                                },
                              }}
                            ></Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12} md={12} />

                      <UploadProfilePicture
                        IsConnection={IsConnection}
                        alumniDetails={alumniDetailsInfo}
                        getAlumniDetails={getAlumniDetails}
                        open={openProfileUpdate}
                        onClose={handleCloseUpdateProfile}
                      />
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>

              {alumniDetailsInfo?.alumniStatus !== "Pending" ? (
                <>
                  {alumniDetails?.addedBy !== null ? (
                    <Grid container spacing={1} pt={2}>
                      <>
                        <Grid item md={6} xs={12}>
                          <PersonalDetailsCard
                            IsConnection={IsConnection}
                            alumniDetails={alumniDetailsInfo}
                            handleNavigateWorkingStatus={
                              handleNavigateWorkingStatus
                            }
                            handleNavigate={handleNavigate}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <SubCard
                            sx={{
                              p: 1,
                              overflow: "auto",
                              "&::-webkit-scrollbar": {
                                width: "0.5em",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#ffffff",
                              },
                            }}
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <InfoIcon sx={{ marginRight: 1 }} /> */}
                                  <span
                                    style={{
                                      color: "#245081",
                                      fontSize: "18px",
                                    }}
                                  >
                                    About
                                  </span>
                                </Typography>
                              </div>
                            }
                            secondary={
                              <>
                                {!IsConnection && (
                                  <>
                                    {alumniDetailsInfo?.aboutMe !== "" && (
                                      <div style={{ cursor: "pointer" }}>
                                        <img
                                          height="20px"
                                          onClick={handleDoubleClick}
                                          alt="icon"
                                          src="/assets/images/icons/editIcon.svg"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            }
                          >
                            <Grid item xs={12} md={12}>
                              {editMode ? (
                                <>
                                  <EditAbout
                                    isAlumn={isAlumn}
                                    setEditMode={setEditMode}
                                    editMode={editMode}
                                    handleDoubleClick={handleDoubleClick}
                                    getAlumniDetails={getAlumniDetails}
                                    alumniDetails={alumniDetailsInfo}
                                    handleCloseAbout={() => setEditMode(false)}
                                  />
                                </>
                              ) : (
                                <Typography variant="body2">
                                  {alumniDetailsInfo?.aboutMe !== null &&
                                  alumniDetailsInfo?.aboutMe !== "" ? (
                                    alumniDetailsInfo?.aboutMe
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "10px",
                                      }}
                                    >
                                      {!IsConnection ? (
                                        <img
                                          height="40px"
                                          onClick={handleDoubleClick}
                                          alt="icon"
                                          src="/assets/images/icons/addPlus.svg"
                                        />
                                      ) : (
                                        <Typography>No About </Typography>
                                      )}
                                    </div>
                                  )}
                                </Typography>
                              )}
                            </Grid>
                          </SubCard>

                          <WorkingDetailsCard
                            IsConnection={IsConnection}
                            alumniDetails={alumniDetailsInfo}
                            handleNavigateWorkingStatus={handleAddNavigation}
                            handleNavigate={handleNavigate}
                          />
                          <EducationDetailsCard
                            IsConnection={IsConnection}
                            alumniDetails={alumniDetailsInfo}
                            handleAddEducatiobNavigation={
                              handleAddEducatiobNavigation
                            }
                            handleNavigate={handleNavigate}
                          />
                        </Grid>
                      </>
                    </Grid>
                  ) : (
                    <MainCard>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 4,
                        }}
                      >
                        {alumniDetailsInfo?.alumniStatus === "Cancel" ? (
                          <Grid item md={12} xs={12} mt={2}>
                            <Alert variant="filled" severity="error">
                              Your application has been rejected due to the
                              following reason:{" "}
                              {alumniDetailsInfo?.approvalNote}
                            </Alert>
                          </Grid>
                        ) : (
                          <>
                            <Grid
                              md={12}
                              sx={{
                                p: 2,
                                // boxShadow: "0 6px 8px #00000030",
                                border: "2px solid #F2DDD9",
                                background: "#D5E0EC",
                              }}
                            >
                              <Typography
                                onClick={() =>
                                  handleNavigate(alumniDetailsInfo?.id)
                                }
                                variant="subtitle1"
                                sx={{
                                  textAlign: "center",
                                  color: "blue",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                Complete Your Profile.
                              </Typography>
                              <Typography sx={{ textAlign: "center" }}>
                                Please take a moment to complete your profile.
                                It'll help you connect better with other members
                                of our organization.
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </MainCard>
                  )}
                </>
              ) : (
                <Grid item md={12} mt={2}>
                  <>
                    <MainCard>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          md={12}
                          sx={{
                            p: 2,
                            // boxShadow: "0 6px 8px #00000030",
                            border: "2px solid #F2DDD9",
                            background: "#D5E0EC",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            We're evaluating your Profile.
                          </Typography>
                          <Typography
                            sx={{ textAlign: "center", fontSize: "16px" }}
                          >
                            In order to make sure our community holds up a
                            standard, we don,t allow any profile to get in.
                          </Typography>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </MainCard>
  );
};

export default ProfilePage;
