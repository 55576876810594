import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { gridSpacing } from "store/constant";
import VerifiedIcon from "@mui/icons-material/Verified";

import { ConnectWithoutContact } from "@mui/icons-material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { openSnackbar } from "store/slices/snackbar";
import { IconEye } from "@tabler/icons";

// ==============================|| USER PROFILE CARD ||============================== //

const AlumniProfileCard = ({ user, setAlumniData, handleViewProfile }) => {
  const theme = useTheme();
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleConnect = async (userId) => {
    try {
      const response = await axios.put(
        `/v1/connection/request/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      const updatedUser = response?.data?.data;

      setAlumniData((prevData) => {
        const updatedData = prevData?.map((userData) =>
          userData.userId === updatedUser?.userId ? updatedUser : userData
        );
        return updatedData;
      });

      dispatch(
        openSnackbar({
          open: true,
          message: `Your request sended to ${updatedUser?.name}`,
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      if (error.response && error.response.status === 404) {
        dispatch(
          openSnackbar({
            open: true,
            message: error.response.data.Msg,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: "Something went wrong",
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      }
    }
  };

  const handleCancelRequest = async (userId) => {
    try {
      const response = await axios.put(
        `/v1/connection/request/cancel/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      const updatedUser = response?.data?.data;

      setAlumniData((prevData) => {
        const updatedData = prevData?.map((userData) =>
          userData.userId === updatedUser?.userId ? updatedUser : userData
        );
        return updatedData;
      });

      dispatch(
        openSnackbar({
          open: true,
          message: `Your request canceled with ${updatedUser?.name}`,
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      if (error.response && error.response.status === 404) {
        dispatch(
          openSnackbar({
            open: true,
            message: error.response.data.Msg,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: "Something went wrong",
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      }
    }
  };

  return (
    <Card
      sx={{
        background:
          theme.palette.mode === "dark"
            ? theme.palette.dark.main
            : theme.palette.grey[50],
        border: theme.palette.mode === "dark" ? "none" : "1px solid",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        borderColor: theme.palette.grey[100],
        textAlign: "center",
      }}
    >
      <CardMedia
        component="img"
        src={
          user?.avatar?.data ? `data:image/*;base64, ${user.avatar.data}` : null
        }
        sx={{
          height: "105px",
          backgroundColor: user?.avatar?.data ? null : "#BDDCF2",
        }}
      />

      <CardContent sx={{ p: 2, pb: "16px !important" }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Avatar
                  alt={user?.name}
                  src={`data:image/*;base64, ${user?.avatar?.data}`}
                  sx={{
                    width: 100,
                    height: 100,
                    m: "-70px auto 0",
                    border: user?.starAlumni ? "4px solid #D89E46" : "none", 
                  }}
                />
                {console.log(user, "USER")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{
                    display: "inline-flex", // Make the text and icon appear on the same line
                    alignItems: "center", // Vertically align the icon with the text
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    margin: 0,
                  }}
                >
                  {user?.name}
                </Typography>
                {user?.starAlumni && (
                  <VerifiedIcon
                    sx={{
                      color: "#D89E46",
                      fontSize: "18px",
                      marginLeft: "4px",
                    }}
                  />
                )}
                <Typography>{user?.programs?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {user?.requestStatus === null ? (
            <Grid item xs={12}>
              <Button
                onClick={() => handleConnect(user?.userId)}
                variant="outlined"
                fullWidth
                sx={{ color: "primary" }}
                startIcon={<ConnectWithoutContact />}
              >
                Connect
              </Button>
            </Grid>
          ) : (
            <>
              {user?.requestStatus !== "Approved" ? (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => handleConnect(user?.userId)}
                          variant="outlined"
                          fullWidth
                          disabled
                          startIcon={<WatchLaterOutlinedIcon />}
                        >
                          Pending
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => handleCancelRequest(user?.userId)}
                          variant="outlined"
                          fullWidth
                          color="error"
                          startIcon={<HighlightOffOutlinedIcon />}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleViewProfile(user?.userId)}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    startIcon={<IconEye />}
                  >
                    View Profile
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

AlumniProfileCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  profile: PropTypes.string,
  role: PropTypes.string,
  status: PropTypes.string,
};

export default AlumniProfileCard;
