import { Grid, IconButton, TextField } from "@mui/material";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";

const EditAbout = ({
  isAlumn,
  alumniDetails,
  setEditMode,
  editMode,
  getAlumniDetails,
  handleCloseAbout,
}) => {
  const [aboutMe, setAboutMe] = useState(alumniDetails?.aboutMe);
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleChange = (event, data) => {
    setAboutMe(event.target.value);
  };
  const handleClose = () => {
    setEditMode(false);
  };

  const handleSubmit = (alumniId) => {
    const payload = {
      about: aboutMe,
    };
    axios
      .put(`/v1/alumni/update/about?alumniId=${alumniId}`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            payload + rendomRequestId
          ),
        },
      })
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Updated Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        getAlumniDetails();
        setEditMode(false);
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: error.response.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <>
      {isAlumn && (
        <>
          <TextField
            autoFocus
            fullWidth
            multiline
            variant="outlined"
            value={aboutMe || ""}
            onChange={(e) => handleChange(e, alumniDetails?.id)}
          />
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={() => handleSubmit(alumniDetails?.id)}>
              <DoneOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => setEditMode(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </>
      )}
    </>
  );
};

export default EditAbout;
