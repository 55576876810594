import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  Link,
  Grid,
  IconButton,
  Modal,
  Chip,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import useRequestIdGenerater from "customhooks";
import CampaignIcon from "@mui/icons-material/Campaign";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import MainCard from "ui-component/cards/MainCard";
import NoAvailableData from "reuseableTable/NoAvailableData";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import xlsxIcon from "../../../../../assets/fileIcons/XLS.svg";
import pdfIcon from "../../../../../assets/fileIcons/PDF.svg";
import docsIcon from "../../../../../assets/fileIcons/DOCS.svg";
import pptxIcon from "../../../../../assets/fileIcons/pptx-file.png";
import videoIcon from "../../../../../assets/fileIcons/video.png";
import txtfileIcon from "../../../../../assets/fileIcons/txt-file.png";
import attechmentIcon from "../../../../../assets/fileIcons/attachment.png";
import moment from "moment";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const formatContent = (content) => {
  const formattedContent = content.replace(/\n\n/g, "\n");
  return formattedContent.split("\n").map((line, index) => (
    <Typography variant="body1" key={index} sx={{ marginBottom: 1 }}>
      {line}
    </Typography>
  ));
};

const announcementColors = [
  "#3F51B5",
  "#673AB7",
  "#FF5722",
  "#009688",
  "#795548",
  "#607D8B",
  "#1E88E5",
  "#43A047",
  "#D32F2F",
  "#F57C00",
  "#8E24AA",
  "#2E7D32",
];

const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const [openFullImage, setOpenFullImage] = useState(false);
  const [fullImageSrc, setFullImageSrc] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const handleImageClick = (src) => {
    console.log(src, "SRC");

    setOpenFullImage(true);
    setFullImageSrc(src);
  };

  const handleCloseFullImage = () => {
    setOpenFullImage(false);
    setFullImageSrc("");
  };

  const getAnnouncements = async () => {
    if (!hasMore) return; // Stop fetching if there's no more data
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/announcement/my?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      const newAnnouncements = response.data.data?.content || [];
      setAnnouncements((prev) => [...prev, ...newAnnouncements]);
      setHasMore(newAnnouncements.length === pageSize); // If less than pageSize, no more data
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 50 // Near the bottom
    ) {
      setPageNumber((prev) => prev + 1);
    }
  };

  useEffect(() => {
    getAnnouncements();
  }, [pageNumber]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <MainCard>
        <Typography
          variant="h3"
          component="h1"
          sx={{ marginBottom: 4, fontWeight: "bold" }}
        >
          Announcements <CampaignIcon color="secondary" />
        </Typography>

        {isLoading && <AnimatedLoader />}

        {announcements.map((announcement, index) => (
          <Card
            key={announcement.id}
            variant="outlined"
            sx={{ marginBottom: 3, boxShadow: 2 }}
          >
            <CardContent>
              {/* Title */}
              <Typography
                variant="h4"
                component="div"
                sx={{
                  fontWeight: "bold",
                  marginBottom: 2,
                  color: announcementColors[index % announcementColors.length], // Rotate through colors
                }}
              >
                {announcement.title}
              </Typography>

              {/* Meta Information */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <Box
                  sx={{
                    backgroundColor: "secondary.light", // Light background for the icon
                    padding: 0.3,
                    borderRadius: "20%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EventIcon color="secondary" sx={{ fontSize: 18 }} />
                  {/* Dark icon color */}
                </Box>
                &nbsp;{" "}
                {`${moment(announcement.publishingDate).format(
                  "DD MMM YYYY"
                )} at
                ${announcement.publishingTime}`}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
              >
                <Box
                  sx={{
                    backgroundColor: "#e3f2fd",
                    padding: 0.3,
                    borderRadius: "20%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PersonIcon color="primary" sx={{ fontSize: 18 }} />{" "}
                  {/* Dark icon color */}
                </Box>
                &nbsp; By: {announcement.createdBy}
              </Typography>

              {/* Content */}
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                {formatContent(announcement.content)}
              </Typography>

              {/* Attachments */}

              {announcement.attachments && announcement.attachments.length > 0 && (
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    Attachments:
                  </Typography>
                  <Grid container spacing={2}>
                    {announcement.attachments.map((attachment, index) => {
                      const filename = decodeURIComponent(
                        attachment.split("/").pop().split("?")[0]
                      );
                      const fileExtension = filename
                        .split(".")
                        .pop()
                        .toLowerCase();

                      const isImage =
                        fileExtension === "jpg" ||
                        fileExtension === "jpeg" ||
                        fileExtension === "png" ||
                        fileExtension === "gif" ||
                        fileExtension === "webp" ||
                        fileExtension === "bmp" ||
                        fileExtension === "svg";

                      const isPDF = fileExtension === "pdf";

                      const isWord =
                        fileExtension === "docx" || fileExtension === "doc";

                      const isExcel =
                        fileExtension === "xlsx" || fileExtension === "xls";

                      const isPowerPoint =
                        fileExtension === "pptx" || fileExtension === "ppt";
                      const isText = fileExtension === "txt";
                      const isZip = fileExtension === "zip";

                      return (
                        <Grid item xs={12} sm={4} md={3} key={index}>
                          {isImage ? (
                            // Show image inline
                            <Box
                              sx={{
                                position: "relative",
                                cursor: "pointer",
                                "&:hover .zoom-icon": {
                                  opacity: 1,
                                },
                              }}
                              onClick={() => handleImageClick(attachment)}
                            >
                              <Box
                                component="img"
                                src={attachment}
                                alt={filename}
                                sx={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "150px",
                                  borderRadius: 1,
                                  boxShadow: 1,
                                  objectFit: "cover",
                                }}
                              />
                              <Tooltip title="Click to full-screen">
                                <IconButton
                                  className="zoom-icon"
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    opacity: 0,
                                    transition: "opacity 0.3s",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                  }}
                                >
                                  <FullscreenIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : isPDF ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={pdfIcon} alt="pdf" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isWord ? (
                            // Show Word icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={docsIcon} alt="doc" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isExcel ? (
                            // Show Excel icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={xlsxIcon} alt="xlsx" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isPowerPoint ? (
                            // Show PowerPoint icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={pptxIcon} alt="pptx" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isText ? (
                            // Show Text file icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={txtfileIcon} alt="txtfileIcon" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isZip ? (
                            // Show ZIP file icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={attechmentIcon} alt="attechmentIcon" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : (
                            // Fallback for other file types
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AttachFileIcon
                                sx={{ fontSize: 16, marginRight: 1 }}
                              />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{ wordBreak: "break-all" }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>
        ))}

        {/* No Announcements */}
        {!isLoading && announcements.length === 0 && (
          <NoAvailableData
            text={" No announcements available at the moment."}
          />
        )}
      </MainCard>
      <Modal
        open={openFullImage}
        onClose={handleCloseFullImage}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Box
            component="img"
            src={fullImageSrc}
            alt="Full image"
            sx={{
              maxWidth: "80vw",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Announcement;
