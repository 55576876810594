import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import ReceivedInvitations from "./ReceivedInvitations";
import SentInvitation from "./SentInvitation";


const Invitations = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MainCard>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
        aria-label="tabs"
      >
        <Tab label="Received" />
        <Tab label="Sent" />
      </Tabs>
      <div className="tab-content">
        {activeTab === 0 && <ReceivedInvitations />}
        {activeTab === 1 && <SentInvitation />}
      </div>
    </MainCard>
  );
};

export default Invitations;
