import { lazy } from "react";

// project imports

import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// login routing

const AlumniRegister = Loadable(
  lazy(() => import("views/alumniRegistration/index"))
);
const AlumniProfile = Loadable(
  lazy(() => import("views/alumniRegistration/alumniProfile/index"))
);
const EditRegistration = Loadable(
  lazy(() => import("views/alumniRegistration/EditRegistration"))
);
const PendingApplication = Loadable(
  lazy(() => import("views/alumniRegistration/pendingApplication/index"))
);
const PendingPost = Loadable(
  lazy(() => import("views/application/postApporval/index"))
);
const PendingStarAlumni = Loadable(
  lazy(() => import("views/application/starAlumni/index"))
);
const PendingPostDetails = Loadable(
  lazy(() => import("views/application/postApporval/PostDetails"))
);
const PendingStarAlumniDetails = Loadable(
  lazy(() => import("views/application/starAlumni/PendingStarAlumniDetails"))
);
const AddWorkingDetails = Loadable(
  lazy(() => import("views/alumniRegistration/alumniProfile/WorkingDetail"))
);
const AddEducationDetails = Loadable(
  lazy(() => import("views/alumniRegistration/alumniProfile/EducationDetails"))
);
const UpdateCareerStatus = Loadable(
  lazy(() =>
    import("views/alumniRegistration/alumniProfile/UpdateCareerStatus")
  )
);

// ==============================|| AUTH ROUTING ||============================== //

const RegistrationRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/alumni/register",
      element: <AlumniRegister />,
    },
    {
      path: "/edit/:id",
      element: <EditRegistration />,
    },
    {
      path: "/alumni/profile/:id",
      element: <AlumniProfile />,
    },
    {
      path: "/alumni/profile",
      element: <AlumniProfile />,
    },
    {
      path: "/update/workingstatus/:id",
      element: <AddWorkingDetails />,
    },
    {
      path: "/update/educationstatus/:id",
      element: <AddEducationDetails />,
    },
    {
      path: "/update/careerStatus/:id",
      element: <UpdateCareerStatus />,
    },
    {
      path: "/pending/application",
      element: <PendingApplication />,
    },
    {
      path: "/pending/post",
      element: <PendingPost />,
    },
    {
      path: "/pending/post/details/:id",
      element: <PendingPostDetails />,
    },
    {
      path: "/pending/star-alumni",
      element: <PendingStarAlumni />,
    },
    {
      path: "/pending/star-alumni/details/:id",
      element: <PendingStarAlumniDetails />,
    },
  ],
};

export default RegistrationRoute;
