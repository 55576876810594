import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Card,
  Link,
  Button,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import DownloadIcon from "@mui/icons-material/Download";
import xlsxIcon from "../../assets/fileIcons/XLS.svg";
// import xlsxIcon from "../../../assets/fileIcons/XLS.svg";
import pdfIcon from "../../assets/fileIcons/PDF.svg";
import docsIcon from "../../assets/fileIcons/DOCS.svg";
import pptxIcon from "../../assets/fileIcons/pptx-file.png";
import txtfileIcon from "../../assets/fileIcons/txt-file.png";
import attechmentIcon from "../../assets/fileIcons/attachment.png";

const ApplicationDetailsDialog = ({ open, onClose, applicationId }) => {
  const [postDetails, setPostDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  console.log(applicationId, "asdfgh");
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/v1/alumni/star-alumni-application-details?applicationId=${applicationId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setPostDetails(response?.data?.data);
      generateTimeStamp();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      generateTimeStamp();
    } finally {
      setLoading(false);
      generateTimeStamp();
    }
  };

  useEffect(() => {
    if (applicationId) fetchData();
  }, [applicationId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Star Alumni Application Details</DialogTitle>
      <DialogContent>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : postDetails ? (
          <Box>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {postDetails?.achievements?.map((achievement, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{ padding: 1.5, mb: 1, width: "100%" }}
                >
                  <Typography variant="h4" fontWeight="bold">
                    Achievement {index + 1}
                  </Typography>
                  <Typography fontWeight="bold" sx={{ mt: 1 }}>
                    Description:
                  </Typography>
                  <Typography>{achievement.description}</Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CalendarMonthIcon color="primary" sx={{ mr: 1 }} />
                      {achievement.month} {achievement.year}
                    </Box>
                  </Typography>

                  <Grid container spacing={2} mb={1.5}>
                    {achievement.attachments.map((attachment, index) => {
                      const filename = decodeURIComponent(
                        attachment.split("/").pop().split("?")[0]
                      );
                      const fileExtension = filename
                        .split(".")
                        .pop()
                        .toLowerCase();

                      const isImage =
                        fileExtension === "jpg" ||
                        fileExtension === "jpeg" ||
                        fileExtension === "png" ||
                        fileExtension === "gif" ||
                        fileExtension === "webp" ||
                        fileExtension === "bmp" ||
                        fileExtension === "svg";

                      const isPDF = fileExtension === "pdf";

                      const isWord =
                        fileExtension === "docx" || fileExtension === "doc";

                      const isExcel =
                        fileExtension === "xlsx" || fileExtension === "xls";

                      const isPowerPoint =
                        fileExtension === "pptx" || fileExtension === "ppt";
                      const isText = fileExtension === "txt";
                      const isZip = fileExtension === "zip";

                      return (
                        <Grid item xs={12} sm={4} md={4} key={index}>
                          {isImage ? (
                            // Show image inline
                            <Box
                              sx={{
                                position: "relative",
                                cursor: "pointer",
                                "&:hover .zoom-icon": {
                                  opacity: 1,
                                },
                              }}
                              //   onClick={() => handleImageClick(attachment)}
                            >
                              <Box
                                component="img"
                                src={attachment}
                                alt={filename}
                                sx={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "150px",
                                  borderRadius: 1,
                                  boxShadow: 1,
                                  objectFit: "cover",
                                }}
                              />
                              <Tooltip title="Click to download">
                                <Link
                                  href={attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  color="primary"
                                  sx={{
                                    wordBreak: "break-word",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <IconButton
                                    className="zoom-icon"
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      opacity: 0,
                                      transition: "opacity 0.3s",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      color: "white",
                                    }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                            </Box>
                          ) : isPDF ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={pdfIcon} alt="pdf" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isWord ? (
                            // Show Word icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={docsIcon} alt="doc" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isExcel ? (
                            // Show Excel icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={xlsxIcon} alt="xlsx" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isPowerPoint ? (
                            // Show PowerPoint icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={pptxIcon} alt="pptx" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isText ? (
                            // Show Text file icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={txtfileIcon} alt="txtfileIcon" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : isZip ? (
                            // Show ZIP file icon with styled link
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                padding: 2,
                                border: "1px solid",
                                borderColor: "grey.300",
                                borderRadius: 1,
                                boxShadow: 1,
                              }}
                            >
                              <img src={attechmentIcon} alt="attechmentIcon" />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          ) : (
                            // Fallback for other file types
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AttachFileIcon
                                sx={{ fontSize: 16, marginRight: 1 }}
                              />
                              <Link
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{ wordBreak: "break-all" }}
                              >
                                <Chip
                                  icon={<ArrowCircleDownIcon />}
                                  label="Download"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Link>
                            </Box>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography>No application details found.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationDetailsDialog;
