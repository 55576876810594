import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";

const LikeList = ({ open, handleClose, post, postId }) => {
  const [likeList, setLikeList] = useState([]);
  const [totalLikeList, setTotalLikeList] = useState(0);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [reactionPages, setReactionPages] = useState({}); // State to store page numbers for each reaction type

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const [isLast, setIsLast] = useState(false);

  const likeArray = [
    {
      type: "All",
      emoji: "All",
    },
    {
      type: "Like",
      emoji: "👍",
    },
    {
      type: "Celebrate",
      emoji: "👏",
    },
    {
      type: "Funny",
      emoji: "😄",
    },
    {
      type: "Love",
      emoji: "❤️",
    },
  ];

  const filteredLikeArray = likeArray.filter((item) =>
    Object.keys(post?.reactionCount).includes(item.type)
  );

  const getLikeList = async (reactionType = null, pageNumber = 0) => {
    try {
      const response = await axios.get(
        `/v1/posts/like/list?postId=${postId}&pageSize=5&pageNumber=${pageNumber}&reactionType=${reactionType || ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: ConvertToRequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setLikeList((prevList) => {
        if (pageNumber === 0) {
          return response?.data?.data?.content;
        } else {
          return [...prevList, ...response?.data?.data?.content];
        }
      });

      setTotalLikeList(response?.data?.data?.totalElements);
      setReactionPages((prevPages) => ({
        ...prevPages,
        [reactionType || "All"]: pageNumber,
      }));
      setIsLast(response?.data?.data?.last);

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (open) {
      getLikeList();
    }
  }, [open]);

  useEffect(() => {
    const totalAllReactions = filteredLikeArray.reduce((total, reaction) => {
      return total + (post?.reactionCount[reaction.type] || 0);
    }, 0);

    setTotalLikeList(totalAllReactions);
  }, [filteredLikeArray, post]);

  const handleReactionSelect = (reactionType) => {
    setSelectedReaction(reactionType);
    getLikeList(reactionType === "All" ? null : reactionType, 0);
  };

  const handleLoadMore = () => {
    const currentPage = reactionPages[selectedReaction || "All"] || 0;
    const nextPage = currentPage + 1;
    getLikeList(selectedReaction === "All" ? null : selectedReaction, nextPage);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: "100%",
            height: "400px",
            backgroundColor: "#ffffff",
            padding: 0,
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ml: 2,
              }}
            >
              <Typography sx={{ fontWeight: 500 }} variant="h3">
                Reactions
              </Typography>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Grid>

            <Tabs
              value={selectedReaction}
              onChange={(_, value) => handleReactionSelect(value)}
              sx={{
                "& .MuiTabs-flexContainer": {
                  border: "none",
                },
                "& a": {
                  minHeight: "auto",
                  minWidth: 10,
                  color: "grey.700",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Tab label={`All (${totalLikeList})`} value="All" />
              {filteredLikeArray?.map((reaction, index) => (
                <Tab
                  key={index}
                  label={`${reaction.emoji} (${post?.reactionCount[reaction.type] || 0
                    })`}
                  value={reaction.type}
                />
              ))}
            </Tabs>

            {likeList?.map((data) => (
              <Grid item md={12} xs={12} p={2} key={data?.alumniMemberMini?.name}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Avatar
                    alt={data?.alumniMemberMini?.name}
                    src={`data:image/*;base64, ${data?.alumniMemberMini?.avatar?.data}`}
                  />
                  <div style={{ marginLeft: "5px" }}>
                    <p style={{ fontWeight: "bold", margin: "0" }}>
                      {data?.alumniMemberMini?.name ?? data?.likedByName}
                    </p>
                    <p style={{ margin: "0" }}>
                      {data?.alumniMemberMini?.email}
                    </p>
                  </div>
                </div>
              </Grid>
            ))}

            {!isLast && (
              <>
                {likeList.length > 4 && (
                  <Grid item md={12} xs={12} p={2}>
                    <Button onClick={handleLoadMore}>Load More</Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LikeList;
