import { Avatar, AvatarGroup, Chip, Grid, Typography } from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import StickyHeaderTable from "reuseableTable";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import NoAvailableData from "reuseableTable/NoAvailableData";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import MainCard from "ui-component/cards/MainCard";
import GroupMemberDetails from "./GroupMemberDetails";

const Groups = () => {
  const [pageNumberForGroup, setPageNumberForGroup] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayedGroupList, setDisplayedGroupList] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [instituteDetails, setInstituteDetails] = useState([]);

  const [groupId, setGroupId] = useState("");
  const [groupMemberImages, setGroupMemberImages] = useState([]);
  const [openMemberList, setOpenListMember] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(11);
  const [totalCount, setTotalCount] = useState(0);

  const pageSize = 4;
  const getRole = localStorage.getItem("role");

  const handleOpnMemberList = (id) => {
    setGroupId(id);
    setOpenListMember(true);
  };
  const handleCloseMemberList = () => {
    setOpenListMember(false);
  };

  const ReuseAbleApiCall = async (endpoint, processResponse) => {
    let url = `/v1/${endpoint}`;
    const instituteId =
      getRole === "ROLE_SUPER_ADMIN" ? localStorage.getItem("instituteId") : "";

    if (instituteId) {
      url += `?instituteId=${instituteId}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      processResponse(response);
      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.log(err);
    }
  };

  const getInstituteData = () => {
    ReuseAbleApiCall("institute/get", (response) => {
      setInstituteDetails(response?.data?.data);
    });
  };


  const columns = [
    {
      id: "name",
      label: "Name",
      width: 100,
      align: "left",
      render: (row) => (
        <div style={{ marginLeft: "5px", width: "130px" }}>
          <p
            style={{
              fontWeight: "bold",
              margin: "0",
            }}
          >
            {row?.name}
          </p>
        </div>
      ),
    },
    {
      id: "createdByName",
      label: "Created By",
      minWidth: 100,
      align: "left",
      render: (row) => (
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ marginLeft: "5px" }}>
            <p
              style={{
                fontWeight: "bold",
                margin: "0",
              }}
            >
              {row?.createdByName}
            </p>
            <p style={{ margin: "0" }}>
              {moment(row?.createdDate).format("MMM Do YY")}
            </p>
          </div>
        </div>
      ),
    },
    {
      id: "noOfMember",
      label: "No Of Member",
      minWidth: 100,
      align: "center",
      render: (row) => <p>{row?.noOfMember}</p>,
    },
    {
      id: "groupMemberImages",
      label: "Group Members",
      minWidth: 100,
      align: "left",
      render: (row) => (
        <Grid container spacing={1}>
          <AvatarGroup max={3}>
            {groupMemberImages[row?.id]?.map((image, idx) => (
              <Avatar
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpnMemberList(row?.id);
                }}
                key={idx}
                alt={image ? row?.name : "No Photo Available"}
                src={image ? `data:image/*;base64, ${image}` : ""}
              />
            ))}
          </AvatarGroup>
        </Grid>
      ),
    },
  ];

  const insertIndex = 4;

  if (instituteDetails?.batch) {
    columns.splice(insertIndex, 0, {
      id: "Batch",
      label: "Batch",
      minWidth: 130,
      align: "left",
      render: (row) => <p>{row?.batch?.tag ?? "---"}</p>,
    });
  }

  if (instituteDetails?.program) {
    columns.splice(insertIndex + 1, 0, {
      id: "Program",
      label: "Program",
      minWidth: 130,
      align: "left",
      render: (row) => <p>{row?.programs?.name ?? "---"}</p>,
    });
  }

  // if (instituteDetails?.branchEnabled) {
  //   columns.splice(insertIndex + 2, 0, {
  //     id: "Branch",
  //     label: "Branch",
  //     minWidth: 130,
  //     align: "left",
  //     render: (row) => <p>{row?.institutionsBranch?.name ?? "---"}</p>,
  //   });
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getGroupList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/v1/communication/group/my/list?pageSize=${rowsPerPage}&pageNumber=${page}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: generateTimeStamp(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      const newGroupList = response?.data?.data?.content || [];

      newGroupList?.length > 0 && (
        <>
          {newGroupList?.forEach((group) => {
            fetchGroupMembers(group?.id);
          })}
        </>
      );

      const updatedGroupList =
        pageNumberForGroup === 0
          ? newGroupList
          : [...displayedGroupList, ...newGroupList];
      setDisplayedGroupList(updatedGroupList);
      setPageCache((prev) => ({
        ...prev,
        [page]: updatedGroupList,
      }));
      setTotalCount(response?.data?.data?.totalElements);
      setShowMore(newGroupList.length >= pageSize);
      setLoading(false);
      generateTimeStamp();
    } catch (error) {
      setLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchGroupMembers = async (groupId) => {
    try {
      const response = await axios.get(
        `/v1/communication/group/list/group/member?groupId=${groupId}&pageNumber=0&pageSize=200`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );

      const groupMembers = response?.data?.data?.content || [];
      const alumniAvatars = groupMembers.map((member) =>
        member.alumniMemberMini.avatar === null
          ? ""
          : member.alumniMemberMini.avatar.data
      );
      setGroupMemberImages((prevImages) => ({
        ...prevImages,
        [groupId]: alumniAvatars,
      }));

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setDisplayedGroupList(pageCache[page]);
    } else {
      getGroupList();
    }
  }, [page, rowsPerPage, pageCache, pageNumberForGroup]);

  useEffect(() => {
    getInstituteData();
  }, []);

  return (
    <MainCard>
      {loading ? (
        <AnimatedLoader />
      ) : (
        <>
          {displayedGroupList?.length > 0 ? (
            <>
              <StickyHeaderTable
                data={displayedGroupList}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                columns={columns}
              />
            </>
          ) : (
            <NoAvailableData
              text={
                "At this time, data isn't available."
              }
            />
          )}
        </>
      )}

      {openMemberList && (
        <GroupMemberDetails
          groupDetailId={groupId}
          open={openMemberList}
          onClose={handleCloseMemberList}
        />
      )}
    </MainCard>
  );
};

export default Groups;
