import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication1/Login1"))
);
// const AuthLogin = Loadable(
//   lazy(() => import("views/pages/authentication/authentication2/NHPSLogin"))
// );
// const AuthLogin = Loadable(
//   lazy(() => import("views/pages/authentication/authentication2/SOILogin"))
// );
// const AuthLogin = Loadable(
//   lazy(() => import("views/pages/authentication/authentication2/USTMLogin"))
// );
const AuthRegister = Loadable(lazy(() => import('views/application/register/RegistrationForm')));
// const AuthRegister = Loadable(
//   lazy(() => import("views/pages/authentication/authentication1/Register1"))
// );
const RegistrationByAlumni = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication1/RegistrationByAlumni")
  )
);
// const RegistrationByAlumni = Loadable(
//   lazy(() =>
//     import("views/pages/authentication/authentication1/RegistrationByAlumniSOI")
//   )
// );
const RegistrationByInvitationLink = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication1/RegistrationByInvitationLink")
  )
);
// const RegistrationByInvitationLink = Loadable(
//   lazy(() =>
//     import("views/pages/authentication/authentication1/RegistrationInviteLinkSOI")
//   )
// );
// const RegistrationByAlumni = Loadable(
//   lazy(() =>
//     import("views/pages/authentication/authentication1/RegistrationByAlumniUSTM")
//   )
// );
const AuthForgotPassword = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication1/ForgotPassword1")
  )
);
const Otp = Loadable(lazy(() => import("views/application/register/OtpforEmail")));
const OTPVerify = Loadable(lazy(() => import("views/application/register/OTPverifyForgetPassword")));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/alumni/register/:id",
      element: <RegistrationByAlumni />,
    },
    {
      path: "/invite/:alumniId/:instituteId",
      element: <RegistrationByInvitationLink />,
    },
    {
      path: "/forgot",
      element: <AuthForgotPassword />,
    },
    {
      path: "/otp/page/:userName",
      element: <Otp />,
    },
    {
      path: "/verfiy/otp/:userName/:code",
      element: <OTPVerify />,
    },
  ],
};

export default LoginRoutes;
