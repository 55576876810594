import React, { useEffect, useRef } from "react";

const EventLocation = ({ onPlaceSelected, value }) => {
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const handleScriptLoad = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Specify types of predictions
          componentRestrictions: { country: "in" }, // Restrict to a specific country, e.g., India
        }
      );

      autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
        "formatted_address",
      ]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          onPlaceSelected(place.formatted_address);
        }
      });

      autocompleteRef.current = autocomplete;
    };

    if (window.google) {
      handleScriptLoad();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6rcaCBBusVrrXNHhbT2XEGsjQv1rBRok&libraries=places`;
      script.async = true;
      script.defer = true;
      script.addEventListener("load", handleScriptLoad);
      document.body.appendChild(script);
    }

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, [onPlaceSelected]);

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      placeholder="Enter a location"
      style={{ width: "100%", padding: "10px" }}
    />
  );
};

export default EventLocation;
