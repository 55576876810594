/* eslint-disable array-callback-return */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

const PercentageChart = ({ studentId, setChartData }) => {
    const [percente, setPercente] = useState([]);
    const [semester, setSemester] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const getAcademicResult = () => {
        axios
            .get(`${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/placements/academic-student-result?studentId=${studentId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('external-token')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                setChartData(result.data.data);
                const percentageArray = [];
                const semesterArray = [];
                result.data.data.map((item) => {
                    percentageArray.push(item.percentage);
                    semesterArray.push(`${item.semester} Sem`);
                });
                setPercente(percentageArray);
                setSemester(semesterArray);
                setLoading(false);
            })
            .catch((error) => {
                setError('Failed to fetch data');
                setLoading(false);
            });
    };

    // const handleBarClick = (event, chartContext, config) => {
    //     const companyIndex = config.dataPointIndex;
    //     console.log(config, 'config');
    //     const companyNames = semester[companyIndex];
    //     console.log(companyNames, 'clickedDesignation');
    //     navigate(`/company/wise/candidates/report/${companyNames}`);
    // };

    useEffect(() => {
        getAcademicResult();
    }, []);

    // Update chart data whenever studentInfo or courseInfo changes
    const chartData = {
        series: [
            {
                name: '',
                data: percente
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false // Disable the context menu toolbar
                }
                // events: {
                //     dataPointSelection: handleBarClick // Attach the event handler
                // }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    // horizontal: true
                    vertical: true
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: semester
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => `Percent: ${value}`
                }
            },
            colors: ['#AC63D2'] // Example color change
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div id="chart">
                {semester.length > 0 && percente.length > 0 && (
                    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
                )}
            </div>
        </div>
    );
};

export default PercentageChart;
