import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  CardActions,
  Divider,
  Grid,
  List,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Card,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import moment from "moment";
import { ClearOutlined } from "@mui/icons-material";
import SubCard from "ui-component/cards/SubCard";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ToastContainer, toast } from "react-toastify";

const ProfileNotification = () => {
  const theme = useTheme();

  const [notificationList, setNotificationList] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalNotifications, setTotalNotifications] = useState(0);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getNotificationList = async (pageNumber, pageSize) => {
    try {
      const response = await axios.get(
        `/v1/notification/list?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      if (pageNumber === 0) {
        setNotificationList(response?.data?.data?.content);
      } else {
        setNotificationList((prevList) => [
          ...prevList,
          ...response?.data?.data?.content,
        ]);
      }
      setTotalNotifications(response?.data?.data?.totalElements);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleClearNotification = async (id) => {
    const payload = {
      notificationIds: [id],
      clearAll: false,
    };
    try {
      const response = await axios.post(
        `/v1/notification/clear/notification`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setNotificationList((prevList) =>
        prevList.filter((item) => item.id !== id)
      );
      generateTimeStamp();

      // Show success toast notification
      toast.success(response?.data?.Msg, {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success",
        theme: "dark",
        progress: undefined,
      });
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to clear notification:", error);
    }
  };

  const handleClearAllNotifications = async () => {
    const payload = {
      notificationIds: [],
      clearAll: true,
    };
    try {
      await axios.post(`/v1/notification/clear/notification`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setNotificationList([]);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to clear all notifications:", error);
    }
  };

  const handleViewMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleViewLess = () => {
    setPageNumber(0);
    setNotificationList([]);
  };

  const senderColorMap = {};
  const getColorForSender = (senderName) => {
    if (!senderColorMap[senderName]) {
      senderColorMap[senderName] = getRandomColor();
    }
    return senderColorMap[senderName];
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    getNotificationList(pageNumber, pageSize);
  }, [pageNumber, pageSize]);

  return (
    <>
      <SubCard
        sx={{
          p: 1,
          height: "400px", // Adjusted height
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ffffff",
          },
        }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#245081", fontSize: "18px" }}>
                Notifications{" "}
                {totalNotifications > 0 && (
                  <Chip
                    sx={{ background: "#ede7f6" }}
                    label={totalNotifications}
                  />
                )}
              </span>
            </Typography>
          </div>
        }
        secondary={
          <>
            {notificationList.length > 0 && (
              <Tooltip title="Clear All Notifications">
                <IconButton onClick={handleClearAllNotifications}>
                  <ClearAllIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <Grid container spacing={2} item xs={12} md={10} lg={12}>
          <List
            sx={{
              width: "100%",
              py: 0,
              borderRadius: "10px",
              "& .MuiListItemSecondaryAction-root": {
                top: 22,
              },
              "& .MuiDivider-root": {
                my: 0,
              },
              "& .list-container": {
                pl: 7,
              },
            }}
          >
            {notificationList && notificationList.length > 0 ? (
              notificationList.map((data) => (
                <Card
                  key={data.id}
                  sx={{
                    border: "1px solid #E9EFE9",
                    borderRadius: "10px",
                    p: 0.5,
                    mt: 0.8,
                  }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Avatar
                        alt={data.fromUserName}
                        src={`data:image/*;base64, ${data?.fromUserprofile?.data}`}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body2">
                        <strong>
                          <span
                            style={{
                              color: getColorForSender(data.fromUserName),
                            }}
                          >
                            {data?.fromUserName}
                          </span>{" "}
                          {data?.notificationFor === "Group"
                            ? `sent a message in ${data?.groupName}`
                            : "sent you a message"}
                        </strong>{" "}
                        {truncateText(data.title, 20)}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {moment(data.date).fromNow()}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleClearNotification(data?.id)}
                      >
                        <ClearOutlined sx={{ fontSize: "12px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: 2,
                }}
              >
                <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/023/570/826/small/still-empty-no-notification-yet-concept-illustration-line-icon-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-vector.jpg"
                  alt="No Notification"
                  style={{ maxWidth: "100%", marginBottom: 16 }}
                />
                <Typography variant="h4" align="center" gutterBottom>
                  No Notification
                </Typography>
                <Divider />
                <Typography variant="body2" align="center">
                  All Clear! We'll notify you when there is something new.
                </Typography>
              </Grid>
            )}
          </List>

          {notificationList.length < totalNotifications && (
            <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
              <IconButton
                size="small"
                disableElevation
                onClick={handleViewMore}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </CardActions>
          )}
        </Grid>
      </SubCard>

      <ToastContainer />
    </>
  );
};

export default ProfileNotification;
