import React from "react";
import StickyHeaderTable from "reuseableTable";
import NoAvailableData from "reuseableTable/NoAvailableData";

const PersonList = ({
  data,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  columns,
  hanldeFilter,
}) => {
  return (
    <>
      {data?.length > 0 ? (
        <StickyHeaderTable
          data={data}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
        />
      ) : (
        <NoAvailableData text="No Data Found!" />
      )}
    </>
  );
};

export default PersonList;
