import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useTheme } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import gfm from "remark-gfm";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import AnimatedLoader from "reuseableTable/AnimatedLoader";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import moment from "moment";
import ViewUploadedImages from "views/application/users/social-profile/ViewUploadedImages";
import LikeList from "views/application/users/social-profile/LikeList";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import CommentList from "views/application/users/social-profile/CommentList";
import { Controller, FormProvider, useForm } from "react-hook-form";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SendOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { Box } from "@mui/system";
import VerifiedIcon from "@mui/icons-material/Verified";

const likeArray = [
  {
    type: "Like",
    emoji: "👍",
  },
  {
    type: "Celebrate",
    emoji: "👏",
  },
  {
    type: "Funny",
    emoji: "😄",
  },
  {
    type: "Love",
    emoji: "❤️",
  },
];

const FormInput = ({
  bug,
  label,
  size,
  fullWidth = true,
  name,
  required,
  ...others
}) => {
  let isError = false;
  let errorMessage = "";
  if (bug && Object.prototype.hasOwnProperty.call(bug, name)) {
    isError = true;
    errorMessage = bug[name].message;
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue=""
        render={({ field }) => (
          <TextField
            fullWidth={fullWidth}
            size={size}
            label={label}
            InputLabelProps={{
              className: required ? "required-label" : "",
              required: required || false,
            }}
            error={isError}
            {...field}
          />
        )}
        {...others}
      />
      {errorMessage && (
        <Grid item xs={12}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      )}
    </>
  );
};
const PostCard = ({
  post,
  setPosts,
  alumniDetails,
  isLoading,
  getPostDetails,
}) => {
  const theme = useTheme();

  const [openLikeList, setOpenLikeList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [commentList, setCommentList] = useState([]);
  const [openComment, setOpenComment] = useState(false);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

  const [jumpingEmoji, setJumpingEmoji] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = () => {
    handleDeletePost(reason); // Pass the reason to the delete handler
    setReason(""); // Reset reason after deletion
    handleClose();
  };
  const handleDeletePost = async (reason) => {
    console.log("Delete button clicked for", reason, post.id);

    const response = await axios.delete(
      `/v1/posts/revoke-from-admin?postId=${post.id}`,
      {
        data: { reason }, // Use the `data` property to include the request body
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      }
    );
    getPostDetails()
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Deleted Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: response?.data?.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  const handleEmojiClick = (emojiType) => {
    setJumpingEmoji(emojiType);
    setTimeout(() => {
      setJumpingEmoji(null);
    }, 700);
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenLikeList = () => {
    setOpenLikeList(true);
  };

  const handleCloseLikeList = () => {
    setOpenLikeList(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeComment = () => {
    setOpenComment((prev) => !prev);
    if (!openComment) {
      getCommentList(post?.id);
    }
  };
  const handlePostLikes = async (id, type, like) => {
    handleEmojiClick(type);
    try {
      const response = await axios.get(
        `/v1/posts/like?like=${like}&postId=${id}&reactionType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setPosts(response?.data?.data);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const getCommentList = async (id) => {
    try {
      const response = await axios.get(
        `/v1/posts/list/comment?postId=${id}&pageSize=200&pageNumber=0`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setCommentList(response?.data?.data?.content);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  let commentsResult = <></>;

  if (commentList) {
    commentsResult = commentList?.map((comment) => (
      <CommentList
        setPosts={setPosts}
        postId={post?.id}
        comment={comment}
        key={comment.id}
        openComment={openComment}
        getCommentList={getCommentList}
        type="FromAdmin"
      />
    ));
  }
  const methods = useForm({
    // resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const onSubmit = async (comment) => {
    // handleChangeComment();
    const payload = {
      comment: comment?.name,
      commentId: "",
    };
    try {
      const response = await axios.post(
        `/v1/posts/add/comment?postId=${
          post?.id
        }&alumniId=${localStorage.getItem("alumniId")}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setPosts(response?.data?.data);
      getCommentList(post?.id);
      reset();
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <MainCard sx={{ mt: 1 }}>
      {isLoading ? (
        <>
          <AnimatedLoader />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                {/* Avatar Section */}
                <Grid item>
                  <Avatar
                    alt={post?.alumniMemberMini?.name}
                    src={`data:image/*;base64, ${post?.alumniMemberMini?.avatar?.data}`}
                    sx={{
                      border: post?.alumniMemberMini?.starAlumni
                        ? "3px solid #D89E46"
                        : "none",
                    }}
                  />
                </Grid>

                {/* Details Section */}
                <Grid item xs zeroMinWidth>
                  <Typography
                    align="left"
                    variant="h6"
                    component="div"
                    sx={{
                      display: "inline-flex", // Align name and icon on the same line
                      alignItems: "center", // Vertically align the icon with the text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {post?.alumniMemberMini
                      ? post?.alumniMemberMini?.name
                      : post?.createdByName}
                    {post?.alumniMemberMini?.starAlumni && (
                      <VerifiedIcon
                        sx={{
                          color: "#D89E46",
                          fontSize: "18px",
                          marginLeft: "4px",
                        }}
                      />
                    )}
                  </Typography>
                  <br />
                  <Typography
                    align="left"
                    variant="caption"
                    color="textSecondary"
                  >
                    Batch {post?.alumniMemberMini?.batchTag} |{" "}
                    {post?.alumniMemberMini?.department}
                  </Typography>
                  <Typography
                    align="left"
                    variant="caption"
                    color="textSecondary"
                  >
                    {moment(post.createdAt).format("DD MMM YYYY")}
                  </Typography>
                </Grid>

                {/* Delete Icon Section */}
                <Grid item>
                  {!post?.revokeInfo && (
                    <Tooltip title="delete post">
                      <IconButton onClick={handleOpen}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {post?.revokeInfo ? (
              <>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        bgcolor: "#FFF5F5",
                        color: "#D32F2F",
                        padding: 2,
                        borderRadius: "12px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #F8D7DA",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="error"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        <WarningAmberIcon /> This post was deleted by{" "}
                        <strong>{post.revokeInfo.revokeBy}</strong> on{" "}
                        {moment(post.revokeInfo.revokeDate).format(
                          "DD MMM YYYY"
                        )}
                        .
                      </Typography>

                      <Typography variant="body1" sx={{ mb: 0.5 }}>
                        <strong>Reason:</strong>{" "}
                        {post?.revokeInfo?.revokeReason}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid>
                  <Grid
                    item
                    xs={12}
                    p={1}
                    sx={{
                      "& > p": {
                        ...theme.typography.body1,
                        mb: 0,
                      },
                    }}
                  >
                    <ReactMarkdown remarkPlugins={[gfm]}>
                      {post.postText}
                    </ReactMarkdown>
                  </Grid>

                  {post.images && post.images.length > 0 && (
                    <Grid item xs={12}>
                      <ViewUploadedImages
                        postId={post?.id}
                        itemData={post?.images}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      sx={{
                        mt: 0,
                        color:
                          theme.palette.mode === "dark"
                            ? "grey.700"
                            : "grey.800",
                      }}
                    >
                      <Grid item>
                        {post?.reactionCount && (
                          <div
                            style={{
                              width: "100px",
                              display: "flex",
                            }}
                          >
                            {Object.entries(post?.reactionCount).map(
                              ([key, value]) => {
                                const emojiItem = likeArray.find(
                                  (item) => item.type === key
                                );
                                return (
                                  <div
                                    key={key}
                                    className={
                                      jumpingEmoji === emojiItem?.type
                                        ? "jumping"
                                        : ""
                                    }
                                    onClick={handleOpenLikeList}
                                    onMouseEnter={(e) => {
                                      e.target.style.borderBottom =
                                        "1px solid black";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.borderBottom =
                                        "1px solid transparent";
                                    }}
                                  >
                                    {emojiItem?.emoji}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ mt: 2 }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePostLikes(
                                  post?.id,
                                  "Like",
                                  !post?.postLikesIds?.includes(
                                    localStorage.getItem("userId")
                                  )
                                    ? true
                                    : false
                                )
                              }
                              color="inherit"
                              size="small"
                              onMouseEnter={handlePopoverOpen}
                            >
                              <ThumbUpAltTwoToneIcon
                                color={
                                  post?.postLikesIds?.includes(
                                    localStorage.getItem("userId")
                                  )
                                    ? "primary"
                                    : "inherit"
                                }
                              />
                              {post?.likes}
                              <Typography
                                color="inherit"
                                sx={{
                                  fontWeight: 500,
                                  ml: 0.5,
                                  display: { xs: "none", sm: "block" },
                                }}
                              >
                                likes
                              </Typography>
                            </Button>
                            {!post?.postLikesIds?.includes(
                              localStorage.getItem("userId")
                            ) && (
                              <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onMouseLeave={handlePopoverClose}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                disableRestoreFocus
                              >
                                <Stack direction="row" spacing={1} p={1}>
                                  {likeArray?.map((like, index) => (
                                    <Typography
                                      key={index}
                                      sx={{
                                        cursor: "pointer",
                                        fontWeight: isHovered ? 500 : 400,
                                      }}
                                      variant={isHovered ? "h1" : "h3"}
                                      onMouseEnter={handleMouseEnter}
                                      onMouseLeave={handleMouseLeave}
                                      onClick={() =>
                                        handlePostLikes(
                                          post?.id,
                                          like?.type,
                                          !post?.postLikesIds?.includes(
                                            localStorage.getItem("userId")
                                          )
                                            ? true
                                            : false
                                        )
                                      }
                                    >
                                      {like.emoji}
                                    </Typography>
                                  ))}
                                </Stack>
                              </Popover>
                            )}
                            <Button
                              onClick={handleChangeComment}
                              size="small"
                              variant="text"
                              color="inherit"
                              startIcon={
                                <ChatBubbleTwoToneIcon color="secondary" />
                              }
                            >
                              {post?.comments} comments
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>

                      {openLikeList && (
                        <LikeList
                          post={post}
                          postId={post?.id}
                          open={openLikeList}
                          handleClose={handleCloseLikeList}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Collapse in={openComment} sx={{ width: "100%" }}>
                    {openComment && (
                      <Grid item xs={12} sx={{ pt: 2 }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Grid container spacing={2} alignItems="flex-start">
                            <Grid
                              item
                              sx={{ display: { xs: "none", sm: "block" } }}
                            >
                              <Avatar
                                sx={{ mt: 0.75 }}
                                alt="User 1"
                                src={`data:image/*;base64, ${alumniDetails?.avatar?.data}`}
                                size="xs"
                              />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                              <FormProvider {...methods}>
                                <FormInput
                                  fullWidth
                                  name="name"
                                  label="Write a comment..."
                                  size={matchesXS ? "small" : "medium"}
                                  bug={errors}
                                />
                              </FormProvider>
                            </Grid>
                            <Grid item>
                              {/* <AnimateButton> */}
                              <IconButton
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size={matchesXS ? "small" : "large"}
                                sx={{ mt: 0.5 }}
                              >
                                <SendOutlined />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    )}
                  </Collapse>
                  {commentsResult}
                </Grid>
              </>
            )}
          </Grid>
          {post?.aiChecked && (
            <Grid display="flex" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" flex="end" spacing={1}>
                <AutoAwesomeIcon sx={{ color: "#4d8ffd" }} />
                <Typography variant="caption">Verified by AI</Typography>
              </Stack>
            </Grid>
          )}
        </>
      )}
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this post?
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
            <Box
              sx={{
                bgcolor: "#fff4e5",
                color: "#8c6938",
                padding: 1.5,
                borderRadius: "8px",
              }}
            >
              <WarningAmberIcon sx={{ color: "#ef7918" }} /> This action cannot
              be undone. Please provide a reason for deleting the post.
            </Box>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason for Deletion"
            type="text"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!reason}
            onClick={handleConfirmDelete}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default PostCard;
