import React, { useEffect, useState, useRef } from "react";
import { useTheme, styled } from "@mui/material/styles";
import {
  AvatarGroup,
  Box,
  Button,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import UserDetails from "./UserDetails";
import ChatDrawer from "./ChatDrawer";
import ChartHistory from "./ChartHistory";
import MainCard from "ui-component/cards/MainCard";
import Avatar from "ui-component/extended/Avatar";
import { appDrawerWidth as drawerWidth, gridSpacing } from "store/constant";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import VideoCallTwoToneIcon from "@mui/icons-material/VideoCallTwoTone";
import CallTwoToneIcon from "@mui/icons-material/CallTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import MoodTwoToneIcon from "@mui/icons-material/MoodTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import NavigateBackButton from "reuseableTable/NavigateBackButton";

// drawer content element
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: open ? theme.spacing(3) : 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      marginLeft: 0,
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
    }),
  })
);

// ==============================|| APPLICATION CHAT ||============================== //

const AlumniChatMainPage = ({ alumniDetailsInfo }) => {
  const theme = useTheme();
  const scrollRef = useRef();
  const scrollContainerRef = useRef();

  const [isFetching, setIsFetching] = useState(false);
  const [emailDetails, setEmailDetails] = React.useState(false);
  const [openChatDrawer, setOpenChatDrawer] = React.useState(true);
  const [isFromSearchBar, setIsFromSearchBar] = React.useState(false);
  const [user, setUser] = useState({});
  const [connectionList, setConnectionList] = useState([]);
  const [data, setData] = React.useState([]);
  const [roomId, setRoomId] = React.useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [roomMessages, setRoomMessages] = useState({});
  const [message, setMessage] = useState("");
  const [currentRoomId, setCurrentRoomId] = useState(roomId);

  const [client, setClient] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [groupMemberImages, setGroupMemberImages] = useState([]);

  const [anchorElEmoji, setAnchorElEmoji] = React.useState();
  const [isCooldown, setIsCooldown] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [getlastdata, setLastdata] = useState(false);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleDrawerOpen = () => {
    setOpenChatDrawer((prevState) => !prevState);
  };
  const handleUserChange = () => {
    setEmailDetails((prev) => !prev);
  };
  const maxLength = 200;

  const handleMessageChange = (e) => {
    const inputText = e.target.value;
    setMessage(inputText);
    setRoomMessages((prevMessages) => ({
      ...prevMessages,
      [currentRoomId]: inputText,
    }));
  };

  const handleRoomChange = (newRoomId) => {
    setRoomMessages((prevMessages) => ({
      ...prevMessages,
      [currentRoomId]: message,
    }));
    const newMessage = roomMessages[newRoomId] || "";
    setMessage(newMessage);
    setCurrentRoomId(newRoomId);
  };

  const generateUniqueId = async (id1, id2) => {
    if (id2 !== undefined) {
      const idStr1 = id1.toString();
      const idStr2 = id2.toString();
      const ids = [idStr1, idStr2];
      ids.sort();
      const combined = ids[0] + ids[1];
      const encoder = new TextEncoder();
      const data = encoder.encode(combined);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      const base64Hash = btoa(
        String.fromCharCode(...new Uint8Array(hashBuffer))
      );
      setRoomId(base64Hash.replace("/", "a"));
      return base64Hash;
    }
  };


  const getMessage = async (roomIde) => {
    //  if (getlastdata) return;
    try {
      const response = await axios.get(
        `/v1/chat/message/list/page?roomId=${encodeURIComponent(
          roomIde
        )}&pageNumber=${pageNumber}&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setData((prevPosts) => [...response?.data?.data?.content, ...prevPosts]);
      setLastdata(response?.data?.data?.last);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
    }
  };

  const getConnectionList = async () => {
    try {
      const response = await axios.get(`/v1/connection/my`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      const connections = response?.data?.data;
      setConnectionList(connections);

      // Find the matching connection
      const matchingConnection = connections?.find(
        (connection) => connection.connectionId === alumniDetailsInfo.userId
      );

      if (matchingConnection) {
        setIsFromSearchBar(true);
        setUser((prevUser) => {
          if (prevUser?.connectionId !== matchingConnection.connectionId) {
            return matchingConnection;
          }
          return prevUser;
        });
        // setRoomId(matchingConnection?.connectionId)
        generateUniqueId(
          localStorage.getItem("userId"),
          matchingConnection?.connectionId
        );
        handleRoomChange(user?.connectionId);
        setOpenChatDrawer(false);
      }

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };
  const clearNotification = async (connectionType, connectionId) => {
    if (connectionId === undefined) return;
    try {
      const response = await axios.put(
        `/v1/chat/clear/count?connectionId=${connectionId}&connectionType=${connectionType}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      getConnectionList();
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchGroupMembers = async (groupId) => {
    try {
      const response = await axios.get(
        `/v1/communication/group/list/group/member?groupId=${groupId}&pageNumber=0&pageSize=200`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );
      setGroupMembers(response?.data?.data?.content);
      const groupMembers = response?.data?.data?.content || [];
      const alumniAvatars = groupMembers.map((member) =>
        member.alumniMemberMini.avatar === null
          ? ""
          : member.alumniMemberMini.avatar.data
      );
      setGroupMemberImages((prevImages) => ({
        ...prevImages,
        [groupId]: alumniAvatars,
      }));

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleOnSend = async () => {
    if (isCooldown || message.trim().length === 0) return;

    const d = new Date();
    setMessage("");
    const newMessage = {
      id: "",
      senderId: localStorage.getItem("userId"),
      receiverId: user?.connectionId,
      roomType: user?.connectionType,
      roomId: roomId,
      content: message,
      chatContent: "Text",
      from: "User1",
      to: user.name,
      text: message,
      time: d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    };

    try {
      await axios.post(`/v1/chat/send`, newMessage, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      generateTimeStamp();

      const startTime = Date.now();
      localStorage.setItem("cooldownStartTime", startTime.toString());

      setIsCooldown(true);

      setTimeout(() => {
        setIsCooldown(false);
        localStorage.removeItem("cooldownStartTime");
      }, 10000);
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };
  const handleEnter = (event) => {
    if (event?.key !== "Enter") {
      return;
    }
    handleOnSend();
  };
  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleOnEmojiButtonClick = (event) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
  };
  const emojiOpen = Boolean(anchorElEmoji);
  const emojiId = emojiOpen ? "simple-popper" : undefined;
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };
  useEffect(() => {
    const sockClient = new Client({
      brokerURL: "ws://https://api.entraralumni.com/ws",
      webSocketFactory: () => new SockJS("https://api.entraralumni.com/ws"),
      debug: function (str) { },
      connectHeaders: {
        Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });
    sockClient.activate();
    setClient(sockClient);
    return () => {
      sockClient.deactivate();
    };
  }, []);

  console.log(subscriptions, "subscriptions");
  useEffect(() => {
    const topic = `/topic/public/${roomId}`;
    if (client && client.connected && roomId) {
      if (!subscriptions.includes(roomId)) {
        console.log("coming in this function");
        client.subscribe(`/topic/public/${roomId}`, (message) => {
          const messageSound = new Audio(
            "/assets/images/icons/mixkit-bell-notification-933.wav"
          );
          console.log("cominginsideee");
          const chatMessage = JSON.parse(message.body);
          if (data.filter((item) => item.id !== chatMessage.id)) {
            console.log(chatMessage, "chatmsg");
            setData((prevState) => [...prevState, chatMessage]);
          }
        });
        setSubscriptions((prevSubscriptions) => [...prevSubscriptions, roomId]);
      }
    }
    if (roomId) {
      setData([]);
      setPageNumber(0);
      setLastdata(false);
      getMessage(roomId);
    }
  }, [roomId, client]);

  useEffect(() => {
    if (user?.connectionType === "Group") {
      fetchGroupMembers(roomId);
    }
  }, [roomId, client]);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [data]);
  // ref = { scrollContainerRef }

  useEffect(() => {
    if (pageNumber > 0) {
      getMessage(roomId);
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(0);
  }, [getlastdata, roomId]);

  useEffect(() => {
    getConnectionList();
  }, [alumniDetailsInfo?.userId]); // Add only necessary dependencies

  useEffect(() => {
    clearNotification(user?.connectionType, user?.connectionId);
  }, [user]);
  useEffect(() => {
    const cooldownStartTime = localStorage.getItem("cooldownStartTime");

    if (cooldownStartTime) {
      const elapsedTime = Date.now() - parseInt(cooldownStartTime, 10);
      const cooldownPeriod = 10000;

      if (elapsedTime < cooldownPeriod) {
        const remainingTime = cooldownPeriod - elapsedTime;
        setIsCooldown(true);

        setTimeout(() => {
          setIsCooldown(false);
          localStorage.removeItem("cooldownStartTime");
        }, remainingTime);
      } else {
        setIsCooldown(false);
        localStorage.removeItem("cooldownStartTime");
      }
    }
  }, []);

  if (!user) return <Typography>Loading...</Typography>;

  const CheckdrawrWidth = user?.connectionId;

  console.log(alumniDetailsInfo, "alumniDetailsInfo")

  return (
    <MainCard>
      <Box sx={{ display: "flex" }}>
        <ChatDrawer
          alumniDetailsInfo={alumniDetailsInfo}
          handleRoomChange={handleRoomChange}
          CheckdrawrWidth={CheckdrawrWidth}
          setRoomId={setRoomId}
          openChatDrawer={openChatDrawer}
          setOpenChatDrawer={setOpenChatDrawer}
          handleDrawerOpen={handleDrawerOpen}
          users={connectionList}
          setUser={setUser}
          generateUniqueId={generateUniqueId}
        />

        {user?.connectionId || alumniDetailsInfo?.userId ? (
          <Main theme={theme} open={openChatDrawer}>
            {user && <></>}
            <Grid container spacing={gridSpacing}>
              <Grid
                item
                xs
                zeroMinWidth
                sx={{
                  display: emailDetails ? { xs: "none", sm: "flex" } : "flex",
                }}
              >
                <MainCard
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "dark.main" : "grey.50",
                  }}
                >
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={0.5}>
                        {!isFromSearchBar && (
                          <Grid item>
                            <IconButton onClick={handleDrawerOpen} size="large">
                              <MenuRoundedIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{ flexWrap: "nowrap" }}
                          >
                            <Grid item>
                              <Avatar
                                alt={user.connectionName}
                                src={`data:image/*;base64, ${user?.image?.data}`}
                              />
                            </Grid>
                            <Grid item sm zeroMinWidth>
                              <Grid container spacing={0} alignItems="center">
                                <Grid item xs={12}>
                                  <Typography variant="h4" component="div">
                                    {user?.connectionName}{" "}
                                  </Typography>
                                </Grid>
                                <Grid container spacing={1} mt={0.5}>
                                  <AvatarGroup max={10}>
                                    {groupMemberImages[user?.id]?.map(
                                      (image, idx) => (
                                        <Avatar
                                          sx={{ cursor: "pointer" }}
                                          key={idx}
                                          alt={
                                            image
                                              ? user?.connectionName
                                              : "No Photo Available"
                                          }
                                          src={
                                            image
                                              ? `data:image/*;base64, ${image}`
                                              : ""
                                          }
                                        />
                                      )
                                    )}
                                  </AvatarGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm zeroMinWidth />
                        {/* <Grid item>
                          <IconButton size="large">
                            <CallTwoToneIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton size="large">
                            <VideoCallTwoToneIcon />
                          </IconButton>
                        </Grid> */}
                        <Grid item>
                          <IconButton onClick={handleUserChange} size="large">
                            <ErrorTwoToneIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: theme.spacing(2) }} />
                    </Grid>
                    <PerfectScrollbar
                      onScrollY={(container) => {
                        if (container.scrollTop === 0 && !getlastdata) {
                          setIsFetching(true);
                          setPageNumber((prevpage) => prevpage + 1);
                        }
                      }}
                      containerRef={(ref) => {
                        scrollContainerRef.current = ref;
                      }}
                      // containerRef={scrollContainerRef}
                      style={{
                        width: "100%",
                        height: "calc(100vh - 440px)",
                        overflowX: "hidden",
                        minHeight: 525,
                      }}
                    >
                      <CardContent>
                        <ChartHistory
                          theme={theme}
                          handleUserDetails={handleUserChange}
                          handleDrawerOpen={handleDrawerOpen}
                          user={user}
                          data={data}
                        />

                        <span ref={scrollRef} />
                      </CardContent>
                    </PerfectScrollbar>

                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <IconButton
                            ref={anchorElEmoji}
                            aria-describedby={emojiId}
                            onClick={handleOnEmojiButtonClick}
                            size="large"
                          >
                            <MoodTwoToneIcon />
                          </IconButton>
                          <Popper
                            id={emojiId}
                            open={emojiOpen}
                            anchorEl={anchorElEmoji}
                            disablePortal
                            modifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [-20, 20],
                                },
                              },
                            ]}
                          >
                            <ClickAwayListener onClickAway={handleCloseEmoji}>
                              <>
                                {emojiOpen && (
                                  <MainCard elevation={8} content={false}>
                                    <Picker
                                      Theme="dark"
                                      emojiStyle="apple"
                                      onEmojiClick={onEmojiClick}
                                      skinTone={SKIN_TONE_MEDIUM_DARK}
                                      disableAutoFocus
                                    />
                                  </MainCard>
                                )}
                              </>
                            </ClickAwayListener>
                          </Popper>
                        </Grid>
                        <Grid item xs zeroMinWidth>


                          <TextField
                            value={message}
                            onKeyPress={handleEnter}
                            onChange={(event) => handleMessageChange(event)}
                            multiline
                            fullWidth
                            rows={2}
                            placeholder="Type here..."
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor:
                                  message.length > maxLength
                                    ? "red"
                                    : undefined,
                                "&:hover fieldset": {
                                  borderColor:
                                    message.length > maxLength
                                      ? "red"
                                      : undefined,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    message.length > maxLength
                                      ? "red"
                                      : undefined,
                                },
                              },
                            }}
                            error={message.length > maxLength} // Show error style when limit exceeded
                          />

                          {/* Character count */}
                          <Typography
                            variant="caption"
                            color={
                              message.length > maxLength
                                ? "red"
                                : "textSecondary"
                            }
                            sx={{ display: "block", marginTop: "5px" }}
                          >
                            {maxLength - message.length >= 0
                              ? `${maxLength - message.length
                              } characters remaining`
                              : `${Math.abs(
                                maxLength - message.length
                              )} characters over limit`}
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*, video/*"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                            multiple
                          />
                          <label htmlFor="fileInput">
                            <IconButton size="large" component="span">
                              <AttachmentTwoToneIcon />
                            </IconButton>
                          </label>
                        </Grid> */}
                        <Grid item>
                          <IconButton
                            color="primary"
                            onClick={handleOnSend}
                            disabled={
                              isCooldown ||
                              // message.trim().length === 0 ||
                              message.length > maxLength
                            }
                            size="large"
                          >
                            <SendTwoToneIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
              {emailDetails && (
                <Grid item sx={{ margin: { xs: "0 auto", md: "initial" } }}>
                  <Box
                    sx={{
                      display: { xs: "block", sm: "none", textAlign: "right" },
                    }}
                  >
                    <IconButton
                      onClick={handleUserChange}
                      sx={{ mb: -5 }}
                      size="large"
                    >
                      <HighlightOffTwoToneIcon />
                    </IconButton>
                  </Box>
                  <UserDetails groupMembers={groupMembers} user={user} />
                </Grid>
              )}
            </Grid>
          </Main>
        ) : (
          <>
            <Main theme={theme} open={openChatDrawer}>
              <Grid
                container
                spacing={gridSpacing}
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "80vh" }}
              >
                <Grid item xs zeroMinWidth>
                  <MainCard
                    sx={{
                      bgcolor:
                        theme.palette.mode === "dark" ? "dark.main" : "grey.50",
                    }}
                  >
                    <Grid
                      container
                      spacing={gridSpacing}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ minHeight: "80vh" }}
                    >
                      <Grid item>
                        <Box
                          sx={{
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "grey.800"
                                : "white",
                            padding: theme.spacing(2),
                            borderRadius: "10px",
                            boxShadow: 1,
                            maxWidth: "80%",
                          }}
                        >
                          <Typography align="center">
                            Please select a contact to initiate a chat.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>
            </Main>
          </>
        )}
      </Box>
    </MainCard>
  );
};

export default AlumniChatMainPage;
