import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
];

const EventInfo = ({
  values,
  handleChange,
  setFieldValue,
  touched,
  errors,
}) => {
  const [instruction, setInstruction] = useState(values?.instruction || "");

  useEffect(() => {
    setInstruction(values?.instruction || "");
  }, [values?.instruction]);

  const handleInstructionChange = (content) => {
    setInstruction(content);
    setFieldValue("instruction", content);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();
  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    return [year, month, day].join("-");
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          type="text"
          id="name"
          label="Event Title"
          name="title"
          value={values?.title}
          error={touched.title && Boolean(errors.title)}
          helperText={touched.title && errors.title}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Start Date *"
          margin="normal"
          name="startDate"
          value={values?.startDate}
          error={touched.startDate && Boolean(errors.startDate)}
          helperText={touched.startDate && errors.startDate}
          type="date"
          InputProps={{
            inputProps: {
              min: formatDate(new Date()),
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
        />
      </Grid>

      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Start Time *"
          margin="normal"
          name="startTime"
          value={values?.startTime}
          error={touched.startTime && Boolean(errors.startTime)}
          helperText={touched.startTime && errors.startTime}
          type="time"
          InputProps={{
            inputProps: {
              min: currentDate,
            },
          }}
          onChange={handleChange}
        />
      </Grid>

      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="End Date *"
          margin="normal"
          name="endDate"
          value={values?.endDate}
          error={touched.endDate && Boolean(errors.endDate)}
          helperText={touched.endDate && errors.endDate}
          InputProps={{
            inputProps: {
              min: formatDate(new Date()),
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          onChange={handleChange}
        />
      </Grid>

      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="End Time *"
          margin="normal"
          name="endTime"
          value={values?.endTime}
          error={touched.endTime && Boolean(errors.endTime)}
          helperText={touched.endTime && errors.endTime}
          InputLabelProps={{
            shrink: true,
          }}
          type="time"
          onChange={handleChange}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="h4">Instructions OR Description</Typography>
        <Grid mt={1}>
          <ReactQuill
            value={instruction}
            onChange={handleInstructionChange}
            theme="snow"
            modules={modules}
            formats={formats}
          />
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values?.limitRequired}
              onChange={(e) => {
                setFieldValue("limitRequired", e.target.checked);
              }}
            />
          }
          label="Enable Participant Limit"
        />
      </Grid>
    </Grid>
  );
};

export default EventInfo;
