import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import { Form, Formik } from "formik";

import React, { useEffect } from "react";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { gridSpacing } from "store/constant";
import { openSnackbar } from "store/slices/snackbar";
const SocialProfile = ({
  open,
  handleClose,
  alumniDetails,
  getAlumniDetails,
}) => {
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  // Check if alumniDetails exists, if not set empty object
  const initialValues = {
    socialLinkTypeLinkedin: "LinkedIn",
    socialLinkTypeTwitter: "Twitter",
    socialLinkTwitter:
      alumniDetails?.socialLinks?.find(
        (link) => link.socialLinkType === "Twitter"
      )?.socialLink ?? "",
    socialLinkLinkedIn:
      alumniDetails?.socialLinks?.find(
        (link) => link.socialLinkType === "LinkedIn"
      )?.socialLink ?? "",
  };

  const handleSubmit = (values) => {
    const socialProfiles = [];

    // if (alumniDetails?.socialLinks) {
    if (values.socialLinkLinkedIn) {
      socialProfiles.push({
        socialLinkType: "LinkedIn",
        socialLink: values.socialLinkLinkedIn,
      });
    }
    if (values.socialLinkTwitter) {
      socialProfiles.push({
        socialLinkType: "Twitter",
        socialLink: values.socialLinkTwitter,
      });
    }
    // }

    axios
      .post(
        `/v1/alumni/add-update/social/link?alumniId=${alumniDetails?.id}`,
        socialProfiles,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              (values.socialLinkLinkedIn || "") + rendomRequestId
            ),
          },
        }
      )
      .then((value) => {
        // Handle success response
        getAlumniDetails();
        handleClose();
        dispatch(
          openSnackbar({
            message: value.data.Msg,
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );

        generateTimeStamp();
      })
      .catch((error) => {
        // Handle error response
        handleClose();
        generateTimeStamp();
        if (error.response && error.response.status === 404) {
          dispatch(
            openSnackbar({
              message: error.response.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          handleClose();
          dispatch(
            openSnackbar({
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Social Profile</DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <Grid container spacing={gridSpacing} p={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      label="LinkedIn Profile"
                      name="socialLinkLinkedIn"
                      fullWidth
                      onChange={handleChange}
                      value={values.socialLinkLinkedIn}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      label="Twitter Profile"
                      name="socialLinkTwitter"
                      onChange={handleChange}
                      fullWidth
                      value={values.socialLinkTwitter}
                    />
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SocialProfile;
