import React, { useState, useEffect } from "react";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import { useParams } from "react-router-dom";
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import NoAviableData from "reuseableTable/NoAvailableData";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import moment from "moment";
import MainCard from "ui-component/cards/MainCard";
import NavigateBackButton from "reuseableTable/NavigateBackButton";
import MainTabs from "./MainTab";

import CategoryIcon from "@mui/icons-material/Category";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoIcon from "@mui/icons-material/Info";

import EventIcon from "@mui/icons-material/Event";
import PinDropSharpIcon from "@mui/icons-material/PinDropSharp";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { ArrowBack } from "@mui/icons-material";

const JoinedEvents = ({ postId, type = "admin", handleCloseDetails }) => {
  const [pageData, setPageData] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(11);
  const [totalCount, setTotalCount] = useState(0);

  const [eventDetails, setEventDetails] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [groupMemberImages, setGroupMemberImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const { id } = useParams();

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const chipsToShow = showAll
    ? eventDetails?.eventForDetails
    : eventDetails?.eventForDetails?.slice(0, 10);

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };
  const formatDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const formattedDateTime = moment(dateTimeString).format(
      "DD MMMM - YYYY / hh:mm A"
    );
    return formattedDateTime;
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "left",
      render: (row) => (
        <div
          style={{
            display: "flex",
          }}
        >
          <Avatar
            alt={row?.name}
            src={`data:image/*;base64, ${row?.alumniMemberMini?.avatar?.data}`}
          />
          <div style={{ marginLeft: "5px" }}>
            <p
              // onClick={() => handleNavigate(row.userId)}
              style={{
                // color: "blue",
                // textDecoration: "underline",
                // cursor: "pointer",
                fontWeight: "bold",
                margin: "0",
              }}
            >
              {row?.alumniMemberMini?.name}
            </p>
            <p style={{ margin: "0" }}>{row?.alumniMemberMini?.email}</p>
          </div>
        </div>
      ),
    },

    {
      id: "phone",
      label: "Phone",
      minWidth: 100,
      align: "left",
      render: (row) => <div>{row?.alumniMemberMini?.phoneNo}</div>,
    },
    {
      id: "batch",
      label: "Batch",
      minWidth: 100,
      align: "left",
      render: (row) => <div>{row?.alumniMemberMini?.batchTag}</div>,
    },
    {
      id: "appliedDateTime",
      label: "Joined At",
      minWidth: 100,
      align: "left",
      render: (row) => {
        const formattedDate = moment(row?.appliedDateTime).format("DD/MM/YYYY");
        const formattedTime = moment(row?.appliedDateTime).format("h:mm A");
        return (
          <div>
            <p>{formattedDate}</p>
            <p>{formattedTime}</p>
          </div>
        );
      },
    },
    {
      id: "volunteers",
      label: "Joined As",
      minWidth: 100,
      align: "left",
      render: (row) => {
        return (
          <div>
            <p>{row?.volunteers ? "Volunteers" : "Participant"}</p>
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `/v1/event/event/participants?postId=${
          id ?? postId
        }&pageNumber=${page}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );
      setPageData(response?.data?.data?.content);
      setTotalCount(response?.data?.data?.totalElements);
      setPageCache((prev) => ({
        ...prev,
        [page]: response?.data?.data?.content,
      }));

      const groupMembers = response?.data?.data?.content || [];
      const memberImages = groupMembers.map((member) =>
        member.alumniMemberMini.avatar === null
          ? ""
          : `data:image/*;base64, ${member.alumniMemberMini.avatar.data}`
      );

      setGroupMemberImages((prev) => ({
        ...prev,
        memberImages,
      }));

      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const getEventDetails = async () => {
    try {
      const response = await axios.get(
        `/v1/event/details?eventId=${id ?? postId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setEventDetails(response?.data?.data);

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setPageData(pageCache[page]);
    } else {
      fetchData();
    }
  }, [page, rowsPerPage, pageCache]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getEventDetails();
    generateTimeStamp();
  }, []);

  const date = moment(eventDetails?.startDate);
  const month = date.format("MMM");
  const day = date.format("DD");

  const handleLocationClick = () => {
    const encodedLocation = encodeURIComponent(eventDetails?.eventSpace ?? "");
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <MainCard>
      {type === "admin" ? (
        <NavigateBackButton name="Details" />
      ) : (
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontWeight: 400, fontSize: "20px", color: "black" }}
          >
            <IconButton color="inherit">
              <ArrowBack onClick={() => handleCloseDetails()} />
            </IconButton>
            Back
          </Typography>
        </Grid>
      )}
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          <>
            <Grid container spacing={2} p={2}>
              <Grid key={eventDetails.id} item xs={12} md={12}>
                <Card
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                  }}
                  variant="outlined"
                >
                  <CardMedia
                    component="img"
                    height="194"
                    image={eventDetails?.coverIcon}
                    alt="Paella dish"
                  />

                  <CardActions>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <EventIcon sx={{ color: "#2377dd" }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h4"
                          color="textSecondary"
                          component="span"
                        >
                          {formatDateTime(
                            eventDetails?.startDate,
                            eventDetails?.startTime
                          )}
                          <span style={{ color: "black" }}> To </span>

                          {formatDateTime(
                            eventDetails?.endDate,
                            eventDetails?.endTime
                          )}
                        </Typography>
                      </Grid>
                      <Grid container sx={{ mt: 1, ml: 1 }}>
                        <AvatarGroup max={3}>
                          {groupMemberImages["memberImages"]?.map(
                            (image, idx) => (
                              <Avatar
                                sx={{ cursor: "pointer" }}
                                key={idx}
                                alt={
                                  image
                                    ? eventDetails?.name
                                    : "No Photo Available"
                                }
                                src={image || ""}
                              />
                            )
                          )}
                        </AvatarGroup>
                      </Grid>
                    </Grid>

                    {/* <IconButton onClick={() => handleOpenInfo()} aria-label="info">
              <InfoOutlined color="secondary" />
            </IconButton> */}
                    {/* <Tag sx={{ ml: 1 }}>{post?.approvalStatus}</Tag> */}
                  </CardActions>
                  <CardContent sx={{ mt: -4 }}>
                    <Grid container>
                      <Grid item md={11} xs={12}>
                        <Typography
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                          variant="h3"
                        >
                          <EventNoteIcon
                            sx={{
                              marginRight: 1,
                              cursor: "pointer",
                              mt: 1,
                              color: "#2377dd",
                            }}
                            // onClick={handleLocationClick}
                          />
                          {eventDetails?.title}
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CategoryIcon
                            sx={{
                              marginRight: 1,
                              cursor: "pointer",
                              mt: 1,
                              color: "#2377dd",
                            }}
                            // onClick={handleLocationClick}
                          />
                          {eventDetails?.eventCategories
                            ? eventDetails?.eventCategories?.name
                            : eventDetails?.categoryName}

                          {/* {eventDetails?.eventCategories?.name} */}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PinDropSharpIcon
                            sx={{
                              marginRight: 1,
                              cursor: "pointer",
                              color: "#2377dd",
                            }}
                            onClick={handleLocationClick}
                          />
                          <a
                            href={eventDetails?.eventLink ?? ""}
                            // target="_blank"
                            // rel="noopener noreferrer"
                          >
                            {eventDetails?.eventSpace ?? ""}
                          </a>
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <InfoIcon
                            sx={{
                              marginRight: 1,
                              cursor: "pointer",
                              mt: 1,
                              color: "#2377dd",
                            }}
                            // onClick={handleLocationClick}
                          />
                          {stripHtmlTags(eventDetails?.instruction)}
                        </Typography>
                      </Grid>

                      <Grid item md={1} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100px",
                            width: "100px",
                            backgroundColor: "#2377dd",
                            borderRadius: "10px",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
                            textAlign: "center",
                            color: "#fafafa",
                          }}
                        >
                          <h4 style={{ margin: 0 }}>{month.toUpperCase()}</h4>
                          <h1 style={{ margin: 0 }}>{day}</h1>
                        </div>
                      </Grid>

                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          gap: 1,
                          marginTop: "15px",
                        }}
                      >
                        {chipsToShow?.map((data, index) => (
                          <Chip key={index} size="small" label={data?.name} />
                        ))}
                        {eventDetails?.eventForDetails?.length > 10 && (
                          <IconButton
                            size="small"
                            onClick={handleShowMore}
                            sx={{ padding: 0 }}
                          >
                            {showAll ? (
                              <FirstPageIcon sx={{ color: "primary" }} />
                            ) : (
                              <LastPageIcon />
                            )}
                          </IconButton>
                        )}
                      </Box>

                      {eventDetails?.userId ===
                        localStorage.getItem("userId") &&
                        eventDetails?.approvalStatus === "Approved" && (
                          <MainTabs
                            data={pageData}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            columns={columns}
                            eventDetails={eventDetails}
                          />
                        )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        </>
      )}
    </MainCard>
  );
};

export default JoinedEvents;
