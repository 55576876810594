import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

const PaymentInfo = ({ values, handleChange, setFieldValue }) => {
  const handleCheckboxChangeDonation = (event) => {
    const { name, checked } = event.target;
    setFieldValue("funding", checked);
  };

  const handleCheckboxChangeParticipant = (event) => {
    const { name, checked } = event.target;
    setFieldValue("requiredParticipantFee", checked);
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.funding}
              onChange={handleCheckboxChangeDonation}
              name="activeDonation"
            />
          }
          label="Active Donation"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.requiredParticipantFee}
              onChange={handleCheckboxChangeParticipant}
              name="activeParticipantFee"
            />
          }
          label="Active Participant Fee"
        />
      </Grid>
      {values?.requiredParticipantFee && (
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            label="Amount"
            name="participantFee"
            onChange={handleChange}
            value={values?.participantFee}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentInfo;
