import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import React, { useEffect, useState } from "react";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import EventPostDetailsCard from "./EventPostDetailsCard";
import NoAvailableData from "reuseableTable/NoAvailableData";
import JoinedEvents from "views/joinedEvents";

const UpcomingEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  const [clickedDetails, setClickedDetails] = useState(false);
  const [postId, setPostId] = useState("");

  const handleClickedDetails = (id) => {
    setPostId(id);
    setClickedDetails(true);
  };
  const handleCloseDetails = () => {
    setClickedDetails(false);
  };

  const [eventType, setEventType] = useState("Upcoming");

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getEventDetails = async (eventType) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/event/my?pageSize=${rowsPerPage}&pageNumber=${page}&myEventType=${eventType ?? "Upcoming"
        }`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setEventDetails(response?.data?.data?.content);
      setPageData(response?.data?.data?.content);
      setTotalCount(response?.data?.data?.totalElements);
      setPageCache((prev) => ({
        ...prev,
        [page]: response?.data?.data?.content,
      }));
      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setEventDetails(pageCache[page]);
    } else {
      getEventDetails();
    }
  }, [page, rowsPerPage, pageCache]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageCache({});
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);
  return (
    <>
      {clickedDetails ? (
        <>
          <JoinedEvents
            handleCloseDetails={handleCloseDetails}
            type="alumni"
            postId={postId}
          />
        </>
      ) : (
        <>
          <Grid item md={4} xs={12} p={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Event Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={eventType}
                label="Event Type"
                onChange={(e) => {
                  setEventType(e.target.value);
                  getEventDetails(e.target.value);
                }}
              >
                <MenuItem value="My_All"> My All</MenuItem>
                <MenuItem value="Upcoming">Upcoming</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {isLoading ? (
            <>
              <AnimatedLoader />
            </>
          ) : (
            <>
              {eventDetails?.length > 0 ? (
                <>
                  <Grid container spacing={2} p={2}>
                    {eventDetails?.map((post) => (
                      <Grid key={post.id} item xs={12} md={3} lg={3}>
                        <EventPostDetailsCard
                          handleClickedDetails={handleClickedDetails}
                          handleCloseDetails={handleCloseDetails}
                          setPost={setEventDetails}
                          post={post}
                          getEventDetails={getEventDetails}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  <NoAvailableData text={"There are no any Upcoming Event."} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default UpcomingEvents;
