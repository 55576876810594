import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import NoAvailableData from "reuseableTable/NoAvailableData";

const BatchDetails = ({
  open,
  batchId,
  handleClose,
  setBatchId,
  setSelectedBatchIds,
  selectedBatchIds,
}) => {
  const [batchList, setBatchList] = useState([]);
  const role = localStorage.getItem("role");

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleOptionChange = (event) => {
    if (role === "ROLE_COLLEGE_ADMIN") {
      const value = event.target.value;
      setSelectedBatchIds((prevSelected) =>
        prevSelected.includes(value)
          ? prevSelected.filter((id) => id !== value)
          : [...prevSelected, value]
      );
    } else {
      setBatchId(event.target.value);
    }
  };

  const getBatchList = async () => {
    let api = "/v1/batch/list";

    try {
      const response = await axios.get(`${api}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: ConvertToRequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setBatchList(response?.data?.BatchList);

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    getBatchList();
    generateTimeStamp();
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 500 }} variant="h3">
                Select a Batch
              </Typography>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Grid>

            {batchList?.length > 0 ? (
              <>
                {batchList?.map((data) => (
                  <Grid item md={12} xs={12} key={data.id}>
                    {role === "ROLE_COLLEGE_ADMIN" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedBatchIds.includes(data.id)}
                            onChange={handleOptionChange}
                            value={data.id}
                          />
                        }
                        label={data?.tag}
                      />
                    ) : (
                      <RadioGroup value={batchId} onChange={handleOptionChange}>
                        <FormControlLabel
                          value={data?.id}
                          label={data?.tag}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    )}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <NoAvailableData
                  text={"Oops! It seems like there is no batch available"}
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            md={12}
            xs={12}
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 10,
              "& > :not(:first-child)": { ml: 2 },
            }}
          >
            <Chip
              onClick={handleClose}
              sx={{
                backgroundColor: "#0a66c2",
                color: "#f0f0f0",
                fontWeight: 450,
                width: 60,
              }}
              label="Back"
            />

            <Chip
              onClick={handleClose}
              sx={{
                backgroundColor: "#0a66c2",
                color: "#f0f0f0",
                fontWeight: 450,
                width: 60,
              }}
              label="Done"
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BatchDetails;
