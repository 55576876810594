import React, { useEffect, useState } from "react";
import {
  Chip,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import moment from "moment";
import SubCard from "ui-component/cards/SubCard";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axios from "axios";
import ConfettiExplosion from "react-confetti-explosion";
import { width } from "@mui/system";

const EventDetails = ({ post, setPosts, showConfetti, setShowConfetti }) => {
  const [open, setOpen] = useState(false);

  const config = {
    force: 0.6,
    duration: 2500,
    particleCount: 200,
    width: 2000,
  };

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleJoinClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleJoinAsParticipant = () => {
    setShowConfetti(false);
    axios
      .get(
        `/v1/posts/register/for/event?postId=${
          post?.id
        }&alumniId=${localStorage.getItem("alumniId")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "multipart/form-data",
            requestId: ConvertToRequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      )
      .then((response) => {
        setShowConfetti(true);
        dispatch(
          openSnackbar({
            open: true,
            message: "SuccessFully! Joined",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );

        setPosts(response?.data?.data);

        handleClose();

        generateTimeStamp();
      })
      .catch((error) => {
        generateTimeStamp();
        dispatch(
          openSnackbar({
            open: true,
            message: error.response.data.Msg,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      });
  };

  const formatDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const formattedDateTime = moment(dateTimeString).format(
      "DD MMMM - YYYY, h:mm A"
    );
    return formattedDateTime;
  };

  return (
    <>
      <SubCard title="Event Details">
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
              <span style={{ marginLeft: "20px" }}>
                {post?.eventName ?? ""}
              </span>
            </Typography>
            {post?.eventType === "Online" ? (
              <Typography>
                <span style={{ fontWeight: "bold" }}>Link:</span>{" "}
                <span style={{ marginLeft: "30px" }}>
                  {" "}
                  <a href={post?.eventLink ?? ""} target="blank"> {post?.eventLink ?? ""}</a>
                </span>
              </Typography>
            ) : (
              <Typography>
                <span style={{ fontWeight: "bold" }}>Location:</span>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {post?.eventLocation ?? ""}
                </span>
              </Typography>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Date & Time:</span>{" "}
              <span style={{ marginLeft: "10px" }}>
                {" "}
                {post?.eventTime
                  ? formatDateTime(post.eventDate, post.eventTime)
                  : ""}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {!post?.eventParticipantsIds?.includes(
              localStorage.getItem("userId")
            ) ? (
              <Chip
                sx={{ cursor: "pointer" }}
                color="primary"
                variant="outlined"
                label="Be a Part of It!"
                onClick={handleJoinClick}
              />
            ) : (
              <Chip color="primary" variant="outlined" label="Already Joined" />
            )}
          </Grid>
        </Grid>
        {showConfetti && (
          <div>
            <ConfettiExplosion config={config} />
          </div>
        )}
      </SubCard>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to join as a participant?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleJoinAsParticipant();
              handleClose();
            }}
            color="primary"
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventDetails;
