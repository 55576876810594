import { Grid, TablePagination } from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import React, { useEffect, useState } from "react";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import InvitationCard from "./InvitationCard";
import NoAvailableData from "reuseableTable/NoAvailableData";

const SentInvitation = () => {
  const [sentfriendRequest, setSentFriendRequest] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageCache({});
  };

  const getSentInvitationDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/connection/request/sent?approvalStatus=Pending&pageSize=${rowsPerPage}&pageNumber=${page}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setSentFriendRequest(response?.data?.data?.content);
      setTotalCount(response?.data?.data?.totalElements);
      setPageCache((prev) => ({
        ...prev,
        [page]: response?.data?.data?.content,
      }));

      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setSentFriendRequest(pageCache[page]);
    } else {
      getSentInvitationDetails();
    }
  }, [page, rowsPerPage, pageCache]);

  useEffect(() => {
    generateTimeStamp();
  }, []);
  return (
    <>
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          {sentfriendRequest?.length > 0 ? (
            <>
              <Grid container direction="row" spacing={2} mt={2}>
                {sentfriendRequest.map((friend, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <InvitationCard
                      getSentInvitationDetails={getSentInvitationDetails}
                      friend={friend}
                      type="sent"
                    />
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NoAvailableData text={"There are no any Send Invitation."} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SentInvitation;
