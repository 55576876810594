import { Button, CircularProgress } from "@mui/material";
import React from "react";

const LoaderButton = ({ isSubmitting}) => {
  return (
    <>
      <Button
        variant="contained"
        type="submit"
        startIcon={
          isSubmitting ? (
            <CircularProgress size="1rem" sx={{ color: "red" }} />
          ) : undefined
        }
        disabled={isSubmitting}
      >
        {isSubmitting ? "Please Wait" : "Save"}
      </Button>
    </>
  );
};

export default LoaderButton;
