import axios from "axios";
import useRequestIdGenerater from "customhooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfilePage from "./profilePage";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { Button } from "@mui/material";

const AlumniProfile = ({ setValue }) => {
  const [alumniDetails, setAlumniDetails] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const { id, value } = useParams();

  const Role = localStorage.getItem("role");

  const getAlumniDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/alumni/info?userId=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Role,
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setAlumniDetails(response?.data?.data);
      localStorage.setItem("alumniBatchId", response?.data?.data?.batch?.id ?? "");
      setIsLoading(false);
      generateTimeStamp();
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
   
      getAlumniDetails();
    

    generateTimeStamp();
  }, []);

  const IsConnection = id ? true : false;

  return (
    <>
      <ProfilePage
        IsConnection={IsConnection}
        isLoading={isLoading}
        alumniDetails={alumniDetails}
        getAlumniDetails={getAlumniDetails}
      />
    </>
  );
};

export default AlumniProfile;
