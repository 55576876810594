import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import BatchMultiSelect from "reuseableTable/autoCompleteMultiSelect/BatchMultiSelect";
import GroupMultiSelect from "reuseableTable/autoCompleteMultiSelect/GroupMultiSelect";
import GroupMultiSelectforAlumni from "reuseableTable/autoCompleteMultiSelect/GroupMultiSelectforAlumni";

const InviteTo = ({
  values,
  handleChange,
  setSelectedBatchs,
  setSelectedGroups,
  selectedBatchs,
  selectedGroups,
  setFieldValue,
  formikRef,
  alumngroup,
}) => {
  const handleRadioChange = (event) => {
    const { value } = event.target;

    setFieldValue("eventFor", value);
  };

  const handleGroupChange = (newSelectedGroups) => {
    setSelectedGroups(newSelectedGroups);

    const selected = newSelectedGroups?.map((data) => data?.id);
    setFieldValue("eventForIds", selected);
  };

  const handleBatchChange = (newSelectedGroups) => {
    setSelectedBatchs(newSelectedGroups);
    const selected = newSelectedGroups?.map((data) => data?.id);
    setFieldValue("eventForIds", selected);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Grid item md={12} xs={12} />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={values.eventFor}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="Group"
                control={<Radio />}
                label="Group"
              />
              <FormControlLabel
                value="Batch"
                control={<Radio />}
                label="Batch"
              />
              <FormControlLabel
                value="EveryOne"
                control={<Radio />}
                label="EveryOne"
              />
              {/* <FormControlLabel
                value="disabled"
                // disabled
                control={<Radio />}
                label="Specific Person"
              /> */}
            </RadioGroup>
          </FormControl>
        </Grid>

        {values?.eventFor === "Group" && (
          <>
            {!alumngroup ? (
              <>
                <Grid item md={12} xs={6}>
                  <GroupMultiSelect
                    setSelectedBatchs={setSelectedBatchs}
                    setSelectedGroups={setSelectedGroups}
                    value={selectedGroups}
                    setFieldValue={setFieldValue}
                    onChange={handleGroupChange}
                    label="Select Groups"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={12} xs={6}>
                  <GroupMultiSelectforAlumni
                    setSelectedBatchs={setSelectedBatchs}
                    setSelectedGroups={setSelectedGroups}
                    value={selectedGroups}
                    setFieldValue={setFieldValue}
                    onChange={handleGroupChange}
                    label="Select Groups"
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {values?.eventFor === "Batch" && (
          <Grid item md={12} xs={6}>
            <BatchMultiSelect
              setSelectedBatchs={setSelectedBatchs}
              value={selectedBatchs}
              setFieldValue={setFieldValue}
              onChange={handleBatchChange}
              label="Select Batch"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InviteTo;
