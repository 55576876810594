import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Avatar,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ApplicationDetailsDialog from "./ApplicationDetailsDailog";

const StarAlumniHighlight = ({ alumni, staralumniRequests }) => {
  const [openDetails, setOpenDetails] = useState(false);
  return (
    <Card
      sx={{
        textAlign: "center",
        padding: 4,
        backgroundImage: `url("https://img.freepik.com/free-psd/golden-celebration-design-elements_23-2150317957.jpg")`,

        // backgroundImage: `url("https://img.pikbest.com/back_our/20201028/bg/c1407da365a9d.png!w700wp")`,
        color: "white",
        borderRadius: "20px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.36)",
        maxWidth: 500,
        height: 420,
        margin: "auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Circular Avatar with Banner */}
      <Box
        sx={{
          margin: "auto",
          position: "relative",
          width: 150,
          height: 150,
        }}
      >
        {/* Avatar */}
        <Avatar
          src={alumni?.largeAvatar || "/default-avatar.png"}
          alt="Star Alumni"
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 2,
            overflow: "hidden",
            border: "5px solid #ecbe32", // Base border color
            borderRadius: "50%",
            animation: "sparkle-border 1s infinite", // Apply the sparkling animation
            "@keyframes sparkle-border": {
              "0%": {
                borderColor: "#ecbe32", // Normal color
                boxShadow:
                  "0 0 5px #ecbe32, 0 0 10px #ecbe32, 0 0 15px #ecbe32",
              },
              "50%": {
                borderColor: "#ffdf4d", // Lighter golden shade on halfway
                boxShadow:
                  "0 0 5px #ffdf4d, 0 0 10px #ffdf4d, 0 0 15px #ffdf4d",
              },
              "100%": {
                borderColor: "#ecbe32", // Return to the original color
                boxShadow:
                  "0 0 5px #ecbe32, 0 0 10px #ecbe32, 0 0 15px #ecbe32",
              },
            },
          }}
        />
      </Box>

      {/* Congratulations Text */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: "cursive",
          marginTop: 2,
          fontWeight: "bold",
          textShadow: "0 0 10px rgba(239, 227, 159, 0.8)",
          color: "#ecbe32",
        }}
      >
        Congratulations
      </Typography>

      {/* Alumni Name on Golden Banner */}
      <Box
        sx={{
          background: "#ecbe32",
          color: "#0a0f1f",
          padding: "8px 16px",
          borderRadius: "5px",
          display: "inline-block",
          fontWeight: "bold",
          fontSize: "18px",
          marginTop: 2,
          textTransform: "uppercase",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        {alumni?.name || "Star Alumni"}
      </Box>

      {/* Achievement Message */}
      <Typography
        variant="body2"
        sx={{
          marginTop: 2,
          padding: "0 16px",
          fontStyle: "italic",
          textShadow: "0 0 5px rgba(255, 255, 255, 0.4)",
          color: "#000",
        }}
      >
        Congratulations you have been recognized as a "Star Alumni" for your
        outstanding achievements and contributions.
      </Typography>
      <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 1 }}>
        {/* View Achievements Button */}
        <Grid item>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#ecbe32", // Button background color
              textTransform: "none",
              padding: "8px 16px",
              fontWeight: "bold",
              fontSize: "16px",
              borderRadius: "8px",
              color: "#0a0f1f", // Text color
              "&:hover": {
                bgcolor: "#c89f28", // Darker shade of the base color for hover
              },
            }}
            onClick={() => {
              setOpenDetails(true);
              console.log("View Achievements clicked");
              // Add your navigation or logic here
            }}
          >
            View Achievements
          </Button>
        </Grid>

        {/* Add More Achievements Button */}
        <Grid item>
          <Button
            sx={{
              bgcolor: "#ecbe32", // Button background color
              textTransform: "none",
              padding: "8px 16px",
              fontWeight: "bold",
              fontSize: "16px",
              borderRadius: "8px",
              color: "#0a0f1f", // Text color for contrast

              "&:hover": {
                bgcolor: "#c89f28", // Darker shade of the base color for hover
                color: "#0a0f1f", // Change text color on hover
              },
            }}
            onClick={() => {
              console.log("Add More Achievements clicked");
              // Add your navigation or logic here
            }}
          >
            Add More Achievements
          </Button>
        </Grid>
      </Grid>
      <ApplicationDetailsDialog
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        applicationId={staralumniRequests[0]?.id}
      />
    </Card>
  );
};

export default StarAlumniHighlight;
