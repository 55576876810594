import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

// project import
import { gridSpacing } from "store/constant";

import moment from "moment";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";

// ==============================|| SOCIAL PROFILE - FRIEND REQUEST CARD ||============================== //

const InvitationCard = ({ friend, getSentInvitationDetails, type }) => {
  const theme = useTheme();
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const handleOpenDialog = (requestId) => {
    setSelectedRequestId(requestId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequestId(null);
  };

  const btnSX = {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.grey[200],
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.dark
        : theme.palette.background.paper,
  };

  const handleCancelRequest = async (userId) => {
    const response = await axios.put(
      `/v1/connection/request/cancel/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      }
    );
    getSentInvitationDetails()
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Cancelled Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: response?.data?.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };
  const handleAcceptInvitation = async (requestId) => {
    const response = await axios
      .put(
        `/v1/connection/request/process/${requestId}?approvalStatus=Approved`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      )

      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Accepted Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        getSentInvitationDetails();
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: error?.response?.data?.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };
  const confirmRejectInvitation = async () => {
    if (!selectedRequestId) return;
    try {
      const response = await axios.put(
        `/v1/connection/request/process/${selectedRequestId}?approvalStatus=Rejected`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      dispatch(
        openSnackbar({
          open: true,
          message: "Rejected Successfully",
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
      getSentInvitationDetails();
      generateTimeStamp();
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: "Something went wrong",
          variant: "alert",
          alert: {
            color: "error",
          },
          close: false,
        })
      );
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          background:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[50],
          border: "1px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[100],
          "&:hover": {
            border: `1px solid${theme.palette.primary.main}`,
          },
        }}
      >
        {type === "Recevied" ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item>
                  <Avatar
                    alt="User 1"
                    src={`data:image/*;base64, ${friend?.fromAlumniMemberMini?.avatar?.data}`}
                    sx={{
                      border: friend?.fromAlumniMemberMini?.starAlumni
                        ? "3px solid #D89E46"
                        : "none",
                    }}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    variant="h5"
                    sx={{
                      display: "inline-flex", // Align name and icon on the same line
                      alignItems: "center", // Vertically align the icon with the text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {friend?.fromAlumniMemberMini?.name}
                    {friend?.fromAlumniMemberMini?.starAlumni && (
                      <VerifiedIcon
                        sx={{
                          color: "#D89E46",
                          fontSize: "18px",
                          marginLeft: "4px",
                        }}
                      />
                    )}
                  </Typography>
                  <Typography>
                    {friend?.fromAlumniMemberMini?.careerStatus}
                  </Typography>
                  <Typography>
                    {friend?.fromAlumniMemberMini?.batchTag}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {moment(friend?.sentOn).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleAcceptInvitation(friend?.id)}
                    variant="outlined"
                    fullWidth
                    sx={btnSX}
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleOpenDialog(friend?.id)}
                    variant="outlined"
                    fullWidth
                    color="error"
                    sx={btnSX}
                  >
                    Reject
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item>
                  <Avatar
                    alt="User 1"
                    src={`data:image/*;base64, ${friend?.toAlumniMemberMini?.avatar?.data}`}
                    sx={{
                      border: friend?.toAlumniMemberMini?.starAlumni
                        ? "3px solid #D89E46"
                        : "none",
                    }}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    variant="h5"
                    sx={{
                      display: "inline-flex", // Align name and icon on the same line
                      alignItems: "center", // Vertically align the icon with the text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {friend?.toAlumniMemberMini?.name}
                    {friend?.toAlumniMemberMini?.starAlumni && (
                      <VerifiedIcon
                        sx={{
                          color: "#D89E46",
                          fontSize: "18px",
                          marginLeft: "4px", // Space between the name and the icon
                        }}
                      />
                    )}
                  </Typography>

                  <Typography>
                    {friend?.toAlumniMemberMini?.careerStatus}
                  </Typography>
                  <Typography>
                    {friend?.toAlumniMemberMini?.batchTag}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {moment(friend?.sentOn).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label="Pending"
                    icon={<WatchLaterOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleCancelRequest(friend?.toUserId)}
                    variant="outlined"
                    fullWidth
                    color="error"
                    sx={btnSX}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Rejection</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to reject this invitation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRejectInvitation} color="error" autoFocus>
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

InvitationCard.propTypes = {
  avatar: PropTypes.string,
  mutual: PropTypes.number,
  name: PropTypes.string,
};

export default InvitationCard;
