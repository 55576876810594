import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import SubCard from "ui-component/cards/SubCard";

const EducationDetailsCard = ({
  alumniDetails,
  handleAddEducatiobNavigation,
  handleNavigate,
  IsConnection,
}) => {
  return (
    <>
      <SubCard
        sx={{
          p: 1,
          mt: 2,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ffffff",
          },
        }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <InfoIcon sx={{ marginRight: 1 }} /> */}
              <span style={{ color: "#245081", fontSize: "18px" }}>
                Education
              </span>
            </Typography>
          </div>
        }
        secondary={
          <>
            {!IsConnection && (
              <>
                {alumniDetails?.educationDetails !== null && (
                  <div style={{ cursor: "pointer" }}>
                    <img
                      height="20px"
                      onClick={() =>
                        handleAddEducatiobNavigation(alumniDetails?.id)
                      }
                      alt="icon"
                      src="/assets/images/icons/addIcon.svg"
                    />
                    <img
                      height="20px"
                      style={{ marginLeft: "20px" }}
                      onClick={() => handleNavigate(alumniDetails?.id)}
                      alt="icon"
                      src="/assets/images/icons/editIcon.svg"
                    />
                  </div>
                )}
              </>
            )}
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {alumniDetails?.educationDetails === null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              {!IsConnection ? (
                <>
                  <img
                    height="40px"
                    onClick={() =>
                      handleAddEducatiobNavigation(alumniDetails?.id)
                    }
                    alt="icon"
                    src="/assets/images/icons/addPlus.svg"
                  />
                </>
              ) : (
                "No Education Deatils Provided"
              )}
            </div>
          ) : (
            <>
              {alumniDetails?.educationDetails?.map((data) => (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item md={9} xs={10}>
                      <Typography variant="subtitle1">
                        {data?.affiliation}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        {data?.instituteName}
                      </Typography>
                      {/* <Typography
                                    variant="primary"
                                    sx={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {moment(data?.joiningDate).format(
                                      "MMMM-YYYY"
                                    )}{" "}
                                    -
                                    {data?.endDate
                                      ? moment(data?.endDate).format(
                                          "MMMM-YYYY"
                                        )
                                      : "Current"}
                                  </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Box>
        </Grid>
      </SubCard>
    </>
  );
};

export default EducationDetailsCard;
