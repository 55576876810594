import {
  Alert,
  Avatar,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import ViewUploadedImages from "../ViewUploadedImages";
import PublicIcon from "@mui/icons-material/Public";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/Delete";
import MainCard from "ui-component/cards/MainCard";
import moment from "moment";
import { Box } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import VerifiedIcon from "@mui/icons-material/Verified";

const EventPostDetailsCard = ({ post, type = "All", getPostDetails }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = () => {
    handleDeletePost(); // Pass the reason to the delete handler

    handleClose();
  };
  const handleDeletePost = async (reason) => {
    console.log("Delete button clicked for", reason, post.id);

    const response = await axios.delete(`/v1/posts/delete?postId=${post.id}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
        "Content-Type": "application/json",
        requestId: RequestId(
          Math.floor(1000 + Math.random() * 9999),
          Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
        ),
      },
    });
    getPostDetails()
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Deleted Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: response?.data?.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };
  return (
    <MainCard mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Avatar
                alt="User 1"
                src={`data:image/*;base64, ${post?.alumniMemberMini?.avatar?.data}`}
                sx={{
                  border: post?.alumniMemberMini?.starAlumni
                    ? "3px solid #D89E46"
                    : "none",
                }}
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography
                    align="left"
                    variant="h5"
                    component="div"
                    sx={{
                      display: "inline-flex", // Align name and icon on the same line
                      alignItems: "center", // Vertically align the icon with the text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {post?.alumniMemberMini?.name}
                    {post?.alumniMemberMini?.starAlumni && (
                      <VerifiedIcon
                        sx={{
                          color: "#D89E46",
                          fontSize: "18px",
                          marginLeft: "4px",
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  {post?.postsFor === "EveryOne" ? (
                    <PublicIcon />
                  ) : post?.postsFor === "Batch" ? (
                    <WorkspacesIcon />
                  ) : (
                    <GroupsIcon />
                  )}
                </Grid>
                <Grid item>
                  <Typography align="left" variant="caption">
                    {/* {post.createdAt} */}
                    {moment(post.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip title="delete post">
                <IconButton onClick={handleOpen}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <ButtonBase sx={{ borderRadius: "12px" }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.smallAvatar,
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.secondary.light,
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.light
                        : theme.palette.secondary.dark,
                    zIndex: 1,
                    transition: "all .2s ease-in-out",
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.main,
                      color: theme.palette.secondary.light,
                    },
                  }}
                  aria-controls="menu-post"
                  aria-haspopup="true"
                >
                  <MoreVertTwoToneIcon fontSize="inherit" />
                </Avatar>
              </ButtonBase>
              <Menu
                id="menu-post"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleClose}>Edit</MenuItem>
                <MenuItem onClick={handleClose}>Delete</MenuItem>
              </Menu>
            </Grid> */}
          </Grid>
        </Grid>
        {post?.revokeInfo ? (
          <>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: "#FFF5F5",
                    color: "#D32F2F",
                    padding: 2,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #F8D7DA",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="error"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    <WarningAmberIcon /> Your content has been deleted.
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1, mb: 0.5 }}>
                    <strong>Deleted By:</strong> {post.revokeInfo.revokeBy}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 0.5 }}>
                    <strong>Delete Date:</strong>{" "}
                    {moment(post.revokeInfo.revokeDate).format("DD MMM YYYY")}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 0.5 }}>
                    <strong>Reason:</strong> {post.revokeInfo.revokeReason}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid>
            <Grid
              item
              xs={12}
              sx={{
                "& > p": {
                  ...theme.typography.body1,
                  mb: 0,
                },
              }}
            >
              <ReactMarkdown remarkPlugins={[gfm]}>
                {post.postText}
              </ReactMarkdown>
            </Grid>

            {post.images && post.images.length > 0 && (
              <Grid item xs={12}>
                <ViewUploadedImages postId={post?.id} itemData={post?.images} />
              </Grid>
            )}

            {post?.approvalStatus === "Rejected" && (
              <Grid item md={12} xs={12} mt={2}>
                <Alert variant="filled" severity="error">
                  {post?.approvalNotes}
                </Alert>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this post?
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
            <Box
              sx={{
                bgcolor: "#fff4e5",
                color: "#8c6938",
                padding: 1.5,
                borderRadius: "8px",
              }}
            >
              <WarningAmberIcon sx={{ color: "#ef7918" }} /> This action cannot
              be undone.
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default EventPostDetailsCard;
