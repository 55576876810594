import {
  Autocomplete,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import StudentProfile from "./StudentProfile";
import { useTheme } from '@mui/material/styles';

const StudentList = ({ studentList, setShowStudentList, setStudentList }) => {
  const [departmentList, setDepartmentList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [acdemicSessionId, setAcdemicSessionId] = useState("");

  const [viewProfile, setViewProfile] = useState(false);
  const [studentId, setStudentId] = useState("");

  // State for DataGrid pagination
  const [pageSize, setPageSize] = useState(10); // Default number of rows per page

  const initialValues = {
    studentName: "",
    departmentId: "",
    courseId: "",
    rollNo: "",
    cgpa: "",
    backlogs: false,
  };

  const getAcademicYearList = () => {
    axios
      .get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/session/list`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("external-token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        const activeSession = result.data?.SessionList?.find(
          (s) => s.status === true
        );

        if (activeSession) {
          setAcdemicSessionId(activeSession.id);
        }
      })
      .catch((error) => {
        console.error("Error fetching academic year list:", error);
      });
  };

  const getDepartmentList = () => {
    axios
      .get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/departments/list/by-college`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setDepartmentList(result.data.departmentList);
      });
  };

  const getCourseListByDepartment = (dId, sessionId) => {
    axios
      .get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/course-details/list/by-college-institute?departmentId=${dId}&sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setCourseList(result.data.courseDetailList);
      });
  };

  const handleChangeAuto = (selectedOption, setFieldValue) => {
    if (selectedOption) {
      const departmentId = selectedOption.id;
      getCourseListByDepartment(departmentId, acdemicSessionId);
      setFieldValue("departmentId", departmentId);
    } else {
      setFieldValue("departmentId", "");
    }
  };

  const handleChangeProgram = (selectedOption, setFieldValue) => {
    if (selectedOption) {
      const courseId = selectedOption.courseId;
      setFieldValue("courseId", courseId);
    } else {
      setFieldValue("courseId", "");
    }
  };

  const handleViewStudentProfile = (id) => {
    setStudentId(id);
    setViewProfile(true);
  };

  const fetchStudents = (values) => {
    axios
      .get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/student/get-student-mini-by-course?departmentId=${values?.departmentId}&courseId=${values?.courseId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setStudentList(result.data.data.content);
      });
  };

  function onSubmit(e, values) {
    e.preventDefault();
    fetchStudents(values);
  }

  useEffect(() => {
    getAcademicYearList();
    getDepartmentList();
  }, []);

  const columns = [
    { field: 'slNo.', headerName: 'slNo.', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    {
      field: "studentName",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <Typography
          style={{
            cursor: "pointer",
            borderBottom: "2px solid transparent", // Default transparent border
            transition: "border-bottom 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.target.style.borderBottom = "2px solid black"; // Change to desired color on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.borderBottom = "2px solid transparent"; // Revert to transparent
          }}
          onClick={() => handleViewStudentProfile(params.row.studentId)}
        >
          {params.value}
        </Typography>
      ),
    },
    { field: "rollNo", headerName: "Roll No.", width: 200 },
    { field: "departmentName", headerName: "Department", width: 200 },
    { field: "courseName", headerName: "Program", width: 280 },
  ];

  const rows = studentList.map((student, index) => ({
    id: index + 1,
    ...student,
  }));

  const theme = useTheme()

  return (
    <>
      {viewProfile ? (
        <StudentProfile studentId={studentId} setViewProfile={setViewProfile} />
      ) : (
        <MainCard>
          <Grid item md={12} xs={12} lg={12}>
            <Typography
              variant="h4"
              sx={{
                fontSize: "18px",
                p: 2,
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
              }}
            >
              <span onClick={() => setShowStudentList(false)}>Back</span>
            </Typography>
          </Grid>

          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={[
                        { id: "none", name: "None" },
                        ...departmentList,
                      ]}
                      name="departmentId"
                      id="departmentId"
                      onChange={(event, newValue) => {
                        handleChange(event, setFieldValue);
                        handleChangeAuto(newValue, setFieldValue);
                        setFieldValue("courseId", "");
                      }}
                      getOptionLabel={(option) => option.name.toUpperCase()}
                      renderInput={(params) => (
                        <TextField {...params} label="Department" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={courseList}
                      id="courseId"
                      name="courseId"
                      onChange={(event, newValue) => {
                        handleChange(event, setFieldValue);
                        handleChangeProgram(newValue, setFieldValue);
                      }}
                      getOptionLabel={(option) =>
                        option.course.courseName.toUpperCase()
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Program" />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} lg={4}>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={(e) => onSubmit(e, values)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          {studentList.length > 0 ? (
            <div style={{ height: 400, width: "100%", marginTop: "16px" }}>
              <DataGrid

                sx={{
                  mt: 2,
                  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
                    backgroundColor: 'f13223'
                  },
                  '& .MuiDataGrid-row:nth-child(even)': {
                    backgroundColor: '#f0f0f0' // Set your desired even cell background color
                  },
                  '& .MuiDataGrid-row:nth-child(odd)': {
                    backgroundColor: '#ffffff' // Set your desired odd cell background color
                  },
                  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
                    // backgroundColor: 'red !important'
                  },

                  // eslint-disable-next-line no-dupe-keys
                  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderTop: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f1' : '#313130'}`
                  }

                }}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
              />
            </div>
          ) : (
            <Grid container mt={2}>
              <Grid item md={12} xs={12} lg={12}>
                <Card>
                  <Typography
                    variant="h3"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    No Student Found
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          )}
        </MainCard>
      )}
    </>
  );
};

export default StudentList;
