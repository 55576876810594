import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Card, CardContent, Grid, Typography } from "@mui/material";

// project imports
import { gridSpacing } from "store/constant";
import moment from "moment";

// ==============================|| CHAT MESSAGE HISTORY ||============================== //

const senderColorMap = {};

const getColorForSender = (senderName) => {
  if (!senderColorMap[senderName]) {
    senderColorMap[senderName] = getRandomColor();
  }
  return senderColorMap[senderName];
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ChartHistory = ({ data, theme, user, ref }) => {
  console.log(data, "updatedData");
  return (
    <Grid item xs={12}>
      <Grid container spacing={gridSpacing} ref={ref}>
        {data?.map((history, index) => (
          <React.Fragment key={index}>
            {history.senderId === localStorage.getItem("userId") ? (
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <Card
                      sx={{
                        display: "inline-block",
                        float: "right",
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? "grey.600"
                            : theme.palette.primary.light,
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 2,
                          pb: "16px !important",
                          width: "fit-content",
                          ml: "auto",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h5" sx={{ color: "black" }}>
                            YOU
                          </Typography>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              color={
                                theme.palette.mode === "dark" ? "dark.900" : ""
                              }
                            >
                              {history.content}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              align="right"
                              variant="subtitle2"
                              color={
                                theme.palette.mode === "dark" ? "dark.900" : ""
                              }
                            >
                              {moment(history.timeStamp).format(
                                "MMMM Do YYYY, h:mm:ss A"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>



            ) : (
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} sm={7}>
                    <Card
                      sx={{
                        display: "inline-block",
                        float: "left",
                        background:
                          theme.palette.mode === "dark"
                            ? theme.palette.dark[900]
                            : theme.palette.secondary.light,
                      }}
                    >
                      <CardContent sx={{ p: 2, pb: "16px !important" }}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            sx={{
                              color: getColorForSender(history.senderName),
                            }}
                          >
                            {history?.senderName?.toUpperCase()}
                          </Typography>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              {history?.content}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align="right" variant="subtitle2">
                              {moment(history.timeStamp).format(
                                "MMMM Do YYYY, h:mm:ss A"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

ChartHistory.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.array,
  user: PropTypes.object,
};

export default ChartHistory;
