import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  FormLabel,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";

const SeekingFor = ({ values, handleChange, setFieldValue }) => {
  const handleCheckboxChange = (event, index, type) => {
    const { checked } = event.target;
    const currentTypes = values?.eventAttendeesList || [];

    let updatedAttendeesList = [...currentTypes];

    if (checked) {
      // Add a new object for the selected type
      updatedAttendeesList.push({
        eventAttendeesType: type,
        limit: 0, // Assuming a default value for limit
      });
    } else {
      // Remove the object for the deselected type
      updatedAttendeesList = updatedAttendeesList.filter(
        (attendee) => attendee.eventAttendeesType !== type
      );
    }

    setFieldValue("eventAttendeesList", updatedAttendeesList);
  };

  const handleLimitChange = (event, index) => {
    const { value } = event.target;
    setFieldValue(`eventAttendeesList.${index}.limit`, value);
  };

  return (
    <FieldArray
      name="eventAttendeesList"
      render={(arrayHelpers, index) => (
        <>
          <Grid container direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.eventAttendeesList.some(
                    (attendee) => attendee.eventAttendeesType === "Volunteers"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, index, "Volunteers")
                  }
                  name="Volunteers"
                />
              }
              label="Volunteer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.eventAttendeesList.some(
                    (attendee) => attendee.eventAttendeesType === "Participants"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, index, "Participants")
                  }
                  name="Participants"
                />
              }
              label="Participate"
            />
          </Grid>
          {values?.limitRequired && (
            <Grid container spacing={1} key={index}>
              {values.eventAttendeesList.map((attendee, index) => (
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label={
                      attendee?.eventAttendeesType === "Volunteers"
                        ? "Volunteers Limit"
                        : "Participate Limit"
                    }
                    name={`eventAttendeesList.${index}.limit`}
                    onChange={(event) => handleLimitChange(event, index)}
                    value={attendee.limit}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    />
  );
};

export default SeekingFor;
