import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";

const UploadAttachment = ({
  attachments,
  index,
  setFieldValue,
  height = "150px",
  backgroundColor = "#fff",
  hoverLabel = "Click or drag to upload Attachment.",
  dropLabel = "Drop files here.",
}) => {
  const [labelText, setLabelText] = useState(hoverLabel);
  const [isDragOver, setIsDragOver] = useState(false);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      handleFiles(e.dataTransfer.files);
    },
  };

  const handleChange = (event) => {
    handleFiles(event.target.files);
  };

  const handleFiles = async (files) => {
    const selectedFiles = Array.from(files); // No longer assuming event.target.files
    const uploadedPaths = [];

    for (const file of selectedFiles) {
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        try {
          const response = await uploadChunk(file, chunk, i, totalChunks);
          if (i === totalChunks - 1) {
            const uploadedFilePath = response.data?.data?.filePath;
            const preSignedUrl = response.data?.data?.preSignedUrl;

            uploadedPaths.push(uploadedFilePath);
          }
        } catch (error) {
          console.error("Error uploading chunk:", error);
          break;
        }
      }
    }

    // Append the new uploaded file paths to the correct achievement
    setFieldValue(`achievements[${index}].attachments`, [
      ...attachments,
      ...uploadedPaths,
    ]);
  };

  const CHUNK_SIZE = 1 * 900 * 900;
  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const uploadChunk = async (file, chunk, chunkIndex, totalChunks) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("fileName", file.name);

    return axios.post("/v1/upload", formData, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
        "Content-Type": "multipart/form-data",
        requestId: ConvertToRequestId(
          Math.floor(1000 + Math.random() * 9999),
          Math.floor(1000 + Math.random() * 9999) + rendomRequestId
        ),
      },
    });
  };

  return (
    <Box
      border={`2px dashed ${isDragOver ? "blue" : "#aaa"}`}
      borderRadius="8px"
      padding="16px"
      textAlign="center"
      height={height}
      bgcolor={backgroundColor}
      {...dragEvents}
      sx={{ overflow: "auto" }}
    >
      <input
        onChange={handleChange}
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        style={{ display: "none" }}
        id={`file-upload-${index}`}
        type="file"
        multiple
      />
      <label htmlFor={`file-upload-${index}`}>
        <CloudUploadIcon fontSize="large" />
        <Typography>{labelText}</Typography>
      </label>

      <div
        style={{
          flexWrap: "wrap",
          gap: "10px",
          overflowY: "auto",
          marginTop: "10px",
        }}
      >
        {attachments.map((fileObj, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
              marginTop: "5px",
              height: "40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>{fileObj?.filePath}</Typography>
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
              }}
            >
              <IconButton
                onClick={() => {
                  const updatedAttachments = [...attachments];
                  updatedAttachments.splice(i, 1);
                  setFieldValue(
                    `achievements[${index}].attachments`,
                    updatedAttachments
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default UploadAttachment;
