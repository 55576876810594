import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import SubCard from "ui-component/cards/SubCard";

const WorkingDetailsCard = ({
  alumniDetails,
  handleNavigateWorkingStatus,
  handleNavigate,
  IsConnection,
}) => {
  return (
    <>
      <SubCard
        sx={{
          p: 1,
          mt: 2,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ffffff",
          },
        }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <InfoIcon sx={{ marginRight: 1 }} /> */}
              <span style={{ color: "#245081", fontSize: "18px" }}>
                Work Experience
              </span>
            </Typography>
          </div>
        }
        secondary={
          <>
            {!IsConnection && (
              <>
                {alumniDetails?.workingDetails !== null && (
                  <div style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        handleNavigateWorkingStatus(alumniDetails?.id)
                      }
                      height="20px"
                      alt="icon"
                      src="/assets/images/icons/addIcon.svg"
                    />
                    <img
                      height="20px"
                      style={{ marginLeft: "20px" }}
                      onClick={() => handleNavigate(alumniDetails?.id)}
                      alt="icon"
                      src="/assets/images/icons/editIcon.svg"
                    />
                  </div>
                )}
              </>
            )}
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          {alumniDetails?.workingDetails === null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              {!IsConnection ? (
                <img
                  height="40px"
                  alt="icon"
                  src="/assets/images/icons/addPlus.svg"
                />
              ) : (
                "No Working Deatails Provided"
              )}
            </div>
          ) : (
            <>
              {alumniDetails?.workingDetails?.map((data) => (
                <Grid item xs={12}>
                  <Grid
                    container
                    sx={{
                      border: "1px solid #E2E5EA",
                      p: 1,
                      borderRadius: "10px",
                    }}
                  >
                    <Grid item md={8} xs={6}>
                      <Typography variant="subtitle1">
                        {data?.designation}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        {data?.organizationName}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Typography
                        variant="primary"
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        {moment(data?.joiningDate).format("MMMM-YYYY")} -
                        {data?.endDate
                          ? moment(data?.endDate).format("MMMM-YYYY")
                          : "Current"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Box>
        </Grid>
      </SubCard>
    </>
  );
};

export default WorkingDetailsCard;
