import {
  Avatar,
  Button,
  ButtonBase,
  Collapse,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import MainCard from "ui-component/cards/MainCard";
import { useTheme } from "@emotion/react";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import axios from "axios";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import useRequestIdGenerater from "customhooks";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VerifiedIcon from "@mui/icons-material/Verified";
import CommentList from "./CommentList";
import ViewUploadedImages from "./ViewUploadedImages";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import LikeList from "./LikeList";
import PublicIcon from "@mui/icons-material/Public";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupsIcon from "@mui/icons-material/Groups";
import EventDetails from "./EventDetails";
import moment from "moment";
import "./likejump.css";
import { SendOutlined, ShareOutlined } from "@mui/icons-material";

const likeArray = [
  {
    type: "Like",
    emoji: "👍",
  },
  {
    type: "Celebrate",
    emoji: "👏",
  },
  {
    type: "Funny",
    emoji: "😄",
  },
  {
    type: "Love",
    emoji: "❤️",
  },
];

const FormInput = ({
  bug,
  label,
  size,
  fullWidth = true,
  name,
  required,
  ...others
}) => {
  let isError = false;
  let errorMessage = "";
  if (bug && Object.prototype.hasOwnProperty.call(bug, name)) {
    isError = true;
    errorMessage = bug[name].message;
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue=""
        render={({ field }) => (
          <TextField
            fullWidth={fullWidth}
            size={size}
            label={label}
            InputLabelProps={{
              className: required ? "required-label" : "",
              required: required || false,
            }}
            error={isError}
            {...field}
          />
        )}
        {...others}
      />
      {errorMessage && (
        <Grid item xs={12}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

const PostDetails = ({ post, setPosts, alumniDetails, isLoading }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentlist, setCommentList] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

  const [openComment, setOpenComment] = React.useState(false);
  const [openLikeList, setOpenLikeList] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [jumpingEmoji, setJumpingEmoji] = useState(null);

  const handleEmojiClick = (emojiType) => {
    setJumpingEmoji(emojiType);
    setTimeout(() => {
      setJumpingEmoji(null);
    }, 700);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenLikeList = () => {
    setOpenLikeList(true);
  };

  const handleCloseLikeList = () => {
    setOpenLikeList(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlePostLikes = async (id, type, like) => {
    handleEmojiClick(type);
    try {
      const response = await axios.get(
        `/v1/posts/like?like=${like}&postId=${id}&reactionType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setPosts(response?.data?.data);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const getCommentList = async (id) => {
    try {
      const response = await axios.get(
        `/v1/posts/list/comment?postId=${id}&pageSize=200&pageNumber=0`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setCommentList(response?.data?.data?.content);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleChangeComment = () => {
    setOpenComment((prev) => !prev);
    getCommentList(post?.id);
  };

  let commentsResult = <></>;

  if (commentlist) {
    commentsResult = commentlist.map((comment) => (
      <CommentList
        setPosts={setPosts}
        postId={post?.id}
        comment={comment}
        key={comment.id}
        openComment={openComment}
        getCommentList={getCommentList}
      />
    ));
  }

  useEffect(() => {
    generateTimeStamp();
  }, []);

  const methods = useForm({
    // resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const onSubmit = async (comment) => {
    // handleChangeComment();
    const payload = {
      comment: comment?.name,
      commentId: "",
    };
    try {
      const response = await axios.post(
        `/v1/posts/add/comment?postId=${
          post?.id
        }&alumniId=${localStorage.getItem("alumniId")}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setPosts(response?.data?.data);
      getCommentList(post?.id);
      reset();
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  return (
    <MainCard>
      {isLoading ? (
        <>
          <AnimatedLoader />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar
                    alt="User 1"
                    src={`data:image/*;base64, ${post?.alumniMemberMini?.avatar?.data}`}
                    sx={{
                      border: post?.alumniMemberMini?.starAlumni
                        ? "3px solid #D89E46"
                        : "none",
                    }}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography
                        align="left"
                        variant="h5"
                        component="div"
                        sx={{
                          display: "inline-flex", // Align name and icon on the same line
                          alignItems: "center", // Vertically align the icon with the text
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {post?.alumniMemberMini
                          ? post?.alumniMemberMini?.name
                          : post?.createdByName}
                        {post?.alumniMemberMini?.starAlumni && (
                          <VerifiedIcon
                            sx={{
                              color: "#D89E46",
                              fontSize: "18px",
                              marginLeft: "4px",
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {post?.postsFor === "EveryOne" ? (
                        <PublicIcon />
                      ) : post?.postsFor === "Batch" ? (
                        <WorkspacesIcon />
                      ) : (
                        <GroupsIcon />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography align="left" variant="caption">
                        {moment(post.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>

            <Grid>
              <Grid
                item
                xs={12}
                p={1}
                sx={{
                  "& > p": {
                    ...theme.typography.body1,
                    mb: 0,
                  },
                }}
              >
                <ReactMarkdown remarkPlugins={[gfm]}>
                  {post.postText}
                </ReactMarkdown>
              </Grid>

              {post.images && post.images.length > 0 && (
                <Grid item xs={12}>
                  <ViewUploadedImages
                    postId={post?.id}
                    itemData={post?.images}
                  />
                </Grid>
              )}

              {post?.eventPost && (
                <Grid mt={2}>
                  <EventDetails
                    setShowConfetti={setShowConfetti}
                    setPosts={setPosts}
                    showConfetti={showConfetti}
                    post={post}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    mt: 0,
                    color:
                      theme.palette.mode === "dark" ? "grey.700" : "grey.800",
                  }}
                >
                  <Grid item>
                    {post?.reactionCount && (
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                        }}
                      >
                        {Object.entries(post?.reactionCount).map(
                          ([key, value]) => {
                            const emojiItem = likeArray.find(
                              (item) => item.type === key
                            );
                            return (
                              <div
                                key={key}
                                className={
                                  jumpingEmoji === emojiItem?.type
                                    ? "jumping"
                                    : ""
                                }
                                onClick={handleOpenLikeList}
                                onMouseEnter={(e) => {
                                  e.target.style.borderBottom =
                                    "1px solid black";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.borderBottom =
                                    "1px solid transparent";
                                }}
                              >
                                {emojiItem?.emoji}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}

                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="text"
                        onClick={() =>
                          handlePostLikes(
                            post?.id,
                            "Like",
                            !post?.postLikesIds?.includes(
                              localStorage.getItem("userId")
                            )
                              ? true
                              : false
                          )
                        }
                        color="inherit"
                        size="small"
                        onMouseEnter={handlePopoverOpen}
                        //   onMouseLeave={handlePopoverClose} // Close popover when mouse leaves the button
                      >
                        <ThumbUpAltTwoToneIcon
                          color={
                            post?.postLikesIds?.includes(
                              localStorage.getItem("userId")
                            )
                              ? "primary"
                              : "inherit"
                          }
                        />
                        {post?.likes}
                        <Typography
                          color="inherit"
                          sx={{
                            fontWeight: 500,
                            ml: 0.5,
                            display: { xs: "none", sm: "block" },
                          }}
                        >
                          likes
                        </Typography>
                      </Button>
                      {!post?.postLikesIds?.includes(
                        localStorage.getItem("userId")
                      ) && (
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onMouseLeave={handlePopoverClose}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          disableRestoreFocus
                        >
                          <Stack direction="row" spacing={1} p={1}>
                            {likeArray?.map((like, index) => (
                              <Typography
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  fontWeight: isHovered ? 500 : 400,
                                }}
                                variant={isHovered ? "h1" : "h3"}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={() =>
                                  handlePostLikes(
                                    post?.id,
                                    like?.type,
                                    !post?.postLikesIds?.includes(
                                      localStorage.getItem("userId")
                                    )
                                      ? true
                                      : false
                                  )
                                }
                              >
                                {like.emoji}
                              </Typography>
                            ))}
                          </Stack>
                        </Popover>
                      )}
                      <Button
                        onClick={handleChangeComment}
                        size="small"
                        variant="text"
                        color="inherit"
                        startIcon={<ChatBubbleTwoToneIcon color="secondary" />}
                      >
                        {post?.comments} comments
                      </Button>
                    </Stack>
                  </Grid>

                  {openLikeList && (
                    <LikeList
                      post={post}
                      postId={post?.id}
                      open={openLikeList}
                      handleClose={handleCloseLikeList}
                    />
                  )}
                </Grid>
              </Grid>

              <Collapse in={openComment} sx={{ width: "100%" }}>
                {openComment && (
                  <Grid item xs={12} sx={{ pt: 2 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid
                          item
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <Avatar
                            sx={{ mt: 0.75 }}
                            alt="User 1"
                            src={`data:image/*;base64, ${alumniDetails?.avatar?.data}`}
                            size="xs"
                          />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                          <FormProvider {...methods}>
                            <FormInput
                              fullWidth
                              name="name"
                              label="Write a comment..."
                              size={matchesXS ? "small" : "medium"}
                              bug={errors}
                            />
                          </FormProvider>
                        </Grid>
                        <Grid item>
                          {/* <AnimateButton> */}
                          <IconButton
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size={matchesXS ? "small" : "large"}
                            sx={{ mt: 0.5 }}
                          >
                            <SendOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                )}
              </Collapse>
              {commentsResult}
            </Grid>
          </Grid>
          {post?.aiChecked && (
            <Grid display="flex" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" flex="end" spacing={1}>
                <AutoAwesomeIcon sx={{ color: "#4d8ffd" }} />
                <Typography variant="caption">Verified by AI</Typography>
              </Stack>
            </Grid>
          )}
        </>
      )}
    </MainCard>
  );
};

export default PostDetails;
