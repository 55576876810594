import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import useConfig from "hooks/useConfig";
// import Carousel, { Modal, ModalGateway } from "react-images";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import Carousel, { Modal, ModalGateway } from "react-images";

const ViewUploadedImages = ({ itemData, postId }) => {
  const { borderRadius } = useConfig();
  const [clickedImages, setClickedImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleImageClick = (index) => {
    getPresignedUrl(index);
  };

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const chunkedData = chunkArray(itemData, 5);

  const getPresignedUrl = async (index) => {
    try {
      const response = await axios.get(`/v1/posts/files?postId=${postId}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      const formattedImages = response?.data?.data?.presignedUrl?.map(
        (item) => ({
          src: item,
          caption: "caption",
        })
      );
      console.log(formattedImages, "formattedImages")
      setClickedImages(formattedImages);
      setCurrentImageIndex(index);
      setOpenModal(true);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const getGridStyle = (count) => {
    switch (count) {
      case 1:
        return { gridTemplateColumns: '1fr', height: '300px' };
      case 2:
        return { gridTemplateColumns: '1fr 1fr', gap: '8px', height: '300px' };
      case 3:
        return {
          gridTemplateColumns: '1fr 1fr',
          gap: '8px',
          gridTemplateAreas: "'a b' 'a c'",
          height: '300px'
        };
      case 4:
        return { gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr', gap: '8px', height: '300px' };
      default:
        return { height: '300px' };
    }
  };

  const getImageArea = (count, index) => {
    if (count === 3) {
      const areas = ['a', 'b', 'c'];
      return areas[index];
    }
    return undefined;
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'grid',
            ...getGridStyle(itemData.length),
          }}
        >
          {itemData.map((item, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: `${borderRadius}px`,
                cursor: 'pointer',
                gridArea: getImageArea(itemData.length, index),
              }}
              onClick={() => handleImageClick(index)}
            >
              <img
                src={`data:image/*;base64, ${item.data}`}
                alt={item.title}
                loading="lazy"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          ))}
        </Grid>
        <ModalGateway>
          {openModal ? (
            <Modal onClose={handleCloseModal}>
              <Carousel
                views={clickedImages}
                currentIndex={currentImageIndex}
                onClose={handleCloseModal}
              />

            </Modal>
          ) : null}
        </ModalGateway>

      </Grid>

    </>
  );
};

ViewUploadedImages.propTypes = {
  itemData: PropTypes.array.isRequired,
};

export default ViewUploadedImages;
