import { Button, Grid, Typography } from "@mui/material";

import React from "react";

import { gridSpacing } from "store/constant";

import MainCard from "ui-component/cards/MainCard";

const NoAvailableData = ({ text }) => {
  return (
    <MainCard>
      <Grid
        container
        spacing={gridSpacing}
        sx={{
          padding: 10,
        }}
      >
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/assets/images/icons/Alumni.svg"
            alt="asset"
            // style={{ width: "40%" }}
          />
        </Grid>

        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4">{text}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </>
      </Grid>
    </MainCard>
  );
};

export default NoAvailableData;
