import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import LoaderButton from "reuseableTable/LoaderButton";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import MainCard from "ui-component/cards/MainCard";
import EventInfo from "./EventInfo";
import InviteTo from "./InviteTo";
import PaymentInfo from "./PaymentInfo";
import SeekingFor from "./SeekingFor";
import EventCover from "./EventCover";
import * as yup from "yup";
import EventLocation from "./EventLocation";

const Add = ({ handleChange, type = "Admin" }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventTypelist, setEventTypeList] = useState([]);
  const [eventMode, setEventMode] = useState("Online");
  const [imageArray, setImageArray] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedBatchs, setSelectedBatchs] = useState([]);

  const [selectedPlace, setSelectedPlace] = useState("");

  const [grouplistforAlumni, setGroupListforAlumni] = useState(false);

  const formikRef = useRef();

  const RequestId = useRequestIdGenerater();
  const { generateTimeStamp, rendomRequestId } = useTimeStamp();

  const handleEventModeChange = (e) => {
    setEventMode(e.target.value);
  };

  const getEventType = async () => {
    try {
      const response = await axios.get(`/v1/master/event/category/list`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      const filteredEventTypes = response?.data?.data.filter(
        (eventType) => eventType.status === true
      );

      setEventTypeList(filteredEventTypes);
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: "Something went wrong",
          variant: "alert",
          alert: {
            color: "error",
          },
          close: false,
        })
      );
    }
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required. "),
    eventSpace: yup.string().required("Event Space is required."),
    eventCategoryId: yup.string().required("Event Category is required."),
    startDate: yup.string().required("Start Date is required."),
    startTime: yup.string().required("Start Time is required."),
    endDate: yup.string().required("End Date is required."),
    endTime: yup.string().required("End Time is required."),
  });

  const alumniIdValue =
    type !== "Admin" ? localStorage.getItem("alumniId") : "";

  useEffect(() => {
    if (type !== "Admin") {
      setGroupListforAlumni(true);
    } else {
      setGroupListforAlumni(false);
    }
  }, [type]);

  const initialValues = {
    id: "",
    alumniId: alumniIdValue,
    title: "",
    eventCategoryId: "",
    categoryName: "",
    eventType: eventMode,
    eventSpace: "",
    eventFor: "",
    eventForIds: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",
    startTime: moment().format("HH:mm"),
    endTime: "",
    limitRequired: false,
    eventAttendeesList: [
      {
        eventAttendeesType: "Volunteers",
        limit: 0,
      },
    ],
    instruction: "",
    funding: false,
    requiredParticipantFee: false,
    participantFee: 0,
  };

  const handleSubmit = async (values) => {
    if (values.eventCategoryId === "Other") {
      values.eventCategoryId = "";
    }

    setIsSubmitting(true);
    const formData = new FormData();

    const jsonBlob = new Blob([JSON.stringify(values)], {
      type: "application/json",
    });

    formData.append("requestBody", jsonBlob);
    formData.append("file", imageArray[0]?.file);

    try {
      const response = await axios.post(`/v1/event/add-update`, formData, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "multipart/form-data",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setIsSubmitting(false);
      handleChange();
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error uploading data:", error);
    }
  };

  useEffect(() => {
    generateTimeStamp();
    getEventType();
  }, []);

  const handlePlaceSelected = (placeName) => {
    // setSelectedPlace(placeName);
    formikRef.current.setFieldValue("eventSpace", placeName);
  };

  return (
    <MainCard>
      <Grid container>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ values, handleChange, setFieldValue, touched, errors }) => (
            <Form>
              <Grid
                container
                // spacing={1}
                sx={{
                  mt: 2,
                  margin: "auto",
                }}
              >
                <Grid item md={12} xs={12}>
                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        p: 1,
                      }}
                    >
                      <EventCover
                        setImageArray={setImageArray}
                        setValue={setFieldValue}
                        onChange={handleChange}
                        imageArray={imageArray}
                      />
                    </Grid>
                  </Grid>
                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Event Category :
                      </Typography>
                    </Grid>
                    <Grid container md={9} xs={9} spacing={1}>
                      <>
                        <FormControl
                          fullWidth
                          error={
                            touched.eventCategoryId &&
                            Boolean(errors.eventCategoryId)
                          }
                        >
                          <InputLabel id="event-category-select-label">
                            Event Category
                          </InputLabel>
                          <Select
                            labelId="event-category-select-label"
                            id="eventCategoryId"
                            name="eventCategoryId"
                            value={values?.eventCategoryId}
                            onChange={handleChange}
                            label="Event Category"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {eventTypelist?.map((data) => (
                              <MenuItem key={data?.id} value={data?.id}>
                                {data?.name}
                              </MenuItem>
                            ))}
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                          {touched.eventCategoryId &&
                            errors.eventCategoryId && (
                              <FormHelperText>
                                {errors.eventCategoryId}
                              </FormHelperText>
                            )}
                        </FormControl>

                        {values?.eventCategoryId === "Other" && (
                          <Grid md={12} xs={12} lg={12} sx={{ mt: 1 }}>
                            <TextField
                              fullWidth
                              type="text"
                              id="name"
                              label="Event Category Name"
                              name="categoryName"
                              value={values?.categoryName}
                              // error={touched.title && Boolean(errors.title)}
                              // helperText={touched.title && errors.title}
                              onChange={handleChange}
                            />
                          </Grid>
                        )}
                      </>
                    </Grid>
                  </Grid>

                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Event Mode :
                      </Typography>
                    </Grid>
                    <Grid container md={9} xs={9} spacing={1}>
                      {selectedPlace && (
                        <div>
                          <h2>Selected Place:</h2>
                          <p>{selectedPlace}</p>
                        </div>
                      )}
                      <Grid
                        container
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item md={12} xs={12}>
                          <FormControlLabel
                            value="Online"
                            control={
                              <Radio
                                checked={eventMode === "Online"}
                                onChange={handleEventModeChange}
                              />
                            }
                            label="Online"
                            style={{ fontWeight: "bold" }}
                          />
                          <FormControlLabel
                            value="Offline"
                            control={
                              <Radio
                                checked={eventMode === "Offline"}
                                onChange={handleEventModeChange}
                              />
                            }
                            label="Offline"
                            style={{ fontWeight: "bold" }}
                          />
                        </Grid>

                        {eventMode !== "Offline" ? (
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              label={
                                eventMode === "Online"
                                  ? "Event Link"
                                  : "Event Location"
                              }
                              margin="normal"
                              name="eventSpace"
                              value={values?.eventSpace}
                              error={
                                touched.eventSpace && Boolean(errors.eventSpace)
                              }
                              helperText={
                                touched.eventSpace && errors.eventSpace
                              }
                              type="text"
                              onChange={handleChange}
                            />
                          </Grid>
                        ) : (
                          <Grid item md={12} xs={12}>
                            <EventLocation
                              onPlaceSelected={handlePlaceSelected}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Event Info :
                      </Typography>
                    </Grid>
                    <Grid container md={9} xs={9} spacing={1}>
                      <EventInfo
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Grid>
                  </Grid>

                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Invite to :
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      md={9}
                      xs={9}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <InviteTo
                        alumngroup={grouplistforAlumni}
                        setSelectedBatchs={setSelectedBatchs}
                        setSelectedGroups={setSelectedGroups}
                        selectedBatchs={selectedBatchs}
                        selectedGroups={selectedGroups}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Grid>
                  </Grid>

                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Seeking for :
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      md={9}
                      xs={9}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <SeekingFor
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Grid>
                  </Grid>

                  <Grid container md={10} xs={10} p={1}>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", p: 2, fontWeight: 500 }}
                      >
                        Payment Info :
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      md={9}
                      xs={9}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <PaymentInfo
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item md={4} xs={12}>
                  <EventCard imageArray={imageArray} values={values} />
                </Grid> */}
              </Grid>

              <LoaderButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Grid>
    </MainCard>
  );
};

export default Add;
