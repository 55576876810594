import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const BroadCasteList = Loadable(
    lazy(() => import("views/broadCast/index"))
);
const BroadCasteEdit = Loadable(
    lazy(() => import("views/broadCast/Edit"))
);

// ==============================|| AUTH ROUTING ||============================== //

const AnnouncementRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "/announcements",
            element: <BroadCasteList />,
        },
        {
            path: "/edit/announcement/:id",
            element: <BroadCasteEdit />,
        },


    ],
};

export default AnnouncementRoutes;
