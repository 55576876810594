import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { Typography, Grid } from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";

const GroupMultiSelectforAlumni = ({ onChange, value, label, setFieldValue }) => {
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [groupList, setGroupList] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const fetchGroupList = async () => {
    try {
      const response = await axios.get(
        `/v1/communication/group/my/list?pageNumber=0&pageSize=100`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );
      const data = response?.data?.data?.content;
      setGroupList(data);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      if (!error.response) {
        // Handle error appropriately
      } else {
        // Handle error appropriately
      }
    }
  };
  useEffect(() => {
    fetchGroupList();
  }, []);

  useEffect(() => {
    if (groupList?.length > 0 && value) {
      const selected = groupList?.filter((batch) => value.includes(batch.id));
      setSelectedItems(selected);
      setFieldValue(
        "eventForIds",
        selected.map((batch) => batch.id)
      );
    }
  }, [groupList, value, setFieldValue]);

  const handleCheckboxChange = (event, option) => {
    const selectedIndex = selectedItems.findIndex(
      (item) => item.id === option.id
    );

    let newSelectedItems;
    if (selectedIndex === -1) {
      newSelectedItems = [...selectedItems, option];
    } else {
      newSelectedItems = selectedItems.filter((item) => item.id !== option.id);
    }
    setSelectedItems(newSelectedItems);
    if (onChange) onChange(newSelectedItems.map((item) => item.id));
    setFieldValue(
      "eventForIds",
      newSelectedItems.map((item) => item.id)
    );
  };

  const renderOption = (props, option) => (
    <li {...props}>
      <Checkbox
        checked={selectedItems.some((item) => item.id === option.id)}
        onChange={(event) => handleCheckboxChange(event, option)}
      />
      <Typography>{option.name}</Typography>
    </li>
  );

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <Autocomplete
      multiple
      id="group-multi-select"
      options={groupList}
      disableCloseOnSelect
      value={selectedItems}
      onChange={(_, newValue) => {
        setSelectedItems(newValue);
        if (onChange) onChange(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label || "Group"} />
      )}
    />
  );
};

GroupMultiSelectforAlumni.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
};

export default GroupMultiSelectforAlumni;
