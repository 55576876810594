import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

const EventForm = ({
  open,
  handleClose,
  eventName,
  setEventName,
  eventdate,
  setEventDate,
  eventTime,
  setEventTime,
  setEventType,
  eventLocation,
  setEventLocation,
  eventType,
  eventLink,
  setEventLink,
}) => {
  const handleEventTypeChange = (e) => {
    setEventType(e.target.value);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 500 }} variant="h3">
                Create an event
              </Typography>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Event Name *"
                margin="normal"
                name="name"
                value={eventName}
                type="text"
                onChange={(e) => setEventName(e.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Event Date *"
                margin="normal"
                name="name"
                value={eventdate}
                type="date"
                onChange={(e) => setEventDate(e.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Event Time *"
                margin="normal"
                name="name"
                value={eventTime}
                type="time"
                onChange={(e) => setEventTime(e.target.value)}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControlLabel
                value="Online"
                control={
                  <Radio
                    checked={eventType === "Online"}
                    onChange={handleEventTypeChange}
                  />
                }
                label="Online"
                style={{ fontWeight: "bold" }}
              />
              <FormControlLabel
                value="Offline"
                control={
                  <Radio
                    checked={eventType === "Offline"}
                    onChange={handleEventTypeChange}
                  />
                }
                label="Offline"
                style={{ fontWeight: "bold" }}
              />
            </Grid>

            {eventType === "Online" && (
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Event Link *"
                  margin="normal"
                  name="name"
                  value={eventLink}
                  type="text"
                  onChange={(e) => setEventLink(e.target.value)}
                />
              </Grid>
            )}
            {eventType === "Offline" && (
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Event Location *"
                  margin="normal"
                  name="name"
                  value={eventLocation}
                  type="text"
                  onChange={(e) => setEventLocation(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            md={12}
            xs={12}
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 10,
              "& > :not(:first-child)": { ml: 2 },
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                fontWeight: 450,
                width: 60,
              }}
            >
              Back
            </Button>

            <Button
              onClick={handleClose}
              variant="contained"
              disabled={eventName === ""}
              sx={{
                fontWeight: 450,
                width: 60,
              }}
            >
              Done
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventForm;
