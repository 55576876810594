import React, { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import UpcomingEvents from "./UpcomingEvents";
import Add from "views/createEvent/add/Add";

export default function ManageEvent() {
  const [isDisplay, setIsDisplay] = useState(false);
  const handleChange = () => {
    setIsDisplay(!isDisplay);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          content={false}
          title={!isDisplay ? "Events" : "Create Events"}
          secondary={
            !isDisplay ? (
              <Button variant="contained" size="small" onClick={handleChange}>
                <IconButton size="small">
                  <AddCircleOutlineIcon />
                </IconButton>
                Create
              </Button>
            ) : (
              <Button variant="contained" size="small" onClick={handleChange}>
                <IconButton size="small">
                  <ArrowBackIcon />
                </IconButton>
                Back
              </Button>
            )
          }
        >
          {isDisplay ? (
            <Add type="alumni" handleChange={handleChange} />
          ) : (
            <UpcomingEvents handleChange={handleChange} />
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
}
