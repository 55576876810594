import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavigateBackButton = ({ name }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontWeight: 400, fontSize: "20px", color: "black" }}>
          <IconButton color="inherit">
            <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
          </IconButton>
          {name}
        </Typography>
      </Grid>
    </>
  );
};

export default NavigateBackButton;
