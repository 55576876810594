import React from "react";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import InfoIcon from "@mui/icons-material/Info";

const StarAlumniCard = ({ alumni }) => {
  return (
    <>
      {/* Star Alumni Card */}
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          boxShadow: 3,
          padding: 3,
          marginBottom: 4,
        }}
      >
        <CardMedia
          component="img"
          image={alumni?.largeAvatar}
          alt="Star Alumni"
          sx={{
            width: { xs: 120, sm: 150 },
            height: { xs: 120, sm: 150 },
            borderRadius: "50%",
            marginBottom: { xs: 2, sm: 0 },
            marginRight: { sm: 3 },
          }}
        />
        <CardContent>
          <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
            What is a Star Alumni <SchoolIcon /> ?
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: 2, textAlign: { xs: "center", sm: "left" } }}
          >
            Star Alumni are exceptional individuals who have made significant
            contributions in their fields, inspired others, and brought pride
            to our institution. By becoming a Star Alumni, your achievements
            will be showcased, inspiring current students and alumni.
          </Typography>
        </CardContent>
      </Card>

      {/* Benefits Section */}
      <Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: 3,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <InfoIcon sx={{ color: "#1976d2", marginRight: 1 }} />
          Benefits of Being a Star Alumni
        </Typography>
        <Box sx={{ paddingLeft: { xs: 0, sm: 2 } }}>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            🌟 Recognition on the Alumni Page.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            🌟 Special Invitations to Alumni Events.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            🌟 A Platform to Share Your Success Story.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default StarAlumniCard;
