import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";

const GroupMemberDetails = ({ open, onClose, groupDetailId }) => {
  const [pageData, setPageData] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const fetchDetails = async (values) => {
    try {
      const response = await axios.get(
        `/v1/communication/group/list/group/member?groupId=${groupDetailId}&pageNumber=0&pageSize=200`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );
      setPageData(response?.data?.data?.content);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = pageData.filter((data) =>
    data.alumniMemberMini?.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">Members</DialogTitle> */}

      <DialogContent sx={{ width: 380, height: 350 }}>
        <Grid item md={12} xs={12} p={2}>
          <TextField
            label="Search by name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
          />
        </Grid>
        <TableContainer>
          <Table>
            <TableBody>
              {filteredData.map((data) => (
                <TableRow key={data.id}>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <Avatar
                        alt={data.name}
                        src={`data:image/*;base64, ${data?.alumniMemberMini?.avatar?.data}`}
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <p style={{ fontWeight: "bold", margin: "0" }}>
                          {data.alumniMemberMini?.name}
                        </p>
                        <p style={{ margin: "0" }}>
                          {data?.alumniMemberMini?.email}
                        </p>
                      
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupMemberDetails;
