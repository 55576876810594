/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, useCallback } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import AlumniCards from "./AlumniCards";
import NoAvailableData from "reuseableTable/NoAvailableData";
import ProfilePage from "views/alumniRegistration/alumniProfile/profilePage";
import { IconSearch } from "@tabler/icons";
import AnimatedLoader from "reuseableTable/AnimatedLoader";

const AlumniListByBatch = () => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  const [batchList, setBatchList] = useState([]);
  const [alumniData, setAlumniData] = useState([]);
  const [batchId, setBatchId] = useState("");

  const [page, setPage] = useState(0);
  const [pageCache, setPageCache] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  const [viewProfile, setViewProfile] = useState(false);
  const [alumniIde, setAlumniId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingBatchList, setLoadingBatchList] = useState(false);

  const [connectionInfo, setConnectionInfo] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getBatchList = () => {
    axios
      .get(`/v1/batch/list`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      })
      .then((response) => {
        setBatchList(response?.data?.BatchList);

        generateTimeStamp();
      })
      .catch((error) => {
        console.error("Failed to fetch batch list:", error);
      });
  };

  const handleBatchChange = () => {
    setLoadingBatchList(true);

    if (batchId === "") return;

    const payload = {
      search,
    };
    axios
      .post(
        `/v1/alumni/by-batch?pageNumber=${page}&pageSize=${rowsPerPage}&batchId=${
          batchId ?? localStorage.getItem("alumniBatchId")
        }`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      )
      .then((response) => {
        const alumniId = localStorage.getItem("alumniId");
        const originalData = response?.data?.data?.content;
        const updatedData = originalData.filter((item) => item.id !== alumniId);

        setAlumniData(updatedData);

        // Check if the filtered out item is in the original data
        if (
          response?.data?.data?.content.some((item) => item.id === alumniId)
        ) {
          setTotalCount(response?.data?.data?.totalElements - 1);
        } else {
          setTotalCount(response?.data?.data?.totalElements);
        }

        setLoadingBatchList(false);
        generateTimeStamp();
      })

      .catch((error) => {
        setLoadingBatchList(false);
        console.error("Failed to fetch batch list:", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => { 
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageCache({});
  };

  const handleViewProfile = (id) => {
    setViewProfile(true);
    setAlumniId(id);
  };
  const getConnectionDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/alumni/profile?userId=${alumniIde}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setConnectionInfo(response?.data?.data);
      generateTimeStamp();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (pageCache[page]) {
      setAlumniData(pageCache[page]);
    } else {
      handleBatchChange();
    }
  }, [page, rowsPerPage, batchId, search]);

  useEffect(() => {
    setBatchId(localStorage.getItem("alumniBatchId"));
    getBatchList();
    generateTimeStamp();
  }, []);

  useEffect(() => {
    getConnectionDetails();
  }, [alumniIde]);

  const handleSearch = (event) => {
    const newString = event.target.value;
    setSearch(newString);
    handleBatchChange();
  };

  return (
    <MainCard>
      {viewProfile ? (
        <>
          <Grid sx={{ mt: 2 }}>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => setViewProfile(false)}
            >
              Back
            </Button>
            <Grid sx={{ mt: 1 }}>
              <ProfilePage
                setViewProfile={setViewProfile}
                IsConnection={true}
                isLoading={isLoading}
                alumniDetails={connectionInfo}
                getAlumniDetails={getConnectionDetails}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={gridSpacing}>
            <Grid item md={4} lg={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="batch-selector">Select Batch</InputLabel>
                <Select
                  label="Select Batch"
                  id="Select Batch"
                  value={batchId}
                  onChange={(event) => {
                    setBatchId(event.target.value);
                  }}
                >
                  {batchList?.map((tab) => (
                    <MenuItem key={tab?.id} value={tab?.id}>
                      {tab.tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={8} lg={8} xs={12}>
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={search}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {loadingBatchList && <AnimatedLoader />}
          {alumniData?.length > 0 ? (
            <AlumniCards
              handleViewProfile={handleViewProfile}
              page={page}
              totalCount={totalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              pageCache={pageCache}
              rowsPerPage={rowsPerPage}
              search={search}
              setSearch={setSearch}
              setRowsPerPage={setRowsPerPage}
              alumniData={alumniData}
              setAlumniData={setAlumniData}
              setPageCache={setPageCache}
              handleBatchChange={handleBatchChange}
            />
          ) : (
            !loadingBatchList && <NoAvailableData text="No data Found!" />
          )}
        </>
      )}
    </MainCard>
  );
};

export default AlumniListByBatch;
