import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Webcam from "react-webcam";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { CameraEnhanceOutlined } from "@mui/icons-material";

const UploadProfilePicture = ({
  open,
  onClose,
  getAlumniDetails,
  alumniDetails,
}) => {
  const [isCapture, setIsCapture] = useState(false);
  const [picture, setPicture] = useState(null);
  const [isMirrored, setIsMirrored] = useState(true); // Mirror state
  const webcamRef = useRef(null);

  const RequestId = useRequestIdGenerater();
  const { generateTimeStamp, rendomRequestId } = useTimeStamp();

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user",
  };
  const capture = () => {
    const pictureSrc = webcamRef.current.getScreenshot();

    if (pictureSrc) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = pictureSrc;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        // Apply mirror effect if enabled
        if (isMirrored) {
          ctx.translate(canvas.width, 0);
          ctx.scale(-1, 1);
        }

        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Convert canvas to a Blob and create a mirrored file
        canvas.toBlob((blob) => {
          const fileName = `${Math.floor(100000 + Math.random() * 900000)}.jpg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });

          // Update picture state with mirrored image
          setPicture(file);
          setIsCapture(false);
        }, "image/jpeg");
      };
    }
  };


  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append("file", picture);

    try {
      const response = await axios.post(`/v1/alumni/upload/avatar`, formData, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "multipart/form-data",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            formData + rendomRequestId
          ),
        },
      });
      setPicture(null);
      onClose();
      getAlumniDetails();
      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.error(err);
    }
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
        Upload Profile Picture
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} alignItems="center">
          {/* Left Grid: Instructions */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Tips for a Good Profile Picture:
            </Typography>
            <Typography variant="body2">
              1. Make sure your face is clearly visible.
            </Typography>
            <Typography variant="body2">
              2. Avoid blurry or low-quality images.
            </Typography>
            <Typography variant="body2">
              3. Ensure the background is clean and professional.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ justifyContent: "center" }}>
            {picture ? (
              <>
                <Typography variant="subtitle1">Preview:</Typography>
                <img
                  src={URL.createObjectURL(picture)}
                  alt="Captured"
                  style={{
                    width: "100%",
                    borderRadius: 8,
                  }}
                />
                <Button
                  variant="text"
                  color="error"
                  onClick={() => setPicture(null)}
                  sx={{ mt: 1 }}
                >
                  Retake Picture
                </Button>
              </>
            ) : isCapture ? (
              <>
                <Webcam
                  audio={false}
                  height={200}
                  ref={webcamRef}
                  width={360}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  style={{
                    transform: isMirrored ? "scaleX(-1)" : "none", // Mirror the webcam feed
                  }}
                />
                <Grid container justifyContent="center" spacing={1} sx={{ mt: 1 }}>
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={capture}>
                      Capture
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setIsCapture(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  sx={{ width: 150, height: 150, cursor: "pointer" }}
                  onClick={() => setIsCapture(true)}
                >
                  <CameraEnhanceOutlined sx={{ fontSize: 50 }} />
                </Avatar>
              </Grid>
            )}


          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!picture}
          onClick={handleImageUpload}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadProfilePicture;
