import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Chip,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

// assets

import CreatePost from "./CreatePost";
import PostDetails from "./PostDetails";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import SubCard from "ui-component/cards/SubCard";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import ProfileNotification from "./ProfileNotification";

// ==============================|| SOCIAL PROFILE - POST ||============================== //

const Profile = ({ alumniDetails, getAlumniDetails, setValue }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [posts, setPosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [getlastdata, setLastdata] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const pageSize = 3;

  const [pageNumberForGroup, setPageNumberForGroup] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayedGroupList, setDisplayedGroupList] = useState([]);
  const [groupMemberImages, setGroupMemberImages] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const token = window.localStorage.getItem("serviceToken");

    const client = new Client({
      brokerURL: "ws://https://api.entraralumni.com/ws",
      webSocketFactory: () => new SockJS("https://api.entraralumni.com/ws"),
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      client.subscribe("/topic/posts", (message) => {
        const updatedPost = JSON.parse(message.body);
        handleUpdatePostLikesWS(updatedPost);
      });
    };

    client.activate();

    return () => {
      client.deactivate();
    };
  }, []);
  const getPostDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/posts/my?pageSize=10&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setPosts((prevPosts) => [...prevPosts, ...response?.data?.data?.content]);
      setLastdata(response?.data?.data?.last);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
    setIsLoading(false);
  };

  const handleUpdatePostLikes = (updatedPost) => {
    const updatedPosts = posts?.map((post) => {
      if (post.id === updatedPost.id) {
        return updatedPost;
      } else {
        return post;
      }
    });
    setPosts(updatedPosts);
  };
  const handleUpdatePostLikesWS = (updatedPost) => {
    setPosts((prevPosts) => {
      return prevPosts.map((post) => {
        if (post.id === updatedPost.id) {
          return {
            ...post,
            likes: updatedPost.likes,
            postLikesIds: updatedPost.postLikesIds,
            reactionCount: updatedPost.reactionCount,
          };
        } else {
          return post;
        }
      });
    });
  };

  useEffect(() => {
    if (!getlastdata) {
      getPostDetails();
    }

    generateTimeStamp();
  }, [pageNumber]);

  const Role = localStorage.getItem("role");

  const isAlumn = Role === "ROLE_ALUMNI";

  const handleDoubleClick = () => {
    setEditMode(isAlumn ? true : false);
  };

  const getGroupList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/v1/communication/group/my/list?pageSize=${pageSize}&pageNumber=${pageNumberForGroup}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: generateTimeStamp(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      const newGroupList = response?.data?.data?.content || [];

      newGroupList?.length > 0 && (
        <>
          {newGroupList?.forEach((group) => {
            fetchGroupMembers(group?.id);
          })}
        </>
      );

      const updatedGroupList =
        pageNumberForGroup === 0
          ? newGroupList
          : [...displayedGroupList, ...newGroupList];
      setDisplayedGroupList(updatedGroupList);
      setShowMore(newGroupList.length >= pageSize);
      setLoading(false);
      generateTimeStamp();
    } catch (error) {
      setLoading(false);
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchGroupMembers = async (groupId) => {
    try {
      const response = await axios.get(
        `/v1/communication/group/list/group/member?groupId=${groupId}&pageNumber=0&pageSize=200`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      );

      const groupMembers = response?.data?.data?.content || [];
      const alumniAvatars = groupMembers.map((member) =>
        member.alumniMemberMini.avatar === null
          ? ""
          : member.alumniMemberMini.avatar.data
      );
      setGroupMemberImages((prevImages) => ({
        ...prevImages,
        [groupId]: alumniAvatars,
      }));

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleShowMore = () => {
    setValue(6);
    // setPageNumberForGroup((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getGroupList();
  }, [pageNumberForGroup]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    generateTimeStamp();
  }, []);

  // };

  return (
    <Grid container spacing={gridSpacing}>
      <>
        {!matches && (
          <Grid item xs={12} sm={12} md={3}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <ProfileNotification />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <MainCard>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} style={{ cursor: "pointer" }}>
                    <TextField
                      id="outlined-textarea"
                      placeholder={`What’s on your mind, ${alumniDetails?.name}`}
                      rows={1}
                      fullWidth
                      multiline
                      onClick={handleClickOpen}
                    />
                  </Grid>

                  <CreatePost
                    alumniDetails={alumniDetails}
                    setOpen={setOpen}
                    open={open}
                  />
                </Grid>
              </MainCard>
            </Grid>

            {posts &&
              posts?.map((post) => (
                <Grid key={post.id} item xs={12}>
                  <PostDetails
                    isLoading={isLoading}
                    alumniDetails={alumniDetails}
                    post={post}
                    setPosts={handleUpdatePostLikes}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {!matches && (
          <Grid item xs={12} sm={12} md={3}>
            <SubCard
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#245081", fontSize: "18px" }}>
                      Groups
                    </span>
                  </Typography>
                </div>
              }
            >
              {loading ? (
                <AnimatedLoader />
              ) : displayedGroupList?.length > 0 ? (
                <Grid container>
                  {displayedGroupList.map((data, index) => (
                    <Grid
                      sx={{
                        border: "1px solid #C2D5EB",
                        p: 1,
                        mt: index !== 0 ? 2 : 0,
                        borderRadius: "10px",
                      }}
                      item
                      md={12}
                      xs={12}
                      key={data.id}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "17px",
                          fontWeight: 550,
                        }}
                      >
                        {data?.name}
                      </Typography>
                      <Typography variant="subtitle1">
                        {data?.noOfMember} Members
                      </Typography>
                      <Grid container>
                        <AvatarGroup max={4}>
                          {" "}
                          {groupMemberImages[data?.id]?.map((image, idx) => (
                            <>
                              {image && (
                                <Avatar
                                  key={idx}
                                  alt={data?.name}
                                  src={`data:image/*;base64, ${image}`}
                                />
                              )}
                            </>
                          ))}
                        </AvatarGroup>
                      </Grid>
                    </Grid>
                  ))}

                  {showMore && (
                    <Grid item md={12} xs={12}>
                      <Chip
                        onClick={handleShowMore}
                        variant="outlined"
                        color="primary"
                        sx={{
                          fontWeight: 450,
                          width: "100%",
                          mt: 2,
                        }}
                        label="View All"
                      ></Chip>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography>
                  It appears that you're not currently associated with any
                  groups.
                </Typography>
              
              )}
            </SubCard>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default Profile;
