import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { ViewList, ViewModule } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const StickyHeaderTable = ({
  data,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  columns,
  pagination = true,
}) => {
  const [gridView, setGridView] = useState(false);
  const theme = useTheme();

  // const handleView = () => {
  //   setGridView(true);
  // };

  // const handleListView = () => {
  //   setGridView(false);
  // };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <IconButton
        // onClick={handleListView}
        >
          <ViewList />
        </IconButton>
        <IconButton
        // onClick={handleView}
        >
          <ViewModule />
        </IconButton>
      </div> */}
      {!gridView ? (
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id || index}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.render
                        ? column.render(row)
                        : column.format
                        ? column.format(row[column.id])
                        : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container p={2} spacing={2}>
          {data.map((row, index) => (
            <Grid item xs={12} sm={6} md={3} key={row.id || index}>
              <Box
                p={2}
                border={1}
                borderRadius={4}
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.dark.main
                      : theme.palette.grey[50],
                  border:
                    theme.palette.mode === "dark"
                      ? "1px solid transparent"
                      : `1px solid ${theme.palette.grey[100]}`,
                  "&:hover": { borderColor: theme.palette.primary.main },
                }}
              >
                <div>
                  {columns.map((column) => (
                    <div
                      key={column.id}
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="title" component="strong">
                        {column.label}:
                      </Typography>
                      <Typography variant="subtitle">
                        {column.render
                          ? column.render(row)
                          : column.format
                          ? column.format(row[column.id])
                          : row[column.id]}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </>
  );
};

export default StickyHeaderTable;
