import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Badge, Box, Grid, Tab, Tabs, useMediaQuery } from "@mui/material";

import Profile from "./Profile";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// assets
import CollectionsIcon from "@mui/icons-material/Collections";

import { HomeOutlined, MessageOutlined } from "@mui/icons-material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import PublicIcon from "@mui/icons-material/Public";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";

import AlumniProfile from "views/alumniRegistration/alumniProfile";
import EventIcon from "@mui/icons-material/Event";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CampaignIcon from '@mui/icons-material/Campaign';

import ManageEvent from "./event/ManageEvent";
import ManagePost from "./mypost/ManagePost";
import Groups from "./groups/Groups";
import Invitations from "./invitations";
import MyConnections from "./myConnections";
import AlumniListByBatch from "views/alumniSide/batch";
import AlumniChatMainPage from "views/application/alumniChat/AlumniChat";
import PlaceMent from "views/placement";
import StarAlumni from "views/starAlumni";
import Announcement from "./announcements";

const isExternalUser = localStorage.getItem("external-token") ? true : false;
function TabPanel({ children, value, index, disabled, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      disabled={disabled}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabOptions = [
  {
    to: "/socialprofile",
    icon: <HomeOutlined stroke={1.5} size="17px" />,
    imageIcon: <HomeOutlined stroke={1.5} size="17px" />,
    label: "Home",
    count: "0",
  },
  {
    icon: <CollectionsIcon stroke={1.5} size="17px" />,
    imageIcon: <CollectionsIcon stroke={1.5} size="17px" />,
    label: "My Post",
    count: "0",
  },
  {
    icon: <Diversity3Icon stroke={1.5} size="17px" />,
    imageIcon: <Diversity3Icon stroke={1.5} size="17px" />,
    label: "My Connections",
    count: "0",
  },
  {
    icon: <PersonAddAltOutlinedIcon stroke={1.5} size="17px" />,
    label: "Invitations",
    imageIcon: <PersonAddAltOutlinedIcon stroke={1.5} size="17px" />,
    count: "0",
  },
  {
    icon: <EventIcon stroke={1.5} size="17px" />,
    imageIcon: <EventIcon stroke={1.5} size="17px" />,
    label: "Events",
    count: "0",
  },
  {
    icon: <PublicIcon stroke={1.5} size="17px" />,
    imageIcon: <PublicIcon stroke={1.5} size="17px" />,
    label: "Batch",
    count: "0",
  },
  {
    icon: <Groups2OutlinedIcon stroke={1.5} size="17px" />,
    label: "Groups",
    imageIcon: <Groups2OutlinedIcon stroke={1.5} size="17px" />,
    count: "0",
  },
  {
    icon: <MessageOutlined stroke={1.5} size="17px" />,
    label: "Messages",
    imageIcon: <MessageOutlined stroke={1.5} size="17px" />,
    count: "0",
  },
  {
    icon: <CampaignIcon stroke={1.5} size="17px" />,
    label: "Announcement",
    imageIcon: <CampaignIcon stroke={1.5} size="17px" />,
    count: "0",
  },
  {
    icon: <Person2OutlinedIcon stroke={1.5} size="17px" />,
    label: "Profile",
    imageIcon: <Person2OutlinedIcon stroke={1.5} size="17px" />,
    count: "0",
  },
  {
    icon: <StarBorderPurple500Icon stroke={1.5} size="17px" />,
    label: "Star Alumni",
    imageIcon: <StarBorderPurple500Icon stroke={1.5} size="17px" />,
    count: "0",
  },
];

if (isExternalUser) {
  tabOptions.push({
    icon: <Person2OutlinedIcon stroke={1.5} size="17px" />,
    label: "Placement",
    imageIcon: <Person2OutlinedIcon stroke={1.5} size="17px" />,
    count: "0",
  });
}
// ==============================|| SOCIAL PROFILE ||============================== //

const AlumniSocialProfile = ({ getAlumniDetails, alumniDetailsInfo, alumniDetails, renderFrom = "Other" }) => {
  const theme = useTheme();
  const { tab } = useParams();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  let selectedTab = 0;

  const [isLoading, setLoading] = useState(true);
  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event, newValue) => {
    if (
      alumniDetails?.alumniStatus === "Pending" ||
      alumniDetails?.addedBy === null
    ) {
      setValue(8);
    } else {
      setValue(newValue);
    }
    if (newValue === 8) {
      localStorage.setItem("tabValue", newValue.toString());
    } else {
      localStorage.removeItem("tabValue");
    }
  };

  useEffect(() => {
    const storedTabValue = localStorage.getItem("tabValue");
    if (storedTabValue && !isNaN(parseInt(storedTabValue))) {
      setValue(parseInt(storedTabValue));
    }

  }, []);

  useEffect(() => {
    if (renderFrom === "HeaderSearch") {
      setValue(7)
    }
  }, [renderFrom]);



  useEffect(() => {
    localStorage.removeItem("tabValue");
  }, []);

  useEffect(() => {
    setLoading(false);
    if (
      alumniDetails?.alumniStatus === "Pending" ||
      alumniDetails?.addedBy === null
    ) {
      setValue(9);
    }
  }, [alumniDetails]);

  const filteredTabOptions =
    renderFrom === "HeaderSearch"
      ? tabOptions.filter((option) => option.label === "Messages")
      : tabOptions;


  return (
    <Grid container spacing={gridSpacing}>
      {!matches && (
        <Grid item xs={12} md={12}>
          <MainCard
            contentSX={{
              p: 1.5,
              paddingBottom: "0px !important",
              [theme.breakpoints.down("lg")]: {
                textAlign: "center",
              },
            }}
          >
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Tabs
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        border: "none",
                      },
                      "& a": {
                        minHeight: "auto",
                        minWidth: 10, // Adjust the minWidth as needed
                        py: 1.5,
                        px: 2,
                        mr: 2.25,
                        color: "grey.700",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& a.Mui-selected": {
                        color: "primary.main",
                      },
                      "& a > svg": {
                        marginBottom: "4px !important",
                        mr: 1.25,
                      },
                    }}
                  >
                    {filteredTabOptions.map((option, index) => (
                      <Tab
                        key={index}
                        component={Link}
                        to={option.to}
                        icon={option.imageIcon}
                        label={
                          matches ? (
                            ""
                          ) : (
                            <>
                              {option.label}
                              {option.count > 0 && (
                                <Badge
                                  sx={{ ml: 3 }}
                                  badgeContent={option.count}
                                  color="secondary"
                                >
                                  {/* You can customize the badge appearance if needed */}
                                </Badge>
                              )}
                            </>
                          )
                        }
                        disabled={
                          (renderFrom === "HeaderSearch" && option.label === "Messages") ||
                          alumniDetails?.alumniStatus === "Pending" ||
                          alumniDetails?.addedBy === null
                        }
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <TabPanel value={value} index={0}>
          <Profile
            setValue={setValue}
            getAlumniDetails={getAlumniDetails}
            alumniDetails={alumniDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ManagePost />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MyConnections value={value} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Invitations />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ManageEvent />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <AlumniListByBatch />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Groups />
        </TabPanel>
        <TabPanel
          value={value}
          index={7}
          disabled={renderFrom === "HeaderSearch"}
        >
          <AlumniChatMainPage
            alumniDetailsInfo={alumniDetailsInfo} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Announcement />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <AlumniProfile setValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <StarAlumni />
        </TabPanel>
        {isExternalUser && (
          <TabPanel value={value} index={11}>
            <PlaceMent setValue={setValue} />
          </TabPanel>
        )}
      </Grid>
      {matches && (
        <Grid item xs={12}>
          <MainCard
            contentSX={{
              p: 1.5,
              paddingBottom: "0px !important",
              [theme.breakpoints.down("lg")]: {
                textAlign: "center",
              },
            }}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 1000,
            }}
          >
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Tabs
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                    sx={{
                      marginTop: 2.5,
                      borderTop: "1px solid #e0e0e0",
                      "& .MuiTabs-flexContainer": {
                        border: "none",
                      },
                      "& a": {
                        minHeight: "auto",
                        minWidth: 10,
                        py: 1.5,
                        px: 1,
                        mr: 2.25,
                        color: "grey.700",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& a.Mui-selected": {
                        color: "primary.main",
                      },
                      "& a > svg": {
                        marginBottom: "4px !important",
                        mr: 1.25,
                      },
                    }}
                  >
                    {filteredTabOptions?.map((option, index) => (
                      <Tab
                        key={index}
                        component={Link}
                        to={option.to}
                        icon={option.icon}
                        label={
                          matches ? (
                            ""
                          ) : (
                            <>
                              {option.label}
                              {option.count > 0 && (
                                <Badge
                                  badgeContent={option.count}
                                  color="secondary"
                                >
                                </Badge>
                              )}
                            </>
                          )
                        }
                        disabled={
                          (renderFrom === "HeaderSearch" && option.label === "Messages") ||
                          alumniDetails?.alumniStatus === "Pending" ||
                          alumniDetails?.addedBy === null
                        }
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      )}
    </Grid>
  );
};

export default AlumniSocialProfile;
