import { Avatar, Grid, Typography } from '@mui/material';
import axios from 'axios';
import useRequestIdGenerater from 'customhooks';
import React, { useEffect, useState } from 'react'
import useTimeStamp from 'reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp';
import ProfilePage from 'views/alumniRegistration/alumniProfile/profilePage';
import AlumniProfileAction from './AlumniProfileAction';

const SearchProfile = ({ alumniUserId, selectedAlumni, setViewProfile }) => {
    const [profileInfo, setProfileInfo] = useState([])
    const [connectionInfo, setConnectionInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const RequestId = useRequestIdGenerater();
    const { rendomRequestId, generateTimeStamp } = useTimeStamp();

    const getConnectionDetails = async (alumniUserId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `/v1/alumni/profile?userId=${alumniUserId}`,
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                            "serviceToken"
                        )}`,
                        "Content-Type": "application/json",
                        requestId: RequestId(
                            Math.floor(1000 + Math.random() * 9999),
                            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
                        ),
                    },
                }
            );
            setConnectionInfo(response?.data?.data);
            generateTimeStamp();
            setIsLoading(false);
        } catch (error) {
            generateTimeStamp();
            console.error("Failed to fetch data:", error);
        }
    };

    const getProfileInfo = async () => {
        try {
            const response = await axios.get(
                `/v1/connection/check-for-individual?alumniUserId=${alumniUserId}`,
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                            "serviceToken"
                        )}`,
                        "Content-Type": "application/json",
                        requestId: RequestId(
                            Math.floor(1000 + Math.random() * 9999),
                            Math.floor(1000 + Math.random() * 9999) + rendomRequestId
                        ),
                    },
                }
            );
            const { data } = await response.data;
            if (data?.connected && data?.connectionRequestByAlumni === null && data?.connectionRequestByUser === null && (
                getConnectionDetails(alumniUserId)
            ))
                console.log(response?.data?.data, "profileInfo")
            setProfileInfo(response?.data?.data);

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getProfileInfo();
        generateTimeStamp();
    }, [])

    useEffect(() => {
        if (
            profileInfo?.connectionRequestByAlumni !== null &&
            profileInfo?.connectionRequestByUser !== null
        ) {
            setConnectionInfo(selectedAlumni);
        }
    }, [profileInfo, selectedAlumni]);

    return (
        <Grid container>
            <>
                {profileInfo?.connected && profileInfo?.connectionRequestByAlumni === null && profileInfo?.connectionRequestByUser === null ? (
                    <ProfilePage
                        setViewProfile={setViewProfile}
                        IsConnection={profileInfo?.connected}
                        isLoading={isLoading}
                        alumniDetails={connectionInfo}
                        renderFrom="HeaderSearch"
                        getAlumniDetails={getConnectionDetails}
                    />
                ) : (
                    <>
                        <AlumniProfileAction
                            getProfileInfo={getProfileInfo}
                            profileInfo={profileInfo}
                            setViewProfile={setViewProfile}
                            IsConnection={profileInfo?.connected}
                            isLoading={isLoading}
                            alumniDetails={connectionInfo}
                            renderFrom="HeaderSearch"
                            getAlumniDetails={getConnectionDetails}
                        />
                    </>
                )}
            </>
        </Grid>
    )
}

export default SearchProfile