import { Grid, Typography, Switch, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import SubCard from "ui-component/cards/SubCard";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { styled } from "@mui/material/styles";
import EditOutlined from "@mui/icons-material/EditOutlined";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";

const PersonalDetailsCard = ({
  alumniDetails,
  handleNavigate,
  IsConnection,
  handleNavigateWorkingStatus,
}) => {
  const [privicyDetails, setPrivicyDetails] = useState([]);
  const [emailSwitch, setEmailSwitch] = useState(false);
  const [phoneSwitch, setPhoneSwitch] = useState(privicyDetails?.phone);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getPrivicyDetails = async () => {
    try {
      const response = await axios.get(
        `/v1/settings/profile/get/by-id?userId=${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setPrivicyDetails(response?.data?.data);

      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleEmailSwitchChange = async (event) => {
    setEmailSwitch(!emailSwitch);

    const response = await axios
      .get(
        `/v1/settings/profile?emailVisible=${event}&phoneVisible=${!phoneSwitch}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      )
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Email Privacy Updated .",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );

        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: error.response.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  const handlePhoneSwitchChange = async (event) => {
    setPhoneSwitch(!phoneSwitch);

    const response = await axios
      .get(
        `/v1/settings/profile?emailVisible=${emailSwitch}&phoneVisible=${!event}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      )
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Phone Privacy Updated .",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );

        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: error.response.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  const addressParts = [
    alumniDetails?.address?.city,
    alumniDetails?.address?.state,
    alumniDetails?.address?.country,
  ].filter(Boolean);

  const formattedAddress = addressParts.join(", ");

  useEffect(() => {
    generateTimeStamp();
  }, []);

  useEffect(() => {
    getPrivicyDetails();
  }, []);
  useEffect(() => {
    setPhoneSwitch(privicyDetails?.phone);
    setEmailSwitch(privicyDetails?.email);
  }, [privicyDetails]);

  return (
    <>
      <SubCard
        sx={{
          p: 1,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ffffff",
          },
        }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#245081", fontSize: "18px" }}>
                Personal Details
              </span>
            </Typography>
          </div>
        }
        secondary={
          <>
            {!IsConnection && (
              <>
                <div style={{ cursor: "pointer" }}>
                  <img
                    height="20px"
                    onClick={() => handleNavigate(alumniDetails?.id)}
                    alt="icon"
                    src="/assets/images/icons/editIcon.svg"
                  />
                </div>
              </>
            )}
          </>
        }
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {[
            {
              label: "Name",
              value: alumniDetails?.name,
            },
            {
              label: "Gender",
              value: alumniDetails?.gender,
            },
            {
              label: "Phone",
              value: alumniDetails?.phoneNo,
              switch: (
                <>
                  {!IsConnection && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!phoneSwitch}
                          onChange={(e) =>
                            handlePhoneSwitchChange(e.target.checked)
                          }
                        />
                      }
                      label={!phoneSwitch ? <LockIcon /> : <LockOpenIcon />}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Email",
              value: alumniDetails?.email,
              switch: (
                <>
                  {!IsConnection && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!emailSwitch}
                            onChange={(e) =>
                              handleEmailSwitchChange(e.target.checked)
                            }
                          />
                        }
                        label={!emailSwitch ? <LockIcon /> : <LockOpenIcon />}
                      />
                    </>
                  )}
                </>
              ),
            },
            { label: "Batch", value: alumniDetails?.batch?.tag },
            {
              label: "Program",
              value: alumniDetails?.programs?.name,
            },
            {
              label: "Career Status",
              value:
                alumniDetails?.careerStatus === "Higher_Education"
                  ? "Higher Education"
                  : "Working",
            },
            {
              label: "Address",
              value: formattedAddress,
            },
          ].map((item, index) => (
            <Grid
              item
              md={12}
              xs={12}
              p={2}
              key={index}
              sx={{ display: "flex" }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  minWidth: 200,
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {item.label}
              </Typography>
              {item.label === "Career Status" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography>{item.value || "-----"}</Typography>
                  {!IsConnection && (
                    <>
                      {alumniDetails?.careerStatus && (
                        <EditOutlined
                          onClick={() =>
                            handleNavigateWorkingStatus(alumniDetails?.id)
                          }
                          style={{
                            fontSize: "16px",
                            color: "secondary",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <Typography>
                    {item.value || "-----"} {item.switch && item.switch}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </SubCard>
    </>
  );
};

export default PersonalDetailsCard;
