import React from "react";

import {
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";

import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import { useDispatch } from "store";
import { getProfileCards } from "store/slices/user";

// assets

import AlumniProfileCard from "./AlumniProfileCard";

// ==============================|| USER CARD STYLE 3 ||============================== //

const AlumniCards = ({
  alumniData,
  totalCount,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  search,
  setSearch,
  handleBatchChange,
  setAlumniData,
  setPageCache,
  pageCache,
  handleViewProfile
}) => {
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState(alumniData);

  React.useEffect(() => {
    setUsers(alumniData);
  }, [alumniData]);

  React.useEffect(() => {
    dispatch(getProfileCards());
  }, []);

  let usersResult = <></>;
  if (users) {
    usersResult = users?.map((user, index) => (
      <Grid key={index} item xs={12} sm={2} lg={2} xl={2} md={2}>
        <AlumniProfileCard
          setPageCache={setPageCache}
          setAlumniData={setAlumniData}
          handleViewProfile={handleViewProfile}
          user={user}
        />
      </Grid>
    ));
  }



  return (
    // <MainCard
    //   title={
    //     <Grid
    //       container
    //       alignItems="center"
    //       justifyContent="space-between"
    //       spacing={gridSpacing}
    //     >
    //       <Grid item>
    //         <Typography variant="h3">Alumni's</Typography>
    //       </Grid>

    //     </Grid>
    //   }
    // >
    <Grid container direction="row" mt={2} spacing={gridSpacing}>
      {usersResult}
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={gridSpacing}>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Grid>
    // </MainCard>
  );
};

export default AlumniCards;
