import { Avatar, Card, Chip, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment';
import PercentageChart from './PercentageChart';
import { useParams } from 'react-router-dom';

const StudentProfile = ({ studentId, setViewProfile }) => {
  const [studentData, setStudentData] = useState();
  const [chartData, setChartData] = useState([]);

  const getStudentData = () => {
    axios
      // eslint-disable-next-line no-template-curly-in-string
      .get(
        `${process.env.REACT_APP_ENTRARUNIVERSITYBASEURL}/v1/student/get-alumni-student-profile?studentId=${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "external-token"
            )}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setStudentData(result.data.data);
      });
  };

  const onDownload = (downloadLink) => {
    const link = document.createElement('a');
    link.download = downloadLink;
    link.target = '_blank';
    link.href = downloadLink;
    link.click();
  };

  useEffect(() => {
    getStudentData(studentId);
  }, []);

  return (
    <>
      <Grid item md={12} xs={12} lg={12} mt={2}>
        <Typography
          variant="h4"

          sx={{
            fontSize: "18px",
            p: 2,
            cursor: "pointer",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
          }}
        >
          <span onClick={() => setViewProfile(false)}>Back</span>
        </Typography>
      </Grid>
      <Card
        sx={{
          background: 'linear-gradient(to right, #FFEFBA, #FFFFFF)',
          padding: '20px',
          borderRadius: '8px',
          mt: 1
        }}
      >


        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2} container justifyContent="center">
            <Avatar alt="User 1" src="/assets/images/icons/man.svg" sx={{ width: 100, height: 100 }} />
          </Grid>
          <Grid item xs={12} sm={8} md={4} sx={{ alignContent: 'center' }}>
            <Typography variant="h3" color="#383930">
              {studentData?.student?.studentName}
            </Typography>
            <Typography variant="h3" color="#383930">
              {studentData?.student?.rollNo}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={{ alignContent: 'center' }}>
            <Typography variant="h3" color="#383930">
              {studentData?.student?.courseDetail?.course?.departmentMini.name}
            </Typography>
            <Typography variant="h3" color="#383930">
              {studentData?.student?.courseDetail?.course?.courseName}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ mt: 2, p: 2 }}>
        <Typography variant="h4">Personal Information</Typography>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={8} md={3}>
            <Box>
              <Typography variant="h5" color="#04326D">
                Name
              </Typography>
              <Typography variant="h5" color="#383930">
                {studentData?.student?.studentName}
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="h5" color="#04326D">
                Gender
              </Typography>
              <Typography variant="h5" color="#383930">
                {studentData?.student?.personalDetails?.gender}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={3}>
            <Box>
              <Typography variant="h5" color="#04326D">
                Phone
              </Typography>
              <Typography variant="h5" color="#383930">
                {studentData?.student?.personalDetails?.phone}
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="h5" color="#04326D">
                Email
              </Typography>
              <Typography variant="h5" color="#383930">
                {studentData?.student?.personalDetails?.email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={3}>
            <Box>
              <Typography variant="h5" color="#04326D">
                Roll Number
              </Typography>
              <Typography variant="h5" color="#383930">
                {studentData?.student?.rollNo}
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="h5" color="#04326D">
                Semester
              </Typography>
              <Typography variant="h5" color="#383930">
                {`${studentData?.student?.semester}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ mt: 2, p: 2 }}>
        <Typography variant="h4">Academic Performance</Typography>
        <PercentageChart studentId={studentId} setChartData={setChartData} />
      </Card>
      {studentData?.studentProfile !== null && (
        <>
          {studentData?.studentProfile?.certificates !== null && studentData?.studentProfile?.certificates !== '' && (
            <Card sx={{ mt: 2, p: 2 }}>
              <Typography variant="h4">Certificates</Typography>
              {studentData?.studentProfile?.certificates?.map((c) => (
                <>
                  <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                    <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {c?.certificateName} Certificate
                      <IconButton color="primary" onClick={() => onDownload(c?.certificateLogo)}>
                        <DownloadForOfflineIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid style={{ fontWeight: 'bold' }}>
                    <Divider />
                  </Grid>
                </>
              ))}
            </Card>
          )}
          {studentData?.studentProfile?.workExperiences !== null && studentData?.studentProfile?.workExperiences !== '' && (
            <Card sx={{ mt: 2, p: 2 }}>
              <Typography variant="h4">Work Experience</Typography>
              <Grid container spacing={2}>
                {studentData?.studentProfile?.workExperiences?.map((w) => (
                  <>
                    <Grid item xs={12} sm={8} md={4} sx={{ mt: 1 }}>
                      <Box>
                        <Typography variant="h4" color="#04326D">
                          Experience
                        </Typography>
                        <Typography variant="h5">{`${w.company} (${moment(w.startDate).format(
                          'DD/MM/YYYY'
                        )} - ${moment(w.endDate).format('DD/MM/YYYY')})`}</Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="h4" color="#04326D">
                          Description
                        </Typography>
                        <Typography variant="h5">{w.jobDescription}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                      <Box>
                        <Typography variant="h4" color="#04326D">
                          Designation
                        </Typography>
                        <Typography variant="h5">{w.designationName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                      <Box>
                        <Typography variant="h4" color="#04326D">
                          Company
                        </Typography>
                        <Typography variant="h5">{w.company}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold', color: 'gray' }}>
                      <Divider />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Card>
          )}
          {studentData?.studentProfile?.projects !== null && studentData?.studentProfile?.projects !== '' && (
            <Card sx={{ mt: 2, p: 2 }}>
              <Typography variant="h4">Projects</Typography>
              <Grid container spacing={2}>
                {studentData?.studentProfile?.projects?.map((p) => (
                  <>
                    <Grid item xs={12} sm={8} md={6} sx={{ mt: 1 }}>
                      <Box>
                        <Typography variant="h4" color="#04326D">
                          {p.projectName}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="h5">{`${moment(p.projectStartDate).format('DD MMM YYYY')} - ${moment(
                          p.projectEndDate
                        ).format('DD MMM YYYY')}`}</Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="h5">{p.projectDescription}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold', color: 'gray' }}>
                      <Divider />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Card>
          )}
          {studentData?.studentProfile?.skills !== null && studentData?.studentProfile?.skills !== '' && (
            <Card sx={{ mt: 2, p: 2 }}>
              <Typography variant="h4">Skills</Typography>
              <Grid container spacing={1}>
                {studentData?.studentProfile?.skills?.map((s) => (
                  <Grid item sx={{ mt: 1 }}>
                    <Chip sx={{ backgroundColor: '#CCFBCF' }} label={s?.skillsName} />
                  </Grid>
                ))}
              </Grid>
            </Card>
          )}
          <Card sx={{ mt: 2, p: 2 }}>
            <Typography variant="h4">Links & Resume</Typography>
            {studentData?.studentProfile?.resumeName !== null && studentData?.studentProfile?.resumeName !== '' && (
              <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontWeight: 'bold' }}>Resume:</span>
                  <IconButton color="primary" onClick={() => onDownload(studentData?.studentProfile?.resumeName)}>
                    <DownloadForOfflineIcon />
                  </IconButton>
                </Typography>
              </Grid>
            )}
            {studentData?.studentProfile?.githubProfileLink !== null && studentData?.studentProfile?.githubProfileLink !== '' && (
              <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontWeight: 'bold' }}>Github:</span>
                  <a target="_blank" rel="noreferrer" href={studentData?.studentProfile?.githubProfileLink}>
                    {studentData?.studentProfile?.githubProfileLink}
                  </a>
                </Typography>
              </Grid>
            )}
            {studentData?.studentProfile?.linkedinProfileLink !== null &&
              studentData?.studentProfile?.linkedinProfileLink !== '' && (
                <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                  <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold' }}>LinkedIn:</span>
                    <a target="_blank" rel="noreferrer" href={studentData?.studentProfile?.linkedinProfileLink}>
                      {studentData?.studentProfile?.linkedinProfileLink}
                    </a>
                  </Typography>
                </Grid>
              )}
            {studentData?.studentProfile?.portfolioLink !== null && studentData?.studentProfile?.portfolioLink !== '' && (
              <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontWeight: 'bold' }}>Portfolio:</span>
                  <a target="_blank" rel="noreferrer" href={studentData?.studentProfile?.portfolioLink}>
                    {studentData?.studentProfile?.portfolioLink}
                  </a>
                </Typography>
              </Grid>
            )}
            {studentData?.studentProfile?.profileDescription && studentData?.studentProfile?.profileDescription !== '' && (
              <Grid item xs={12} sm={8} md={12} sx={{ mt: 1 }}>
                <Typography variant="h5">
                  <span style={{ fontWeight: 'bold' }}>Description:</span>{' '}
                  {studentData?.studentProfile?.profileDescription}{' '}
                </Typography>
              </Grid>
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default StudentProfile;
