import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Chip,
  Avatar,
} from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";

import InfoIcon from "@mui/icons-material/Info";
import ApplyIcon from "@mui/icons-material/Send";
import MainCard from "ui-component/cards/MainCard";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NoAvailableData from "reuseableTable/NoAvailableData";
import StarAlumniForm from "./StarAlumniForm";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import AnimatedLoader from "reuseableTable/AnimatedLoader";
import StickyHeaderTable from "reuseableTable";
import ApplicationDetailsDialog from "./ApplicationDetailsDailog";
import moment from "moment";
import StarAlumniCard from "./StarAlumniCard";
import StarAlumniHighlight from "./StarAlumniHighlight";

const StarAlumni = () => {
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const [loading, setLoading] = useState(false);
  const [alumni, setAlumni] = useState(null);
  const [staralumniRequests, setStaralumniRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(11);
  const [totalCount, setTotalCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleViewDetails = (row) => {
    setOpenDetail(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAlumniDetails = async () => {
    try {
      const response = await axios.get(
        `/v1/alumni/info?userId=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setAlumni(response.data.data);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const getstarAlumniRequests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/v1/alumni/star-alumni-application?pageSize=${rowsPerPage}&pageNumber=${page}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setStaralumniRequests(response.data.data.content);
      generateTimeStamp();
      setLoading(false);

      setTotalCount(response?.data?.data?.totalElements);
    } catch (error) {
      generateTimeStamp();
      setLoading(false);
      console.error("Failed to fetch data:", error);
    }
  };
  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "left",
      render: (row) => (
        <div
          style={{
            display: "flex",
          }}
        >
          <Avatar
            alt={row?.alumniMemberMini?.name}
            src={`data:image/*;base64, ${row?.alumniMemberMini?.avatar?.data}`}
          />
          <div style={{ marginLeft: "5px" }}>
            <p
              style={{
                fontWeight: "bold",
                margin: "0",
              }}
            >
              {row?.alumniMemberMini?.name}
            </p>
            <p style={{ margin: "0" }}>{row?.alumniMemberMini?.email}</p>
          </div>
        </div>
      ),
    },
    {
      id: "approvalNote",
      label: "Approval/Rejection Note",
      minWidth: 100,
      align: "center",
      render: (row) => (
        <div>
          <p>{row?.approvalNote ? row?.approvalNote : "-"}</p>
        </div>
      ),
    },

    {
      id: "appliedDate",
      label: "Applied On",
      minWidth: 100,
      align: "center",
      render: (row) => (
        <div>
          <p>{moment(row?.appliedDate).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },

    {
      id: "approvalStatus",
      label: "Status",
      minWidth: 100,
      align: "left",
      render: (row) => (
        <div>
          <Chip
            sx={{
              backgroundColor:
                row.approvalStatus === "Pending"
                  ? "#E7C1C1"
                  : row.approvalStatus === "Approved"
                  ? "#D1F0BB"
                  : row.approvalStatus === "Rejected" && "#ECBEB2",
              color: "#000000", // Text color
            }}
            label={row?.approvalStatus}
          />
        </div>
      ),
    },
    {
      id: "action",
      label: "View Details",
      minWidth: 180,
      align: "center",
      render: (row) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleViewDetails(row)}
        >
          View Details
        </Button>
      ),
    },
  ];
  useEffect(() => {
    getAlumniDetails();
    getstarAlumniRequests();
  }, []);

  return (
    <MainCard>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" }, // Stack for mobile
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: { xs: 2, sm: 0 }, // Add spacing for stacked layout
          }}
        >
          Star Alumni{" "}
          <StarsIcon
            sx={{ color: "#D89E46", marginLeft: 1, fontSize: "20px" }}
          />
        </Typography>
        {!alumni?.starAlumni && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<ApplyIcon />}
            sx={{
              textTransform: "capitalize",
              width: { xs: "100%", sm: "auto" }, // Full width for mobile
            }}
            onClick={handleOpen}
          >
            Apply to Become a Star Alumni
          </Button>
        )}
      </Box>
      <Box>
        {loading ? (
          <AnimatedLoader />
        ) : (
          <>
            {alumni?.starAlumni ? (
              <StarAlumniHighlight
                alumni={alumni}
                staralumniRequests={staralumniRequests}
                
              />
            ) : (
              <StarAlumniCard alumni={alumni} />
            )}
            {!alumni?.starAlumni &&
              staralumniRequests[0]?.approvalStatus === "Pending" &&
              staralumniRequests?.length > 0 && (
                <>
                  <StickyHeaderTable
                    data={staralumniRequests}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    columns={columns}
                  />
                </>
              )}
          </>
        )}
      </Box>

      <StarAlumniForm
        userId={""}
        open={open}
        handleClose={handleClose}
        getstarAlumniRequests={getstarAlumniRequests}
      />
      {openDetail && (
        <ApplicationDetailsDialog
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          applicationId={staralumniRequests[0]?.id}
        />
      )}
    </MainCard>
  );
};

export default StarAlumni;
