// DrawerContext.js

import React, { createContext, useState, useContext } from "react";

// Create the context
const DrawerContext = createContext({
  drawerOpen: false,
  setDrawerOpen: () => {},
  setOpenDrawerForUserId: () => {},
});

// Create a provider component
export const DrawerProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDrawerForUserId, setOpenDrawerForUserId] = useState("");

  return (
    <DrawerContext.Provider
      value={{ drawerOpen, setDrawerOpen, openDrawerForUserId, setOpenDrawerForUserId }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

// Custom hook to use the context
export const useDrawer = () => useContext(DrawerContext);
