import {
  Avatar,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CollectionsIcon from "@mui/icons-material/Collections";
import PublicIcon from "@mui/icons-material/Public";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupsIcon from "@mui/icons-material/Groups";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import GroupDetails from "./GroupDetails";
import EventForm from "./EventForm";
import moment from "moment";
import SubCard from "ui-component/cards/SubCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CloseOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ReactPhotoEditor } from "react-photo-editor";
// import "react-photo-editor/dist/style.css";
import BatchDetails from "./BatchDetails";

const CreatePost = ({ open, setOpen, alumniDetails, getPostDetails }) => {
  const [postdata, setpostdata] = useState("");
  const [posttype, setPostType] = useState(false);
  const [postsFor, setPostFor] = useState(false);
  const [forGroup, setForGroup] = useState(false);
  const [forBatch, setForBatch] = useState(false);
  const [openEventForm, setOpenEventForm] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventdate, setEventDate] = useState(moment().format("YYYY-MM-DD"));
  const [eventTime, setEventTime] = useState(moment().format("HH:mm"));
  const [eventType, setEventType] = useState("Online");
  const [eventLink, setEventLink] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const role = localStorage.getItem("role");
  const [selectedGroupIds, setSelectedGroupIds] = useState(
    role === "ROLE_COLLEGE_ADMIN" ? [] : groupId
  );
  const [selectedBatchIds, setSelectedBatchIds] = useState(
    role === "ROLE_COLLEGE_ADMIN" ? [] : batchId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState("EveryOne");

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const handleClose = () => {
    setOpen(false);
    setpostdata("");
    setEventName("");
    setOpenEventForm(false);
    setEventDate(moment().format("YYYY-MM-DD"));
    setPostType(false);
    setPostFor(false);
    setForGroup(false);
    setGroupId(null);
    setBatchId(null);
    setEventLink("");
    setSelectedOption("EveryOne");
    setSelectedGroupIds([]);
    setSelectedBatchIds([]);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    if (event.target.value === "EveryOne") {
      setSelectedGroupIds([]);
      setSelectedBatchIds([]);
    }

    if (event.target.value === "Batch" && role === "ROLE_COLLEGE_ADMIN") {
      handleOpenforBatch();
      setSelectedGroupIds([]);
    }

    if (event.target.value === "Group") {
      handleOpenforGroup();
      setSelectedBatchIds([]);
    }
  };

  const handleOpenPostFor = () => {
    setPostFor(true);
  };

  const handleClosePostFor = () => {
    setPostFor(false);
  };

  const handleOpenforGroup = () => {
    setForGroup(true);
  };
  const handleOpenforBatch = () => {
    setForBatch(true);
  };
  const handleCloseforBatch = () => {
    setForBatch(false);
  };

  const handleCloseforGroup = () => {
    setForGroup(false);
  };

  const handleOpenEventForm = () => {
    setOpenEventForm(true);
  };

  const handleCloseEventForm = () => {
    setOpenEventForm(false);
  };

  const handleCancelEventForm = () => {
    setpostdata("");
    setEventName("");
    setOpenEventForm(false);
    setEventDate(moment().format("YYYY-MM-DD"));
    setPostType(false);
    setPostFor(false);
    setForGroup(false);
    setGroupId(null);
    setBatchId(null);
    setEventLink("");
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);
  const handleUploadPost = () => {
    setIsSubmitting(true);

    let postFor = "EveryOne";
    let postForId = "";
    let postForIds = null;

    if (selectedOption === "Batch") {
      postFor = "Batch";
      let batch = localStorage.getItem("alumniBatchId");
      if (batch) {
        postForId = batch;
        postForIds = selectedBatchIds;
      }
    } else if (selectedOption === "Group") {
      postFor = "Group";
      if (postFor === "Group") {
        postForId = groupId;
        postForIds = selectedGroupIds;
      }
    }
    console.log(selectedGroupIds, groupId, "selectedGroupIds");
    console.log(selectedBatchIds, batchId, "selectedGroupIds");

    const filePaths = selectedFiles.map((file) => file.filePath);
    const payload = {
      postsFor: postFor,
      postForId: postForId,
      postForIds: postForIds,
      postText: postdata,
      postsType: eventName !== "" ? "Event" : "Media",
      eventPost: eventName !== "" ? true : false,
      eventName: eventName,
      eventType: eventType,
      eventLocation: eventLocation,
      eventLink: eventLink,
      eventDate: eventdate,
      eventTime: eventTime,
      alumniId: localStorage.getItem("alumniId"),
      images: filePaths,
    };
    console.log(payload, "selectedGroupIds");
    axios
      .post("/v1/posts/create", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: ConvertToRequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999) + rendomRequestId
          ),
        },
      })
      .then((response) => {
        dispatch(
          openSnackbar({
            open: true,
            message: response.data.Msg,
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        handleClose();
        setpostdata("");
        setEventName("");
        setEventLink("");
        setOpenEventForm(false);
        setEventDate(moment().format("YYYY-MM-DD"));
        setPostType(false);
        setSelectedFiles([]);

        setPostFor(false);
        setForGroup(false);
        setGroupId(null);
        setBatchId(null);
        getPostDetails();
        setSelectedOption("EveryOne");
        setIsSubmitting(false);
        generateTimeStamp();
      })
      .catch((error) => {
        setIsSubmitting(false);
        generateTimeStamp();
        dispatch(
          openSnackbar({
            open: true,
            message: error.response?.data?.Msg,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      });
  };

  const CHUNK_SIZE = 1 * 900 * 900;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [fileIndexToEdit, setFileIndexToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = async (event) => {
    const files = Array.from(event.target.files);
    const remainingSlots = 4 - selectedFiles.length;
    const filesToProcess = files.slice(0, remainingSlots);

    if (files.length > 4) {
      console.error("You can upload only 4 images.");
    }

    const uploadedPaths = [];
    const allOriginalFiles = [...originalFiles];

    for (const file of filesToProcess) {
      allOriginalFiles.push(file);
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        try {
          const response = await uploadChunk(file, chunk, i, totalChunks);
          if (i === totalChunks - 1) {
            const uploadedFilePath = response.data?.data?.filePath;
            const preSignedUrl = response.data?.data?.preSignedUrl;
            uploadedPaths.push({ filePath: uploadedFilePath, preSignedUrl });
          }
        } catch (error) {
          console.error("Error uploading chunk:", error);
          break;
        }
      }
    }

    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...uploadedPaths,
    ]);
    setOriginalFiles(allOriginalFiles);
    fileInputRef.current.value = null;
  };

  const uploadChunk = async (file, chunk, chunkIndex, totalChunks) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("fileName", file.name);

    return axios.post("/v1/upload", formData, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("serviceToken")}`,
        "Content-Type": "multipart/form-data",
        requestId: ConvertToRequestId(
          Math.floor(1000 + Math.random() * 9999),
          Math.floor(1000 + Math.random() * 9999) + rendomRequestId
        ),
      },
    });
  };

  const handleRemoveFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    const updatedOriginalFiles = [...originalFiles];

    updatedSelectedFiles.splice(index, 1);
    updatedOriginalFiles.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setOriginalFiles(updatedOriginalFiles);
  };

  const handleEditImage = (index) => {
    const originalFile = originalFiles[index]; // Retrieve the original file for editing

    if (originalFile) {
      setFileToEdit(originalFile); // Set file for editor
      setFileIndexToEdit(index); // Track index for saving
      setShowModal(true); // Open editor modal
    } else {
      console.error("Original file not found at index:", index);
    }
  };

  const handleSaveImage = async (editedFile) => {
    const updatedFiles = [...selectedFiles];
    const updatedOriginalFiles = [...originalFiles];

    // Replace the original file with the edited one
    updatedOriginalFiles[fileIndexToEdit] = editedFile;

    // Re-upload the edited file in chunks
    const totalChunks = Math.ceil(editedFile.size / CHUNK_SIZE);
    const uploadedPaths = [];
    for (let i = 0; i < totalChunks; i++) {
      const chunk = editedFile.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
      try {
        const response = await uploadChunk(editedFile, chunk, i, totalChunks);
        if (i === totalChunks - 1) {
          const uploadedFilePath = response.data?.data?.filePath;
          const preSignedUrl = response.data?.data?.preSignedUrl;
          uploadedPaths.push({ filePath: uploadedFilePath, preSignedUrl });
        }
      } catch (error) {
        console.error("Error uploading chunk:", error);
        break;
      }
    }

    // Update metadata for the edited file
    updatedFiles[fileIndexToEdit] = uploadedPaths[0];

    setSelectedFiles(updatedFiles);
    setOriginalFiles(updatedOriginalFiles); // Update original files list
    setShowModal(false);
    setFileToEdit(null);
  };

  const hideModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <>
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent
            sx={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
          >
            <Grid container>
              <Grid
                item
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={alumniDetails?.name}
                    src={`data:image/*;base64, ${alumniDetails?.avatar?.data}`}
                  />

                  <Grid container alignItems="center" p={1}>
                    <Grid item xs={12}>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                          {alumniDetails?.name}
                        </Typography>
                        <ArrowDropDownIcon onClick={handleOpenPostFor} />
                      </Grid>
                      <Typography variant="subtitle2">
                        {selectedOption === "EveryOne"
                          ? "Anyone"
                          : selectedOption}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <IconButton onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid item md={12} xs={12}>
                {selectedFiles?.length > 0 && (
                  <Grid container spacing={1}>
                    {selectedFiles?.map((file, index) => (
                      <Grid item md={6} xs={12} key={index}>
                        <div
                          style={{
                            position: "relative",
                            width: "180px",
                            height: "180px",
                          }}
                        >
                          <img
                            src={file?.preSignedUrl}
                            alt={`File ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <IconButton
                            size="small"
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              zIndex: "1",
                            }}
                          >
                            <DeleteOutlineIcon
                              color="error"
                              onClick={() => handleRemoveFile(index)}
                            />
                            {/* <EditOutlinedIcon
                              onClick={() => handleEditImage(index)}
                            /> */}
                          </IconButton>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {eventName !== "" && (
                  <SubCard
                    title="Event Details"
                    secondary={
                      <div>
                        <IconButton>
                          <EditOutlinedIcon onClick={handleOpenEventForm} />
                        </IconButton>
                        <IconButton>
                          <CloseOutlined onClick={handleCancelEventForm} />
                        </IconButton>
                      </div>
                    }
                    p={1}
                  >
                    <Grid item md={12} xs={12}>
                      <Typography sx={{ color: "#ae4905" }}>
                        {eventdate}
                      </Typography>
                      <Typography>{eventName}</Typography>
                    </Grid>
                  </SubCard>
                )}

                <Grid sx={{ mt: 2 }}>
                  <TextField
                    name="post"
                    fullWidth
                    autoFocus
                    placeholder="what do you want to talk about."
                    multiline
                    rows={13}
                    value={postdata}
                    onChange={(e) => setpostdata(e.target.value)}
                    sx={{
                      backgroundColor: "transparent",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: 0,
                        },
                        "&:hover fieldset": {
                          borderWidth: 0,
                        },
                        "&.Mui-focused fieldset": {
                          borderWidth: 0,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <ReactPhotoEditor
                open={showModal}
                onClose={hideModal}
                file={fileToEdit}
                onSaveImage={handleSaveImage}
              />

              <Grid
                item
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Grid container alignItems="center" p={1}>
                    {posttype ? (
                      <Grid>
                        <Tooltip title="Media">
                          <IconButton onClick={handleFileUploadClick}>
                            <CollectionsIcon />
                          </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Event">
                          <IconButton onClick={handleOpenEventForm}>
                            <EventIcon />
                          </IconButton>
                        </Tooltip> */}
                        {/* <IconButton title="Poll">
                            <IconButton>
                              <PollIcon />
                            </IconButton>
                          </IconButton> */}
                        {/* <Tooltip title="Jobs">
                          <IconButton>
                            <WorkIcon />
                          </IconButton>
                        </Tooltip> */}
                      </Grid>
                    ) : (
                      <Tooltip title="Media">
                        <IconButton onClick={handleFileUploadClick}>
                          <CollectionsIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>

                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleFileSelected}
                  style={{ display: "none" }}
                  multiple
                />

                <Button
                  onClick={handleUploadPost}
                  variant="contained"
                  disabled={postdata === "" || isSubmitting}
                  sx={{
                    fontWeight: 450,
                    width: 60,
                  }}
                >
                  Post
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>

      <>
        <Dialog open={postsFor} onClose={handleClosePostFor}>
          <DialogContent
            sx={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
          >
            <Grid container>
              <Grid
                item
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 500 }} variant="h3">
                  Post Settings
                </Typography>
                <IconButton onClick={handleClosePostFor}>
                  <ClearIcon />
                </IconButton>
              </Grid>

              <Grid item md={12} xs={12}>
                <RadioGroup value={selectedOption} onClick={handleOptionChange}>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      widhth: 500,
                    }}
                  >
                    <FormControlLabel
                      value="EveryOne"
                      label="Anyone"
                      control={<Radio />}
                    />
                    <IconButton
                      sx={{
                        color: "primary.main",
                        bgcolor: "primary.light",
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                      }}
                      size="large"
                    >
                      <PublicIcon />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      widhth: 500,
                    }}
                  >
                    <FormControlLabel
                      value="Batch"
                      label="Batch"
                      control={<Radio />}
                    />
                    <IconButton
                      sx={{
                        color: "primary.main",
                        bgcolor: "primary.light",
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                      }}
                      size="large"
                    >
                      <WorkspacesIcon />
                    </IconButton>
                  </div>

                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      widhth: 500,
                    }}
                  >
                    <FormControlLabel
                      value="Group"
                      control={<Radio />}
                      label="Group"
                    />
                    <IconButton
                      sx={{
                        color: "primary.main",
                        bgcolor: "primary.light",
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                      }}
                      size="large"
                    >
                      <GroupsIcon />
                    </IconButton>
                  </div>
                </RadioGroup>
              </Grid>
            </Grid>
            <DialogActions>
              <Grid
                item
                md={12}
                xs={12}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 20,
                  "& > :not(:first-child)": { ml: 2 },
                }}
              >
                <Chip
                  onClick={handleClosePostFor}
                  sx={{
                    backgroundColor: "#0a66c2",
                    color: "#f0f0f0",
                    fontWeight: 450,
                    width: 60,
                  }}
                  label="Back"
                />

                <Chip
                  onClick={handleClosePostFor}
                  sx={{
                    backgroundColor: "#0a66c2",
                    color: "#f0f0f0",
                    fontWeight: 450,
                    width: 60,
                  }}
                  label="Done"
                />
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {forGroup && (
          <GroupDetails
            setGroupId={setGroupId}
            groupId={groupId}
            open={forGroup}
            handleClose={handleCloseforGroup}
            setSelectedGroupIds={setSelectedGroupIds}
            selectedGroupIds={selectedGroupIds}
          />
        )}

        {forBatch && (
          <BatchDetails
            setBatchId={setBatchId}
            batchId={batchId}
            open={forBatch}
            handleClose={handleCloseforBatch}
            setSelectedBatchIds={setSelectedBatchIds}
            selectedBatchIds={selectedBatchIds}
          />
        )}

        {openEventForm && (
          <EventForm
            eventLink={eventLink}
            setEventLink={setEventLink}
            eventLocation={eventLocation}
            setEventLocation={setEventLocation}
            eventType={eventType}
            setEventType={setEventType}
            eventTime={eventTime}
            setEventTime={setEventTime}
            eventdate={eventdate}
            setEventDate={setEventDate}
            eventName={eventName}
            setEventName={setEventName}
            open={openEventForm}
            handleClose={handleCloseEventForm}
          />
        )}
      </>
    </>
  );
};

export default CreatePost;
