import React, { Fragment, useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import moment from "moment";
import UserAvatar from "./UserAvatar"; // Assuming you have a UserAvatar component
import "./UserReorderStyle.css"; // Import the CSS file for transitions

const ConnectionList = ({
  users,
  setUser,
  setRoomId,
  generateUniqueId,
  handleRoomChange,
}) => {
  const [orderedUsers, setOrderedUsers] = useState([]);

  useEffect(() => {
    // Set the initial ordered users with the enter class
    const initialUsers = users.map((user) => ({
      ...user,
      transitionClass: "user-item-enter",
    }));
    setOrderedUsers(initialUsers);

    // After a delay, switch to the active class to trigger the transition
    const timer = setTimeout(() => {
      const activeUsers = initialUsers.map((user) => ({
        ...user,
        transitionClass: "user-item-enter-active",
      }));
      setOrderedUsers(activeUsers);
    }, 0);

    return () => clearTimeout(timer);
  }, [users]);

  return (
    <List component="nav">
      {orderedUsers.map((user) => {
        const userMessageCount =
          user?.messageCount?.[localStorage.getItem("userId")] || 0;

        return (
          <Fragment key={user.id}>
            <ListItemButton
              onClick={() => {
                if (user?.connectionType === "Friend") {
                  const id = generateUniqueId(
                    localStorage.getItem("userId"),
                    user?.connectionId
                  );
                  handleRoomChange(user?.connectionId);
                } else {
                  setRoomId(user?.connectionId);
                  handleRoomChange(user?.connectionId);
                }
                setUser(user);
              }}
              className={`user-item ${user.transitionClass}`}
            >
              <ListItemAvatar>
                <UserAvatar user={user} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    component="span"
                  >
                    <Grid item xs zeroMinWidth component="span">
                      <Typography
                        variant="h5"
                        color="inherit"
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {user?.connectionName}
                      </Typography>
                    </Grid>
                    <Grid item component="span">
                      <Typography component="span" variant="subtitle2">
                        {moment(user.lastMessageTimeStamp).format(" h:mm A")}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    component="span"
                  >
                    <Grid item xs zeroMinWidth component="span">
                      {/* <Typography
                        variant="caption"
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {user.status}
                      </Typography> */}
                    </Grid>
                    <Grid item component="span">
                      {userMessageCount !== 0 && (
                        <Chip
                          label={userMessageCount}
                          component="span"
                          color="secondary"
                          sx={{
                            width: 30,
                            height: 30,
                            "& .MuiChip-label": {
                              px: 0.5,
                            },
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                }
              />
            </ListItemButton>
            <Divider />
          </Fragment>
        );
      })}
    </List>
  );
};

export default ConnectionList;
