import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import UploadAttachment from "./UploadAttachment"; // Assuming this is a custom component.
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";

const initialValues = {
  achievements: [
    {
      description: "",
      attachments: [],
      year: "",
      month: "",
    },
  ],
};

const validationSchema = Yup.object({
  achievements: Yup.array().of(
    Yup.object({
      description: Yup.string().required("Description is required"),
      year: Yup.string().required("Year is required"),
      month: Yup.string().required("Month is required"),
    })
  ),
});

const StarAlumniForm = ({
  open,
  handleClose,
  getstarAlumniRequests,
  userId,
}) => {
  console.log(userId, "XXX");

  const RequestId = useRequestIdGenerater();
  const { generateTimeStamp, rendomRequestId } = useTimeStamp();
  const role = localStorage.getItem("role");

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    console.log(values, userId, "XXX");

    const api =
      role === "ROLE_ALUMNI"
        ? `v1/alumni/apply-star-alumni`
        : `/v1/alumni/apply-star-alumni-by-admin?alumniUserId=${userId}`;

    axios
      .post(api, values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      })
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: value.data.Msg,
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );

        resetForm();
        handleClose();
        getstarAlumniRequests();
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: error.response.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
        {userId
          ? "Make this alumni as a Star Alumni"
          : " Apply to Become a Star Alumni"}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          sx={{ marginBottom: 3, textAlign: "center", color: "gray" }}
        >
          Share your achievements and apply to be featured as a Star Alumni.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // Corrected line: Access resetForm from the second argument of onSubmit
            setSubmitting(true);
            handleSubmit(values, setSubmitting, resetForm);
            setSubmitting(false);
            // resetForm(); // Call resetForm here
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <FieldArray name="achievements">
                {({ push, remove, form }) => (
                  <Box>
                    {form.values.achievements.map((achievement, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #ddd",
                          padding: 2,
                          marginBottom: 3,
                          borderRadius: "8px",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", marginBottom: 2 }}
                        >
                          Achievement {index + 1}
                        </Typography>

                        {/* Description Field */}
                        <Box sx={{ marginBottom: 2 }}>
                          <Field
                            as={TextField}
                            fullWidth
                            name={`achievements[${index}].description`}
                            label="Description"
                            variant="outlined"
                            error={Boolean(
                              form.errors?.achievements?.[index]?.description &&
                                form.touched?.achievements?.[index]?.description
                            )}
                            helperText={
                              form.errors?.achievements?.[index]?.description &&
                              form.touched?.achievements?.[index]?.description
                                ? form.errors.achievements[index].description
                                : ""
                            }
                          />
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            {" "}
                            <Box sx={{ marginBottom: 2 }}>
                              <Field
                                as={TextField}
                                fullWidth
                                select
                                name={`achievements[${index}].year`}
                                label="Year"
                                variant="outlined"
                                error={Boolean(
                                  form.errors?.achievements?.[index]?.year &&
                                    form.touched?.achievements?.[index]?.year
                                )}
                                helperText={
                                  form.errors?.achievements?.[index]?.year &&
                                  form.touched?.achievements?.[index]?.year
                                    ? form.errors.achievements[index].year
                                    : ""
                                }
                              >
                                {Array.from(
                                  { length: 25 },
                                  (_, i) => new Date().getFullYear() - i
                                ).map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {" "}
                            <Box sx={{ marginBottom: 2 }}>
                              <Field
                                as={TextField}
                                fullWidth
                                select
                                name={`achievements[${index}].month`}
                                label="Month"
                                variant="outlined"
                                error={Boolean(
                                  form.errors?.achievements?.[index]?.month &&
                                    form.touched?.achievements?.[index]?.month
                                )}
                                helperText={
                                  form.errors?.achievements?.[index]?.month &&
                                  form.touched?.achievements?.[index]?.month
                                    ? form.errors.achievements[index].month
                                    : ""
                                }
                              >
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month) => (
                                  <MenuItem key={month} value={month}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>

                        {/* Upload Attachments */}
                        <Box sx={{ marginBottom: 2 }}>
                          <UploadAttachment
                            attachments={
                              form.values.achievements[index].attachments
                            }
                            index={index}
                            setFieldValue={form.setFieldValue}
                          />
                        </Box>

                        {/* Remove Button */}
                        {form.values.achievements.length > 1 && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => remove(index)}
                            sx={{ marginTop: 2 }}
                          >
                            Remove
                          </Button>
                        )}
                      </Box>
                    ))}

                    {/* Add Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        push({
                          description: "",
                          attachments: [], // Initialize attachments array for new achievement
                          year: "",
                          month: "",
                        })
                      }
                      sx={{ marginTop: 2 }}
                    >
                      Add Achievement
                    </Button>
                  </Box>
                )}
              </FieldArray>

              {/* Form Actions */}
              <DialogActions sx={{ marginTop: 3 }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default StarAlumniForm;
