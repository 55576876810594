// ConnectionCard.js

import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Drawer,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { IconEye } from "@tabler/icons";
import VerifiedIcon from "@mui/icons-material/Verified";

import { useDrawer } from "./DrawerContext";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axios from "axios";

const ConnectionCard = ({ friend, handleViewProfile, getConnectionList }) => {
  const theme = useTheme();
  const { drawerOpen, setDrawerOpen, openDrawerForUserId } = useDrawer(); // Use context for Drawer state
  const [messageBody, setMessageBody] = useState("");
  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleConfirmUnfriend = () => {
    console.log(friend, "XXXXXXXXX");

    handleUnfriend(friend?.connectionRequestId);
    setOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleSendMessage = () => {
    setDrawerOpen(false);
  };

  const handleUnfriend = async (connectionRequestId) => {
    const response = await axios.put(
      `/v1/connection/revoke/${connectionRequestId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      }
    );
    getConnectionList()
      .then((value) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Cancelled Successfully",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        generateTimeStamp();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          generateTimeStamp();
          dispatch(
            openSnackbar({
              open: true,
              message: response?.data?.data.Msg,
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "Something went wrong",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: false,
            })
          );
        }
      });
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          background:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[50],
          border: "1px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[100],
          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  alt="User 1"
                  src={`data:image/*;base64, ${friend?.friendAlumni?.avatar?.data}`}
                  sx={{
                    border: !friend?.friendAlumni?.starAlumni
                      ? null
                      : "3px solid #D89E46",
                  }}
                />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  variant="h5"
                  sx={{
                    display: "inline-flex", // Make the text and icon appear on the same line
                    alignItems: "center", // Vertically align the icon with the text
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    margin: 0, // Remove default margin for tight alignment
                  }}
                >
                  {friend?.friendAlumni?.name}
                  {friend?.friendAlumni?.starAlumni && (
                    <VerifiedIcon
                      sx={{
                        color: "#D89E46",
                        fontSize: "18px",
                        marginLeft: "4px", // Space between name and icon
                      }}
                    />
                  )}
                </Typography>

                <Typography>{friend?.friendAlumni?.careerStatus}</Typography>
                <Typography>{friend?.friendAlumni?.batchTag}</Typography>
              </Grid>
              {/* <Grid item>
                <Tooltip title="Compose Message">
                  <IconButton onClick={toggleDrawer(true)}>
                    <MessageOutlined />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <Button
                  onClick={() =>
                    handleViewProfile(friend?.friendAlumni?.userId)
                  }
                  variant="outlined"
                  fullWidth
                  startIcon={<IconEye />}
                >
                  View Profile
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} mt={1}>
                <Button
                  onClick={handleOpen}
                  variant="outlined"
                  color="error"
                  fullWidth
                  startIcon={<PersonRemoveIcon />}
                >
                  Unfriend
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Drawer
        anchor="bottom"
        open={
          drawerOpen && openDrawerForUserId === friend?.friendAlumni?.userId
        }
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            height: "auto",
            minHeight: "200px",
            maxHeight: "400px",
            width: "600px",
            margin: "16px",
            boxShadow: 3,
            borderRadius: 2,
            position: "fixed",
            bottom: 0,
            right: 0,
            transform: "translateX(-16px)", // Adjusting for the margin
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            Message to {friend?.friendAlumni?.name}
          </Typography>

          <TextField
            id="message-body"
            label="Message"
            multiline
            rows={4}
            fullWidth
            value={messageBody}
            onChange={(e) => setMessageBody(e.target.value)}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{ mt: 2, alignSelf: "flex-end" }}
          >
            Send
          </Button>
        </Box>
      </Drawer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="unfriend-dialog-title"
        aria-describedby="unfriend-dialog-description"
      >
        <DialogTitle id="unfriend-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="unfriend-dialog-description">
            Are you sure you want to unfriend this person? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmUnfriend}
            color="error"
            variant="contained"
          >
            Unfriend
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConnectionCard;
