import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import PersonList from "./PersonList";

const MainTabs = ({
  eventDetails,
  data,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  columns,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="tabs example">
        <Tab label="Details" />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {value === 0 && (
          <PersonList
            data={data}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            columns={columns}
          />
        )}
      </Box>
    </Box>
  );
};

export default MainTabs;
